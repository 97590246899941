import React from 'react'
import { Route, Redirect } from "react-router-dom"
import { connect } from 'react-redux'

const AuthenticatedRoute = ({component: Component, user, ...rest}) => (
    <Route
        {...rest}
        render={(props) => {
            if (user.isLoading) {
                return <h2>Loading...</h2>;
            } else if (!user.isAuthenticated) {
                return <Redirect to="/login" />
            } else {
                return <Component {...props} />
            }
        }}
    />
)

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(AuthenticatedRoute)