import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import Select from 'react-select'
import { useHistory } from 'react-router-dom'

const TPBuild = ({ user: {token}, match, products: { presetData }}) => {

    const { params: { buildId } } = match
    const [tpBuild, settpBuild] = useState(null)
    const [tpbuildinfo, settpbuildinfo] = useState(null)

    let history = useHistory()
    const selectedcomp = useRef()

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/tpbuilds?buildid=' + buildId, config).then(res => {
            settpBuild(res.data[0])
            settpbuildinfo(res.data[0])
        }).catch((err)=>{
            if(err.response.status==401){
                history.push("/unauthorized")
            }
        })
    }, [])

    const round = (type, value, decimals = 0, even=false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updatekuroTotal = (e) => {
        let tempbuild = {...tpbuildinfo}
        tempbuild.kurototal = e.target.value
        let reg = /^[0-9.]*$/
        if (!reg.test(tempbuild.kurototal)) {
            return tempbuild.kurototal
        }
        settpbuildinfo(tempbuild)
    }

    const buildTotal = (tempbuild) => {
        let comptotal = 0
        let tpcharges = 0
        let subtotal = 0
        let buildtotal = 0
        let kmar = 5
        let kmargin = 0
        for (let preset of tempbuild.presets) {
            if (preset.type === "margin") {
                kmar = preset.kmar / 100
            } else {
                comptotal += Number(preset.totalprice)
            }
        }
        tpcharges = round("round", (comptotal * 10) / 100, 2)
        subtotal = round("round", comptotal + tpcharges, 2)
        kmargin = round("round", subtotal * kmar, 2)
        buildtotal = round("round", subtotal + kmargin, 2)
        tempbuild.comptotal = round("round", comptotal, 2)
        tempbuild.tpcharges = tpcharges
        tempbuild.subtotal = subtotal
        tempbuild.kmargin = kmargin
        tempbuild.buildtotal = buildtotal
        return tempbuild
    }

    const addpreset = (pindex) => {
        let tempbuild = {...tpbuildinfo}
        tempbuild.presets.splice(pindex+1, 0, {'type': '', 'presetid': '', 'price': 0, 'quantity': 1, 'totalprice': 0})
        settpbuildinfo(tempbuild)
    }

    const removepreset = (pindex) => {
        let tempbuild = {...tpbuildinfo}
        tempbuild.presets = tempbuild.presets.filter((_, i) => i !== pindex)
        tempbuild = buildTotal(tempbuild)
        settpbuildinfo(tempbuild)
    }

    const getpresetlabel = (comp) => {
        let label = ""

        if (comp.presetid !== "") {
            if (comp.presetid === "other") {
                label = "Other"
            } else {
                label = presetData !== null && presetData.filter(ps => ps.type === comp.type)[0].list.filter(item => item.presetid === comp.presetid)[0].kuro_title
            }
        }

        return label
    }

    const updatepreset = (pindex, key, e, num=false) => {
        let tempbuild = {...tpbuildinfo}
        tempbuild.presets[pindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "type" || key === "presetid") {
           selectedcomp.current.setValue('')
        }
        if (key === "presetid") {
            let title = presetData.filter(ps => ps.type === tempbuild.presets[pindex]["type"])[0]["list"].filter(item => item.presetid === e.target.value)[0].title
            let price = presetData.filter(ps => ps.type === tempbuild.presets[pindex]["type"])[0]["list"].filter(item => item.presetid === e.target.value)[0].price
            let totalprice = price * tempbuild.presets[pindex].quantity
            tempbuild.presets[pindex].title = title
            tempbuild.presets[pindex].price = price
            tempbuild.presets[pindex].totalprice = totalprice
            tempbuild = buildTotal(tempbuild)
        } else if (key === "quantity") {
            let totalprice = tempbuild.presets[pindex].price * e.target.value
            tempbuild.presets[pindex].totalprice = totalprice
            tempbuild = buildTotal(tempbuild)
        } else if (key === "price") {
            let totalprice = e.target.value * tempbuild.presets[pindex].quantity
            tempbuild.presets[pindex].totalprice = totalprice
            tempbuild = buildTotal(tempbuild)
        } else if (key === "title") {
            tempbuild.presets[pindex].title = e.target.value
        } else {
            tempbuild.presets[pindex].presetid = ""
            tempbuild.presets[pindex].price = 0
            tempbuild.presets[pindex].quantity = 1
            tempbuild.presets[pindex].totalprice = 0
        }
        settpbuildinfo(tempbuild)
    }

    const updatemargin = (e) => {
        let tempbuild = {...tpbuildinfo}
        tempbuild.presets.filter(ps => ps.type === "margin")[0].presetid = e.target.value
        tempbuild.presets.filter(ps => ps.type === "margin")[0].kmar = presetData.filter(ps => ps.type === "margin")[0]["list"].filter(item => item.presetid === e.target.value)[0]["kmar"]
        tempbuild = buildTotal(tempbuild)
        settpbuildinfo(tempbuild)
    }

    const updateTPBuild = (e, key) => {
        let tempbuild = {...tpbuildinfo}
        tempbuild[key] = e.target.value
        settpbuildinfo(tempbuild)
    }

    const updateBuildComp = (pindex, e) => {
        let tempbuild = {...tpbuildinfo}
        let presetid = e.value
        if (e) {
            tempbuild.presets[pindex].presetid = presetid
            let title = (presetid === "other") ? "" : presetData.filter(ps => ps.type === tempbuild.presets[pindex]["type"])[0]["list"].filter(item => item.presetid === presetid)[0].title
            let price = (presetid === "other") ? "" : presetData.filter(ps => ps.type === tempbuild.presets[pindex]["type"])[0]["list"].filter(item => item.presetid === presetid)[0].price
            let totalprice = price * tempbuild.presets[pindex].quantity
            tempbuild.presets[pindex].title = title
            tempbuild.presets[pindex].price = price
            tempbuild.presets[pindex].totalprice = totalprice
            tempbuild = buildTotal(tempbuild)
        } else {
            tempbuild.presets[pindex].title = ''
            tempbuild.presets[pindex].price = 0
            tempbuild.presets[pindex].totalprice = 0
            tempbuild = buildTotal(tempbuild)
        }
        settpbuildinfo(tempbuild)
    }

    const compOptions = (comp) => {
        const tempArray = []
        presetData !== null && comp.type !== "" && presetData.filter(ps => ps.type === comp.type)[0]["list"].map(item => {
            return (
                tempArray.push({ value: item.presetid, label: item.kuro_title})
            )
        })
        tempArray.push({ value: 'other', label: 'Other'})
        return tempArray
    }

    const disableScroll = (e) => {
        e.target.blur()
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        let tpbuild = tpbuildinfo
        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/tpbuilds?buildid=' + buildId, tpbuild, config).then(res => {
            settpbuildinfo(res.data[0])
            history.push('/tpbuilds')
        }).catch((err)=>{
            if(err.response.status==401){
                history.push("/unauthorized")
            }
        })
    }

    const cancelHandler = () => {
        history.push('/tpbuilds')
    }

    return (
        <div>
            <div className='tpbuilds txt-light-grey'>
                <h2>TP-Build</h2>
                {tpbuildinfo !== null &&
                <table className="home border even_odd tp_preset_details" cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <td>Build ID</td>
                            <td colSpan="3">Build Title</td>
                            <td>ASIN</td>
                            <td>Kuro SKU</td>
                            <td colSpan="2">Channel</td>
                        </tr>
                        <tr>
                            <td>{tpBuild.buildid}</td>
                            <td colSpan="3"><textarea className='large_textarea' value={tpbuildinfo.title} onChange={(e) => updateTPBuild(e, "title")} /></td>
                            <td><input value={tpbuildinfo.asin} onChange={(e) => updateTPBuild(e, "asin")}/></td>
                            <td><input value={tpbuildinfo.kuro_sku} onChange={(e) => updateTPBuild(e, "kuro_sku")}/></td>
                            <td colSpan="2">
                                <select value={tpbuildinfo.channel} onChange={(e) => updateTPBuild( e, "channel")}>
                                    <option value="">Select Channel</option>
                                    <option value="Amazon">Amazon</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Type</td>
                            <td>Component</td>
                            <td>Title</td>
                            <td>Price</td>
                            <td>Quantity</td>
                            <td>Total Price</td>
                            <td><span className="prod-span" onClick={() => addpreset(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span></td>
                        </tr>
                        {tpbuildinfo.presets.map((comp, i) =>
                        <>
                        {comp.type !== "margin" &&
                        <tr key={i}>
                            <td>
                                <select value={comp.type} onChange={(e) => updatepreset(i, "type", e)}>
                                    <option value="">Select Type</option>
                                    <option value="cpu">Processor</option>
                                    <option value="mob">Motherboard</option>
                                    <option value="ram">RAM</option>
                                    <option value="gpu">Graphics Card</option>
                                    <option value="psu">Power Supply</option>
                                    <option value="ssd">SSD</option>
                                    <option value="hdd">HDD</option>
                                    <option value="cooler">CPU Cooler</option>
                                    <option value="tower">Case</option>
                                    <option value="fans">Fan</option>
                                    <option value="wifi">WiFi</option>
                                    <option value="os">Operating System</option>
                                    <option value="shp_fees">Shipping Fees</option>
                                    <option value="build_fees">Build Fees</option>
                                    <option value="warranty">Warranty</option>
                                </select>
                            </td>
                            <td>
                                <Select classNamePrefix="kuro-search-select" options={compOptions(comp)} value={{value: comp.presetid, label: getpresetlabel(comp)}} onChange={(e) => updateBuildComp(i, e)} placeholder="Select Component" className="react-select-container" ref={selectedcomp}/>
                            </td>
                            <td width={"350px"}>
                                <textarea value={comp.title} onChange={(e) => updatepreset(i, "title", e)} />
                            </td>
                            <>
                                {comp.type !== "margin" ? (
                                    <>
                                <td width="110px">
                                    <input type="number" min="0" onWheel={disableScroll} value={comp.price} onChange={(e) => updatepreset(i, "price", e, true)} />
                                </td>
                                {comp.type !== "os" && comp.type !== "shp_fees" && comp.type !== "build_fees" && comp.type !== "warranty" ? (
                                <td>
                                    <input type="number" min="1" max="100" onWheel={disableScroll} value={comp.quantity} onChange={(e) => updatepreset(i, "quantity", e, true)} />
                                </td>
                                ): (
                                    <td>NA</td>
                                )}
                                    </>
                                ) : (
                                    <>
                                    <td>
                                        <select value={comp.presetid} onChange={(e) => updatepreset(i, "presetid", e)}>
                                            {presetData !== null && comp.type !== "" && presetData.filter(ps => ps.type === comp.type)[0]["list"].map((item, k) =>
                                            <option key={k} value={item.presetid}>{item.kmar}</option>
                                            )}
                                        </select>
                                    </td>
                                    <td>NA</td>
                                    </>
                                )}
                                <td>
                                    {comp.totalprice}
                                </td>
                                <td>
                                    <span className="prod-span" onClick={() => addpreset(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                                    <span className="prod-span" onClick={() => removepreset(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span>
                                </td>
                            </>
                        </tr>
                        }
                        </>
                        )}
                        <tr>
                            <td colSpan="3"></td>
                            <td colSpan="2">Build Comp Total</td>
                            <td>{tpbuildinfo.comptotal}</td>
                        </tr>
                        <tr>
                            <td colSpan="3"></td>
                            <td colSpan="2">TP Commission</td>
                            <td>{tpbuildinfo.tpcharges}</td>
                        </tr>
                        <tr>
                            <td colSpan="3"></td>
                            <td colSpan="2">Sub Total</td>
                            <td>{tpbuildinfo.subtotal}</td>
                        </tr>
                        <tr>
                            <td  colSpan="2">KM(%)</td>
                            <td>
                                <select value={tpbuildinfo.presets.filter(comp => comp.type === "margin")[0].presetid} onChange={(e) => updatemargin(e)}>
                                    {presetData !== null && presetData.filter(ps => ps.type === "margin")[0]["list"].map((item, k) =>
                                    <option key={k} value={item.presetid}>{item.title}</option>
                                    )}
                                </select>
                            </td>
                            <td colSpan="2">Kuro Margin</td>
                            <td>{tpbuildinfo.kmargin}</td>
                        </tr>
                        <tr>
                            <td colSpan="3"></td>
                            <td colSpan="2">Build Total</td>
                            <td>{tpbuildinfo.buildtotal}</td>
                        </tr>
                        <tr>
                            <td colSpan="3"></td>
                            <td colSpan="2">Kuro Total</td>
                            <td><input onChange={(e) => updatekuroTotal(e)} value={tpbuildinfo.kurototal} className="small"/></td>
                        </tr>
                    </tbody>
                </table>
                }
            </div>
            <ul className='btns'>
                <li><button onClick={submitHandler}>Submit</button></li>
                <li><button onClick={cancelHandler}>Cancel</button></li>
            </ul>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    products: state.products
})

export default connect(mapStateToProps)(TPBuild)