import React from 'react'
import KuroLink from '../components/common/KuroLink'

const PcComponents = () => {

    return (
        <div className="pc-comps mx-width txt-light-grey">
            <div className="page-title">
                <h1>Stock Register</h1>
            </div>
            <div className='col_2'>
                <div className="pc-comp">
                    <div className="cnt">
                        <h2> Core Components</h2>
                        <ul className="no_bullets">
                            <li><KuroLink to='/stock-register/components/cpu'>Processors</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/mob'>Motherboards</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/ram'>RAMs</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/gpu'>Graphics Cards</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/psu'>Power Supplys</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/ssd'>Solid State Drives</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/hdd'>Hard Drives</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/tower'>Cases</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/cooler'>CPU Coolers</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/fan'>Fans</KuroLink></li>
                        </ul>
                    </div>
                </div>
                <div className='stock'>
                    <div className="cnt">
                        <h3><KuroLink to="/stock-register/monitors">Monitors</KuroLink></h3>
                        <h3><KuroLink to="/stock-register/networking">Networking Gear</KuroLink></h3>
                        <h3><KuroLink to="/stock-register/external">External Devices</KuroLink></h3>
                    </div>
                    <lable>&nbsp;</lable>
                    <h2>Accessories</h2>
                    <ul className="no_bullets">
                        <li><KuroLink to='/stock-register/accessories/keyboard'>Keyboards</KuroLink></li>
                        <li><KuroLink to='/stock-register/accessories/keyboard-accessories'>Keyboard Accessories</KuroLink></li>
                        <li><KuroLink to='/stock-register/accessories/mouse'>Mouse</KuroLink></li>
                        <li><KuroLink to='/stock-register/accessories/mouse-accessories'>Mouse Accessories</KuroLink></li>
                        <li><KuroLink to='/stock-register/accessories/kbdmouse'>Keyboard Mouse Combo</KuroLink></li>
                        <li><KuroLink to='/stock-register/accessories/streaming-gear'>Streaming Gear</KuroLink></li>
                    </ul>
                </div>
            </div>
            <p>&nbsp;</p>
        </div>
    )
}

export default PcComponents