import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment-timezone'

moment.tz.setDefault('Asia/Kolkata')

const TPOrderInvoice = ({ match, user: {token}, admin: { provinces }}) => {

    const { params: { orderid } } = match
    const [orderdata, setorderdata] = useState(null)
    const [invoicedata, setinvoicedata] = useState(null)
    const [preview, setpreview] = useState(false)
    const [errmsg, seterrmsg] = useState({
        "pan": "",
        "gstin": ""
    })

    const round = (type, value, decimals = 0, even=false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updateroundoff = (tempinvoice) => {
        let roundoff = 0
        if (tempinvoice.billadd.state === "" || tempinvoice.billadd.state === "Telangana") {
            if (Math.abs(tempinvoice.totalprice - 2 * tempinvoice.cgst - tempinvoice.totalpricebgst) >= 0.01) {
                roundoff = round("round", tempinvoice.totalprice - 2 * tempinvoice.cgst - tempinvoice.totalpricebgst, 2)
            } else {
                roundoff = 0
            }
        } else {
            if (Math.abs(tempinvoice.totalprice - tempinvoice.gst - tempinvoice.totalpricebgst) >= 0.01) {
                roundoff = round("round", tempinvoice.totalprice - tempinvoice.gst - tempinvoice.totalpricebgst, 2)
            }
        }
        tempinvoice.roundoff = roundoff
        return tempinvoice
    }

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/tporders?orderid=' + orderid, config).then(res => {
            setorderdata(res.data[0])
        })
    }, [])

    useEffect(() => {
        if (orderdata !== null) {
            let tempinvoice = {}
            tempinvoice.builds = []
            tempinvoice.products = []
            let totalpricebgst = 0
            let gst = 0
            if (orderdata.builds) {
                for (let i=0; i<orderdata.builds.length; i++) {
                    let build = {}
                    build.title = orderdata.builds[i].title
                    build.buildType = "custom"
                    let price = orderdata.builds[i].price
                    let quantity = orderdata.builds[i].quantity
                    let totalprice = price * quantity
                    build.price = price
                    build.components = orderdata.builds[i].components
                    for (let j=0; j<build.components.length; j++) {
                        let srno = ""
                        if (orderdata.outward) {
                            let sr_nos = orderdata.outward.builds[i].serials[j].sr_no
                            for (let k=0; k<sr_nos.length; k++) {
                                if (sr_nos[k] !== "") {
                                    if (srno !== "") {
                                        srno += ", "
                                    }
                                    srno += sr_nos[k]
                                }
                            }
                            if (srno !== "") {
                                build.components[j].title += " [S/N: " + srno + "]"
                            }
                        }
                    }
                    build.buildType = "custom"
                    build.quantity = quantity
                    build.pricebgst = round("round", (price / 1.18), 2)
                    build.tax = round("round", (price / 1.18) * 0.18, 2)
                    build.totalprice = totalprice
                    build.totalpricebgst = round("round", (totalprice / 1.18), 2)
                    totalpricebgst += round("round", (totalprice / 1.18), 2)
                    gst += round("round", (totalprice / 1.18) * 0.18, 2)
                    tempinvoice.builds.push(build)
                }
            }
            if (orderdata.products) {
                for (let i=0; i<orderdata.products.length; i++) {
                    let prod = orderdata.products[i]
                    let prodtitle = prod.title
                    let srno = ""
                    if (orderdata.outward) {
                        let sr_nos = orderdata.outward.products[i].sr_no
                        for (let k=0; k<sr_nos.length; k++) {
                            if (sr_nos[k] !== "") {
                                if (srno !== "") {
                                    srno += ", "
                                }
                                srno += sr_nos[k]
                            }
                        }
                        if (srno !== "") {
                            prodtitle += " [S/N: " + srno + "]"
                        }
                    }
                    prod.title = prodtitle
                    let price = prod.price
                    let quantity = prod.quantity
                    let totalprice = price * quantity
                    let tax = round("ceil", (price / 1.18) * 0.18, 2)
                    prod.pricebgst = round("round", price - tax, 2)
                    prod.totalprice = totalprice
                    tempinvoice.products.push(prod)
                }
            }
            tempinvoice.totalpricebgst = round("round", totalpricebgst, 2)
            tempinvoice.gst = round("round", gst, 2)
            tempinvoice.cgst = round("round", gst/2, 2)
            tempinvoice.totalprice = orderdata.totalprice
            tempinvoice.user = orderdata.user
            tempinvoice.billadd = orderdata.billadd
            tempinvoice.create_order = false
            if (orderdata.addressflag) {
                tempinvoice.addressflag = orderdata.addressflag
            } else {
                tempinvoice.shpadd = orderdata.shpadd
            }
            tempinvoice.invoice_date = orderdata.order_date
            tempinvoice.orderid = orderdata.orderid
            tempinvoice.order_date = orderdata.order_date
            tempinvoice.invoice_no = orderdata.invoice_no
            tempinvoice.po_ref = orderdata.po_ref === "" ? orderdata.tporderid : orderdata.po_ref
            setinvoicedata(tempinvoice)
        }
    }, [orderdata])

    const updateTotal = () => {
        let totalpricebgst = 0
        let gst = 0
        let totalprice = 0
        for (let i=0; i<invoicedata.builds.length; i++) {
            totalpricebgst += invoicedata.builds[i].totalpricebgst
            gst += invoicedata.builds[i].totaltax
            totalprice += invoicedata.builds[i].totalprice
        }
        for (let i=0; i<invoicedata.products.length; i++) {
            totalpricebgst += invoicedata.products[i].totalpricebgst
            gst += invoicedata.products[i].totaltax
            totalprice += invoicedata.products[i].totalprice
        }
        let tempinvoice = {...invoicedata}
        tempinvoice.totalprice = totalprice
        tempinvoice.gst = round("round", gst, 2)
        tempinvoice.cgst = round("round", gst/2, 2)
        tempinvoice.totalpricebgst = round("round", totalpricebgst, 2)
        setinvoicedata(tempinvoice)
    }

    const updatebuild = (type, bindex, key, e, num=false, cindex) => {
        let tempinvoice = {...invoicedata}
        if (type === "build") {
            tempinvoice.builds[bindex][key] = num ? Number(e.target.value) : e.target.value
        } else {
            tempinvoice.builds[bindex].components[cindex][key] = e.target.value
        }
        if (key === "price" || key === "quantity") {
            let price = tempinvoice.builds[bindex].price
            let quantity = tempinvoice.builds[bindex].quantity
            let totalprice = price * quantity
            tempinvoice.builds[bindex].pricebgst = round("round", (price / 1.18), 2)
            tempinvoice.builds[bindex].tax = round("round", (price / 1.18) * 0.18, 2)
            tempinvoice.builds[bindex].totalprice = totalprice
            tempinvoice.builds[bindex].totalpricebgst = round("round", (totalprice / 1.18), 2)
            tempinvoice.builds[bindex].totaltax = round("round", (totalprice / 1.18) * 0.18, 2)
        }
        setinvoicedata(tempinvoice)
        if (key === "price" || key === "quantity") {
            updateTotal()
        }
    }

    const updateprod = (index, key, e, num=false) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.products[index][key] = num ? Number(e.target.value) : e.target.value
        if (key === "price" || key === "quantity") {
            let price = tempinvoice.products[index].price
            let quantity = tempinvoice.products[index].quantity
            let totalprice = price * quantity
            const tax = round("ceil", (price / 1.18) * 0.18, 2)
            const totaltax = round("ceil", (totalprice / 1.18) * 0.18, 2)
            tempinvoice.products[index].pricebgst = round("round", price - tax, 2)
            tempinvoice.products[index].totalprice = totalprice
            tempinvoice.products[index].totaltax = totaltax
            tempinvoice.products[index].totalpricebgst = round("round", totalprice - totaltax, 2)
        }
        setinvoicedata(tempinvoice)
        if (key === "price" || key === "quantity") {
            updateTotal()
        }
    }

    const updateuser = (key, e) => {
        let tempinvoice = {...invoicedata}
        let value = e.target.value
        if (key === "phone") {
            value = value.replaceAll("+91", "").replaceAll(/\D/g, "")
        }
        tempinvoice.user[key] = value
        tempinvoice.billadd[key] = value
        setinvoicedata(tempinvoice)
    }

    const updatepangstin = (invoice) => {
        let tempmsg = {...errmsg}
        if (invoice.billadd.pan.length !== 10 || !(/[A-Z]{5}[0-9]{4}[A-Z]{1}/).test(invoice.billadd.pan)) {
            tempmsg["pan"] = "*** Please Enter Valid PAN number"
        } else {
            tempmsg["pan"] = ""
        }
        if (invoice.billadd.gstin !== "" && (invoice.billadd.gstin.length !== 15 || invoice.billadd.gstin.slice(2,12) !== invoice.billadd.pan || !(/^[0-9]*$/).test(invoice.billadd.gstin.slice(0,2)))) {
            tempmsg["gstin"] = "*** Please Enter Valid GSTIN number"
        } else {
            tempmsg["gstin"] = ""
        }
        seterrmsg(tempmsg)
    }

    const updateaddress = (key, subkey, e) => {
        let value = e.target.value
        let tempinvoice = {...invoicedata}
        if (key === "billadd" && subkey === "state") {
            tempinvoice = updateroundoff(tempinvoice)
            tempinvoice[key][subkey] = value
        } else if (subkey === "pincode") {
            if (value.length <= 6 && (/^[0-9]*$/).test(value)) {
                tempinvoice[key][subkey] = value
            }
        } else if (subkey === "pan" || subkey === "gstin") {
            if ((/^[A-Z0-9]*$/).test(value.toUpperCase())) {
                tempinvoice[key][subkey] = value.toUpperCase()
            }
            updatepangstin(tempinvoice)
        } else {
            tempinvoice[key][subkey] = value
        }
        setinvoicedata(tempinvoice)
    }

    const updateinvoicedate = (date) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.invoice_date = (date == null) ? "" : date
        setinvoicedata(tempinvoice)
    }

    const updateinvoice = (key, e) => {
        let tempinvoice = {...invoicedata}
        tempinvoice[key] = e.target.value
        setinvoicedata(tempinvoice)
    }

    const disableScroll = (e) => {
        e.target.blur()
    }

    var fileDownload = require('js-file-download')

    const invoiceHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let invoice = invoicedata
        if (invoice.user.state === "Telangana") {
            invoice.roundoff = round("round", invoice.totalprice - 2 * invoice.cgst - invoice.totalpricebgst, 2)
        } else {
            invoice.roundoff = round("round", invoice.totalprice - invoice.gst - invoice.totalpricebgst, 2)
        }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwardinvoices', invoice, config)
        .then((res) => {
            fileDownload(res.data, 'invoice.pdf')
        })
    }

    return (
        <div className="hp20 invoice-creation txt-light-grey">
        {preview ? (
            <div className="invoice-preview">
                <h2>Preview</h2>
                <table className="border" cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <th>S.No.</th>
                            <th colSpan="9">Product Description</th>
                            <th colSpan="2">HSN / SAC CODE</th>
                            <th>QTY</th>
                            <th colSpan="3">Unit Price<br/>(Excl. GST)</th>
                            <th colSpan="3">Tax Rate</th>
                            <th colSpan="3">Total Amount</th>
                        </tr>
                        {invoicedata.builds.map((build, i) =>
                        <tr>
                            <td></td>
                            <td colSpan="9">
                            {build.buildType === "custom" ? (
                                <>
                                <b>{build.title}</b><br/>
                                {build.components.map((comp) =>
                                    comp.title !== "" && 
                                    <>
                                    {comp.title}<br/>
                                    </>
                                )}
                                </>
                            ) : (
                                build.title
                            )}
                            </td>
                            <td colSpan="2">{build.hsncode}</td>
                            <td>{build.quantity}</td>
                            <td colSpan="3">{build.pricebgst}</td>
                            <td colSpan="3">
                            {invoicedata.billadd.state === "Telangana" ? (
                            <>
                            CGST @ 9%<br/>SGST @ 9%
                            </>
                            ) : (
                                "IGST @ 18%"
                            )}
                            </td>
                            <td className="text_right" colSpan="3">{build.totalpricebgst}</td>
                        </tr>
                        )}
                        {invoicedata.products.map((prod, i) =>
                        <tr key={i}>
                            <td></td>
                            <td colSpan="9">{prod.title}</td>
                            <td colSpan="2">{prod.hsncode}</td>
                            <td>{prod.quantity}</td>
                            <td colSpan="3">{prod.pricebgst}</td>
                            <td colSpan="3">
                            {invoicedata.billadd.state === "Telangana" ? (
                            <>
                            CGST @ 9%<br/>SGST @ 9%
                            </>
                            ) : (
                                "IGST @ 18%"
                            )}
                            </td>
                            <td className="text_right" colSpan="3">{prod.totalpricebgst}</td>
                        </tr>
                        )}
                        <tr>
                            <td className="text_right" colSpan="19"><b>Amount Taxable</b></td>
                            <td className="text_right" colSpan="3"><b>Rs.{invoicedata.totalpricebgst}</b></td>
                        </tr>
                        <tr>
                            <td className="text_right" colSpan="19">
                                Tax Summary<br/>
                                {invoicedata.billadd.state === "Telangana" ? (
                                <>
                                CGST @ 9%<br/>SGST @ 9%
                                </>
                                ) : (
                                "IGST @ 18%"
                                )}
                            </td>
                            <td className="text_right" colSpan="3">
                                &nbsp;<br/>
                                {invoicedata.billadd.state === "Telangana" ? (
                                <>
                                Rs.{invoicedata.cgst }<br/>
                                Rs.{invoicedata.cgst }
                                </>
                                ) : (
                                    invoicedata.gst
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="text_right" colSpan="19">Round off</td>
                            <td className="text_right" colSpan="3">{invoicedata.roundoff}</td>
                        </tr>
                        <tr>
                            <td className="text_right" colSpan="19"><b>Grand Total</b></td>
                            <td className="text_right" colSpan="3"><b>Rs.{invoicedata.totalprice}</b></td>
                        </tr>
                    </tbody>
                </table>
                <ul className="btns">
                    <li>
                        <button onClick={invoiceHandler}>Generate Invoice</button>
                    </li>
                    <li>
                        <button onClick={() => setpreview(false)}>Back</button>
                    </li>
                </ul>
            </div>
        ) : (
            invoicedata !== null &&
            <div className="invoice-wrap">
                {invoicedata.builds.length > 0 && 
                <div className='builds'>
                    <h3>Builds</h3>
                    {invoicedata.builds.map((build, i) =>
                    <table key={i} className="build border" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td>Title</td>
                                <td colSpan="3"><textarea className="x-large" value={build.title} onChange={(e) => updatebuild("build", i, "title", e)} /></td>
                            </tr>
                            <tr>
                                <td>Price</td>
                                <td><input type="number" className="large" onWheel={disableScroll} value={build.price} onChange={(e) => updatebuild("build", i, "price", e, true)} /></td>
                                <td>Quantity</td>
                                <td><input className="small" min="1" type="number" onWheel={disableScroll} value={build.quantity} onChange={(e) => updatebuild("build", i, "quantity", e, true)} /></td>
                            </tr>
                            {build.components.map((comp, j) => 
                            <tr key={j}>
                                <td colSpan="4">
                                    <textarea value={comp.title} className="x-large" min="1" max="100" onChange={(e) => updatebuild("comp", i, "title", e, false, j)} />
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    )}
                </div>
                }
                {invoicedata.products.length > 0 &&
                <div className='products'>
                    <h3>Products</h3>
                    <table className="border" cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <td colSpan="3">Title</td>
                                <td>Price</td>
                                <td>Quantity</td>
                                <td>Total Price</td>
                            </tr>
                            {invoicedata.products.map((prod, i) =>
                            <tr key={i}>
                                <td colSpan="3">
                                    <textarea value={prod.title} type="textarea" className="x-large" onChange={(e) => updateprod(i, "title", e)}></textarea>
                                </td>
                                <td>
                                    <input type="number" className="small" onWheel={disableScroll} value={prod.price} onChange={(e) => updateprod(i, "price", e, true)} />
                                </td>
                                <td>
                                    <input type="number" className="small" min="1" max="100" onWheel={disableScroll} value={prod.quantity} onChange={(e) => updateprod(i, "quantity", e, true)} />
                                </td>
                                <td>
                                    {prod.totalprice}
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                }
                <table className="border" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Taxable Amount</td>
                            <td>{invoicedata.totalpricebgst}</td>
                        </tr>
                        <tr>
                            <td>IGST</td>
                            <td>{invoicedata.gst}</td>
                        </tr>
                        <tr>
                            <td>SGST</td>
                            <td>{invoicedata.cgst}</td>
                        </tr>
                        <tr>
                            <td>CGST</td>
                            <td>{invoicedata.cgst}</td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>{invoicedata.totalprice}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="border" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Invoice Date</td>
                            <td>
                                <DatePicker selected={invoicedata.invoice_date === "" ? new Date() : new Date(invoicedata.invoice_date)} dateFormat='dd-MMM-yyyy' onChange={date => updateinvoicedate(date)} />
                            </td>
                        </tr>
                        <tr>
                            <td>Order ID</td>
                            <td><input className="order" value={invoicedata.orderid} onChange={(e) => updateinvoice("orderid", e)} /></td>
                        </tr>
                        <tr>
                            <td>PO/Ref No</td>
                            <td><input className="po_ref" value={invoicedata.po_ref} onChange={(e) => updateinvoice("po_ref", e)} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="name" value={invoicedata.user.name} onChange={(e) => updateuser("name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="phone" value={invoicedata.user.phone} onChange={(e) => updateuser("phone", e)} /></td>
                        </tr>
                        <tr>
                            <td colSpan="2">Billing Address</td>
                        </tr>
                        <tr>
                            <td>Company Name</td>
                            <td><input className="company" value={invoicedata.billadd.company} onChange={(e) => updateaddress("billadd", "company", e)} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="name" value={invoicedata.billadd.name} onChange={(e) => updateaddress("billadd", "name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="phone" value={invoicedata.billadd.phone} onChange={(e) => updateaddress("billadd", "phone", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line1</td>
                            <td><textarea className="address" value={invoicedata.billadd.addressline1} onChange={(e) => updateaddress("billadd", "addressline1", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line2</td>
                            <td><textarea className="address" value={invoicedata.billadd.addressline2} onChange={(e) => updateaddress("billadd", "addressline2", e)} /></td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td><input className="city" value={invoicedata.billadd.city} onChange={(e) => updateaddress("billadd", "city", e)} /></td>
                        </tr>
                        <tr>
                            <td>Pincode</td>
                            <td><input className="pincode" value={invoicedata.billadd.pincode} onChange={(e) => updateaddress("billadd", "pincode", e)} /></td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                <select className="state" value={invoicedata.billadd.state} onChange={(e) => updateaddress("billadd", "state", e)}>
                                    <option value="">Select State</option>
                                    {provinces.map((province, i) =>
                                    <option key={i} value={province["state"]}>{province["state"]}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>PAN</td>
                            <td><input className="pan" value={invoicedata.billadd.pan} onChange={(e) => updateaddress("billadd", "pan", e)} /></td>
                        </tr>
                        <tr>
                            <td>GSTIN</td>
                            <td><input className="gstin" value={invoicedata.billadd.gstin} onChange={(e) => updateaddress("billadd", "gstin", e)} /></td>
                        </tr>
                        <tr>
                            <td colSpan="2">Shipping Address</td>
                        </tr>
                        <tr>
                            <td>Company Name</td>
                            <td><input className="company" value={invoicedata.shpadd.company} onChange={(e) => updateaddress("shpadd", "company", e)} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="name" value={invoicedata.shpadd.name} onChange={(e) => updateaddress("shpadd", "name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="phone" value={invoicedata.shpadd.phone} onChange={(e) => updateaddress("shpadd", "phone", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line1</td>
                            <td><textarea className="address" value={invoicedata.shpadd.addressline1} onChange={(e) => updateaddress("shpadd", "addressline1", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line2</td>
                            <td><textarea className="address" value={invoicedata.shpadd.addressline2} onChange={(e) => updateaddress("shpadd", "addressline2", e)} /></td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td><input className="city" value={invoicedata.shpadd.city} onChange={(e) => updateaddress("shpadd", "city", e)} /></td>
                        </tr>
                        <tr>
                            <td>Pincode</td>
                            <td><input className="pincode" value={invoicedata.shpadd.pincode} onChange={(e) => updateaddress("shpadd", "pincode", e)} /></td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                <select className="state" value={invoicedata.shpadd.state} onChange={(e) => updateaddress("shpadd", "state", e)}>
                                    <option value="">Select State</option>
                                    {provinces.map((province, i) =>
                                    <option key={i} value={province["state"]}>{province["state"]}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>PAN</td>
                            <td><input className="pan" value={invoicedata.shpadd.pan} onChange={(e) => updateaddress("shpadd", "pan", e)} /></td>
                        </tr>
                        <tr>
                            <td>GSTIN</td>
                            <td><input className="gstin" value={invoicedata.shpadd.gstin} onChange={(e) => updateaddress("shpadd", "gstin", e)} /></td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <ul className='btns'>
                                    <li>
                                        <button onClick={() => setpreview(true)}>Preview Invoice</button>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {Object.keys(errmsg).map((err) =>
                    <>{errmsg[err] !== "" && <p>{errmsg[err]}</p>}</>
                )}
            </div>
        )}
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps)(TPOrderInvoice)