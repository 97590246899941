import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import axios from 'axios'
import "../../styles/employee.css"
import { useHistory } from 'react-router-dom'

moment.tz.setDefault('Asia/Kolkata')

const JobApps = ({ user: { token, userDetails } }) => {
    let history = useHistory()
    const [appsData, setappsData] = useState(null);
    const access = userDetails.accesslevel.job_application;
    useEffect(() => {
        if (access === "NA") {
            history.push("/unauthorized")
        }
    }, [])

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'careers/jobadmin', config).then(res => {
            setappsData(res.data)
        })
    }, [])

    const updateApp = (index, key, e) => {
        let tempapp = [...appsData];
        tempapp[index][key] = e.target.value
        setappsData(tempapp)
    };

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios.post(process.env.REACT_APP_KC_API_URL + 'careers/jobadmin', appsData, config).then(res => {
            setappsData(res.data)
        })
    };

    return (
        <div className="job-apps">
            {access==="edit" && <ul className='btns'>
                <li>
                    <button onClick={() => submitHandler()}>Save</button>
                </li>
            </ul>}
            
            <table className='border even_odd table_mob inward_mob  '>
                <tbody>
                    <tr>
                        <th>Application ID</th>
                        <th>Applicant</th>
                        <th>State</th>
                        <th>Current City</th>
                        <th >Address</th>
                        <th>Qualification</th>
                        <th>Skills</th>
                        <th>Work Experience</th>
                        <th>Position Applied</th>
                        <th>Applied On</th>
                        {access==="edit" && <>
                            <th>Remarks</th>
                            <th>Status</th></>}
                        
                    </tr>
                    {appsData !== null && appsData.map((app, i) =>
                        <tr key={i}>
                            <td>{app.appid}</td>
                            <td>
                                <p><b>{app.name}</b></p>
                                <p>{app.phone}<br />
                                    <i>{app.email}</i></p>
                            </td>
                            <td>{app.province}</td>
                            <td>{app.city}</td>
                            <td>{app.address}</td>
                            <td>{app.qualification}</td>
                            <td>{app.skills}</td>
                            <td>{app.exp}</td>
                            <td>{app.pos}</td>
                            <td>{moment(app.created_at).format('DD-MM-YYYY')}</td>
                            {access==="edit" && <><td><textarea onChange={(e) => updateApp(i, "remarks", e)} value={app.remarks} /></td>
                             <td>
                             <select onChange={(e) => updateApp(i, "status", e)} value={app.status}>
                                 <option value="Pending Review">Pending Review</option>
                                 <option value="Short Listed">Short Listed</option>
                                 <option value="Interviewed">Interviewed</option>
                                 <option value="Selected">Selected</option>
                                 <option value="Rejected">Rejected</option>
                             </select>
                         </td>
                         </>}
                           
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(JobApps)