export const psspecs = [
    {
        "type": "cpu",
        "specs": [
            {
                "id": "cores",
                "text": "Cores",
                "type": "num"
            },
            {
                "id": "threads",
                "text": "Threads",
                "type": "num"
            },
            {
                "id": "base_speed",
                "text": "Base Speed",
                "postfix": "GHz",
                "type": "dec"
            },
            {
                "id": "boost_speed",
                "text": "Boost Speed",
                "postfix": "GHz",
                "type": "dec"
            },
            {
                "id": "igpu",
                "text": "Integrated Graphics",
                "type": "bool"
            },
            {
                "id": "graphics_title",
                "text": "Graphics Type",
                "type": "txt"
            },
            {
                "id": "graphics_cores",
                "text": "Graphics Cores",
                "type": "num"
            },
            {
                "id": "graphics_frequency",
                "text": "Graphics Frequency",
                "type": "num"
            },
            {
                "id": "stock_cooler",
                "text": "Stock Cooler",
                "type": "bool"
            },
            {
                "id": "supported_chipset",
                "text": "Supported Chipset",
                "type": "multiselect",
                "options": [
                    { value: "A320", label: "A320" },
                    { value: "A620", label: "A620" },
                    { value: "B350", label: "B350" },
                    { value: "B450", label: "B450" },
                    { value: "B550", label: "B550" },
                    { value: "B560", label: "B560" },
                    { value: "B650", label: "B650" },
                    { value: "B660", label: "B660" },
                    { value: "B760", label: "B760" },
                    { value: "H310", label: "H310" },
                    { value: "H410", label: "H410" },
                    { value: "H510", label: "H510" },
                    { value: "H610", label: "H610" },
                    { value: "X670", label: "X670" },
                    { value: "Z790", label: "Z790" }
                ]
            }
        ]
    },
    {
        "type": "mob",
        "specs": [
            {
                "id": "chipset",
                "text": "Chipset",
                "type": "txt",
            },
            {
                "id": "wifi",
                "text": "Wi-Fi",
                "type": "bool"
            },
            {
                "id": "max_ram",
                "text": "Max. Ram",
                "type": "txt"
            },
            {
                "id": "back_typec",
                "text": "Back Type-C",
                "type": "bool"
            },
            {
                "id": "front_typec",
                "text": "Front Type-C",
                "type": "bool"
            },
            {
                "id": "dimm_slots",
                "text": "DIMM Slots",
                "type": "num"
            },
            {
                "id": "pcie_gen5",
                "text": "PCIE Gen5",
                "type": "bool"
            },
            {
                "id": "pcie_gen4",
                "text": "PCIE Gen4",
                "type": "bool"
            },
            {
                "id": "pcie_gen3",
                "text": "PCIE Gen3",
                "type": "bool"
            },
            {
                "id": "form_factor",
                "text": "Form Factor",
                "type": "select",
                "options": ["ATX", "M-ATX", "E-ATX", "Mini-ITX", "SSIEEB"]
            },
            {
                "id": "ddr_type",
                "text": "DDR Type",
                "type": "select",
                "options": ["DDR2", "DDR3", "DDR4", "DDR5"]
            }
        ],
    },
    {
        "type": "ram",
        "specs": [
            {
                "id": "size",
                "text": "Size",
                "postfix": "GB",
                "type": "num"
            },
            {
                "id": "speed",
                "text": "Speed",
                "postfix": "MHz",
                "type": "num"
            },
            {
                "id": "hstype",
                "text": "Heat Sink Type",
                "type": "select",
                "options": ["Non Heat Sink", "Heat Sink", "ARGB"]
            },
            {
                "id": "color",
                "text": "Color",
                "type": "select",
                "options": ["Red", "White", "Black", "Grey", "Silver"]
            },
            {
                "id": "type",
                "text": "Type",
                "type": "select",
                "options": ["DDR4", "DDR5", "DDR4 ECC", "DDR5 ECC"]
            }
        ]
    },
    {
        "type": "gpu",
        "specs": [
            {
                "id": "vram",
                "text": "VRAM",
                "postfix": "GB",
                "type": "num"
            }
        ]
    },
    {
        "type": "ssd",
        "specs": [
            {
                "id": "size",
                "text": "Size",
                "type": "txt"
            },
            {
                "id": "form_factor",
                "text": "Form Factor",
                "type": "select",
                "options": ["2.5\" SATA", "M.2 SATA", "NVMe Gen3", "NVMe Gen4", "NVMe Gen5"]
            },
            {
                "id": "read_speed",
                "text": "Read Speed",
                "type": "num"
            },
            {
                "id": "write_speed",
                "text": "Write Speed",
                "type": "num"
            }
        ]
    },
    {
        "type": "hdd",
        "specs": [
            {
                "id": "size",
                "text": "Size",
                "postfix": "TB",
                "type": "num",
            },
            {
                "id": "speed",
                "text": "Speed",
                "postfix": "rpm",
                "type": "num"
            }
        ]
    },
    {
        "type": "psu",
        "specs": [
            {
                "id": "wattage",
                "text": "Wattage",
                "postfix": "Watts",
                "type": "num"
            },
            {
                "id": "rating",
                "text": "Rating",
                "type": "select",
                "options": ["None", "80+", "80+ White", "80+ Bronze", "80+ Gold", "80+ Platinum", "80+ Titanium"]
            },
            {
                "id": "type",
                "text": "Type",
                "type": "select",
                "options": ["Non-Modular", "Semi-Modular", "Fully-Modular"]
            }
        ]
    },
    {
        "type": "cooler",
        "specs": [
            {
                "id": "rgb",
                "text": "RGB",
                "type": "select",
                "options": ["None", "LED", "RGB", "ARGB"]
            },
            {
                "id": "type",
                "text": "Type",
                "type": "select",
                "options": ["Air Cooler", "Liquid Cooler", "Custom Liquid Loop"]
            },
            {
                "id": "size",
                "text": "Size",
                "postfix": "mm",
                "type": "select",
                "options": ["<120", "120", "140", "240", "280", "360", "420"]
            }
        ]
    },
    {
        "type": "tower",
        "specs": [
            {
                "id": "form_factor",
                "text": "Form Factor",
                "type": "select",
                "options": ["Compact Mid Tower", "Mid Tower", "Full Tower", "M-ATX", "ITX", "1U Server Rack", "2U Server Rack", "3U Server Rack", "4U Server Rack", "Server Tower"]
            },
            {
                "id": "sidepanel",
                "text": "Side Panel",
                "type": "select",
                "options": ["Solid", "Acrylic", "Tempered Glass"]
            },
            {
                "id": "front_typec",
                "text": "Front Type-C",
                "type": "bool",
            },
            {
                "id": "rgb",
                "text": "RGB",
                "type": "select",
                "options": ["None", "LED", "RGB", "ARGB"]
            },
            {
                "id": "case_fans",
                "text": "Pre-Installed Fans",
                "type": "num"
            },
            {
                "id": "radiator_support",
                "text": "Radiator Support",
                "type": "select",
                "options": ["120", "140", "240", "280", "360", "420"]
            }
        ]
    }
]