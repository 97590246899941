import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import KuroLink from '../components/common/KuroLink'
import moment from 'moment-timezone'
import axios from 'axios'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
moment.tz.setDefault('Asia/Kolkata')

const OutwardDebitNotes = ({ user: { token, userDetails } }) => {
    const history = useHistory();
    const [DnData, setDnData] = useState(null)
    const access = userDetails.accesslevel.outward_debitnotes
    useEffect(() => {
        if (access === "NA") {
            history.push("/unauthorized");
            return;
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwarddebitnotes?limit=30', config).then(res => {
            setDnData(res.data)
        }).catch((err) => {
            if (err.response.status == 401) {
                history.push("/unauthorized")
            }
        })
    }, [])

    var fileDownload = require('js-file-download')

    const downloadDebitNote = (debitnote_no) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwarddebitnotes?debittnote_no=' + debitnote_no + "&download=true", config)
            .then((res) => {
                fileDownload(res.data, debitnote_no + '.pdf')
            }).catch((err) => {
                if (err.response.status == 401) {
                    history.push("/unauthorized")
                }
            })
    }

    const deleteHandler = (debitnote_no) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios.delete(process.env.REACT_APP_KC_API_URL + 'kurostaff/outwarddebitnotes?debittnote_no=' + debitnote_no, config)
            .then((res) => {
                setDnData(res.data)
            }).catch((err) => {
                if (err.response.status == 401) {
                    history.push("/unauthorized")
                }
            })
    }

    return (
        <div>
            <h2 className='txt-light-grey'>Sales Debit Notes</h2>
            {access === "write" || access === "edit" && <ul className='create-dabit btns'>
                <li><KuroLink to="/create-outward-debitnotes"><button>Create Debit Credit Notes</button></KuroLink></li>
            </ul>}
            {(DnData !== null && DnData.length > 0) ? <table className="even_odd border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <th>Debit Note No</th>
                        <th>Debit Note Date</th>
                        {/* <th>Customer Name</th>
                        <th>Mobile Number</th> */}
                        <th>Debit Note Total</th>
                        {access === "edit" && <th></th>}

                    </tr>
                    {DnData !== null && DnData.map((debitnote, i) =>
                        <tr key={i}>
                            <td>{debitnote.debitnote_no}</td>
                            <td>{moment(debitnote.debitnote_date).format('DD-MM-YYYY')}</td>
                            {/* <td>{debitnote.user.name}</td>
                        <td>{debitnote.user.phone}</td> */}
                            <td>{debitnote.totalprice}</td>
                            {access === "edit" && <td>
                                <ul className='btns'>
                                    {(access === "write" || access === "edit") && <li>
                                        <button onClick={() => downloadDebitNote(debitnote.debitnote_no)}>Download Debit Note</button>
                                    </li>}
                                    {userDetails.access === "Super" && <li>
                                        <button onClick={(e) => deleteHandler(debitnote.debitnote_no)}>Delete Debit Note</button>
                                    </li>}

                                </ul>
                            </td>}

                        </tr>
                    )}
                </tbody>
            </table> : <p>No Data</p>}

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(OutwardDebitNotes)