import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CreatePaymentLink = ({ match, user: { token, userDetails } }) => {
  const { params: { orderid } } = match;
  const [errMsg, setErrMsg] = useState("");
  const [partialPayment, setPartialPayment] = useState({ visibility: false, value: 0 });
  const [paymentDetails, setPaymentDetails] = useState({
    orderid: orderid,
    phone: '',
    amount: null,
    partialpayment: 0,
    linkexpiry: null
  });
const history=useHistory();
const access=userDetails.accesslevel.indent;

useEffect(()=>{
if(access!=="edit" && access!=="write"){
  history.push("/unauthorized");
  return;
}
}, [])
  const handlePhoneChange = (e) => {
    const sanitizedValue = e.target.value.replace(/\D/g, '');
    setPaymentDetails({ ...paymentDetails, phone: sanitizedValue });
  };

  const handleAmountChange = (e) => {
    const sanitizedValue = e.target.value.replace(/\D/g, '');
    const amount = Number(sanitizedValue);
    setPaymentDetails({ ...paymentDetails, amount: amount });
  };

  const handleReset = () => {
    setPaymentDetails({
      orderid: orderid,
      phone: '',
      amount: null,
      partialpayment: 0,
      linkexpiry: null
    });
    setPartialPayment({ visibility: false, value: 0 });
  };

  const handleCheckBox = () => {
    setPartialPayment((prev) => ({ ...prev, visibility: !prev.visibility }));

  };

  const handlePartialPayment = (e) => {
    const sanitizedValue = e.target.value.replace(/\D/g, '');
    const amount = Number(sanitizedValue);
    setPartialPayment((prev) => ({ ...prev, value: amount }));
    setPaymentDetails((prev) => ({ ...prev, partialpayment: amount }));
  };

  const handleLinkExpiry = (value) => {
    const days=Number(value)
    setPaymentDetails({ ...paymentDetails, linkexpiry: days });
  };

  const handleBack=()=>{
    history.push("/inward-payments")
  }
  const handleSubmit = () => {
    console.log(paymentDetails);
    let proceed = true;
    if(paymentDetails.orderid==""){
      proceed = false;
      setErrMsg("orderid is empty")
    }
    if (paymentDetails.amount === 0) {
      proceed = false;
      setErrMsg("Enter Amount");
      console.log("entered1");
    }
    if (paymentDetails.partialpayment == null) {
      proceed = false;
      setErrMsg("Enter partial payment");
      console.log("entered1");
    }
    if (paymentDetails.linkexpiry == null) {
      proceed = false;
      setErrMsg("Set Expiry day");
      console.log("entered1");
    }
    if (paymentDetails.phone.length !== 10 || paymentDetails.phone === "") {
      proceed = false;
      setErrMsg("Enter Valid Phone Number");
      console.log("entered2");
    }

    if (proceed) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
           'Authorization': `Token ${token}`,
         
        },
      };
      // axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/payment', paymentDetails, config)
      axios.post(process.env.REACT_APP_KC_API_URL+"kuroadmin/payment", paymentDetails, config)
          .then((res) => {
            setPaymentDetails({
              orderid: '',
              phone: '',
              amount: null, 
            partialPayment:0,
          linkexpiry:null})
          }).catch((error) => {
            console.error(error);
            setErrMsg("Submission failed. Please try again.");
          });
      // setErrMsg("success");
    }
  };

  return (
    <div className="txt-light-grey">
      <h1 className='txt-light'>Create Payment Link</h1>
      <p>Link Details</p>
      <table className="border">
        <tbody>
          <tr>
            <td>Payment for</td>
            <td>{orderid}</td>
          </tr>
          <tr>
            <td>Phone number</td>
            <td>
              <input
                type='text'
                placeholder='Enter Phone Number'
                value={paymentDetails.phone}
                onChange={handlePhoneChange}
                required
              />
            </td>
          </tr>
          <tr>
            <td>Amount(Rs)</td>
            <td>
              <input
                type='text'
                placeholder='Enter Amount'
                value={paymentDetails.amount}
                onChange={handleAmountChange}
                required
              />
            </td>
          </tr>
          <tr>
            <td>Partial payments</td>
            <td>
              <input type="checkbox" onClick={handleCheckBox} />
              {partialPayment.visibility && (
                <input
                  type='text'
                  placeholder='Enter Partial Amount'
                  value={partialPayment.value}
                  onChange={handlePartialPayment}
                  required
                />
              )}
            </td>
          </tr>
          <tr>
            <td>Link Expiry</td>
            <td>
              <select name="expiry" id="expiry" onChange={(e) => handleLinkExpiry(e.target.value)}>
                <option value="">Select Expiry Day</option>
                <option value="30">30 Days</option>
                <option value="15">15 Days</option>
                <option value="7">7 Days</option>
                <option value="2">2 Days</option>
                <option value="1">1 Day</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>

      {errMsg && <p className="error_msg">{errMsg}</p>}
      <br />
      <ul className='btns'>
        <li>
          <button onClick={handleReset}>Reset</button>
        </li>
        <li>
          <button onClick={handleSubmit}>Submit</button>
        </li>
        <li><button onClick={handleBack}>back</button></li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(CreatePaymentLink);
