import { UPDATE_PROVINCES, UPDATE_VENDORS, UPDATE_INWARD_INVOICES } from '../actions/types'

const initialState = {
    provinces: [],
    vendors: null,
    inwardinvoices: null
}

const admin = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PROVINCES:
            return {
                ...state,
                provinces: action.payload
            }
        case UPDATE_VENDORS:
            return {
                ...state,
                vendors: action.payload
            }
        case UPDATE_INWARD_INVOICES:
            return {
                ...state,
                inwardinvoices: action.payload
            }
        default:
            return state
    }
}

export default admin