// Kuro User
export const USER_LOADED = 'USER_LOADED'
export const USER_LOADING = 'USER_LOADING'
export const AUTH_FAIL = 'AUTH_FAIL'
export const OTP_FAIL = 'OTP_FAIL'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGIN_MESSAGE = 'LOGIN_MESSAGE'
export const REDIRECT_TO = 'REDIRECT_TO'
// Kuro Products
export const UPDATE_COMPONENTS = 'UPDATE_COMPONENTS'
export const UPDATE_MONITORS = 'UPDATE_MONITORS'
export const UPDATE_ACCESSORIES = 'UPDATE_ACCESSORIES'
export const UPDATE_NETWORKING = 'UPDATE_NETWORKING'
export const UPDATE_EXTERNAL = 'UPDATE_EXTERNAL'
export const UPDATE_PRESETS = 'UPDATE_PRESETS'
// Kuro Admin
export const UPDATE_PROVINCES = 'UPDATE_PROVINCES'
export const UPDATE_VENDORS = 'UPDATE_VENDORS'
export const UPDATE_INWARD_INVOICES = 'UPDATE_INWARD_INVOICES'