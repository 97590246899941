import React, {useState, useEffect} from 'react'
import axios from 'axios'
import EditableOrder from '../components/EditableOrder'
import KuroLink from '../components/common/KuroLink'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const UserOrders = ({ match }) => {

    const { params: { userId } } = match;

    const [ordersData, setOrdersData] = useState(null);
const history=useHistory()
    useEffect(() => {
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/orders?phone=9492540571&userid=' + userId).then(res => {
            setOrdersData(res.data);
        }).catch((err)=>{
            if(err.response.status==401){
                history.push("/unauthorized")
            }
        })
    }, []);

    const LoadingOrders = () => {
        return(
        <div className='loading-wrapper'>
            <div className='loading-animation'></div>
        </div>
        )
    }

    return (
        <div className="orders txt-light-grey">
            <h3>Orders</h3>
            <div className="orders-list">
            {ordersData !== null ? (ordersData.length > 0 ?
             ordersData.map((order, i) =>
                <EditableOrder key={i} order={order} />
            ) : (
                "No Order Placed"
            )) : <LoadingOrders/>}
            </div>
            <p>&nbsp;</p>
            <ul className='btns'>
                <li><KuroLink to="/users"><button>Back</button></KuroLink></li>
            </ul>
            <p>&nbsp;</p>
        </div>
    )
}

export default UserOrders;