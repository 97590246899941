import React, {useEffect, useState} from 'react'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

moment.tz.setDefault('Asia/Kolkata')

const EditAttendance = ({match,  user: {token}}) => {

    let history = useHistory()
    const { params: { userid } } = match
    const [emps, setemps] = useState(null)
    const [attData, setattData] = useState(null)
    const [selectdate, setselectdate] = useState(new Date())

    useEffect(() => {
        getdata(new Date())
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/employees?userid=' + userid, config)
        .then((res) => {
            setemps(res.data)
        })
    }, [])

    const getdata = (selecteddate) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/emp_attendance?userid=' + userid + "&at_date=" + moment(selecteddate).format('DD-MM-YYYY'),  config)
        .then((res) => {
            if (res.data.length > 0) {
                setattData(res.data)
            } else {
                let tempData = [{"userid": userid, "at_date": moment(selecteddate).format('DD-MM-YYYY'), "status": ""}]
                setattData(tempData)
            }
        })
        setselectdate(selecteddate)
    }

    const updateStatus = (e) => {
        let tempData = [...attData]
        tempData[0]["status"] = e.target.value
        setattData(tempData)
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/emp_attendance', attData, config)
        .then(res => {
            setattData(res.data)
        })
        history.push("/attendance")
    }

    const backHandler = () => {
        history.push("/attendance")
    }


  return (
    <div> 
        <h3 className='txt-light-grey'>{emps !== null && emps.filter((emp) => emp.userdata.userid === userid)[0]['userdata']['name']}</h3>
        <table className='border even_odd'>
            {attData !== null && attData.map((item, i) =>
            <tbody key={i}>
                <tr>
                    <th>Date</th>
                    <td><DatePicker dateFormat='dd-MMM-yyyy' selected={selectdate} onChange={(dateItem)=> getdata(dateItem)} /></td>
                </tr>
                <tr>
                    <th>Status</th>
                    <td>
                        <select value={item.status} onChange={(e)=> updateStatus(e)} >
                            <option value="">Select Status</option>
                            <option value="Present">Present</option>
                            <option value="Absent">Absent</option>
                            <option value="Week Off">Week Off</option>
                            <option value="Half Day">Half Day</option>
                            <option value="Holiday">Holiday</option>
                            <option value="Leave">Leave</option>
                        </select>
                    </td>
                </tr>
            </tbody>
            )}
        </table>
        <div className='col_2'>
            <button onClick={submitHandler}>Submit</button>
            <button onClick={backHandler}>Back</button>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(EditAttendance)