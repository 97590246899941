import React, { useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import "../../styles/employee.css"

const CreateEmp = ({user: {token}}) => {

    const defaultemp = {
        "name": "",
        "phone": "",
        "password": "",
        "email": "",
        "role": "",
        "access": ""
    };

    const [emp, setemp] = useState(defaultemp);
    const [errorMsg, seterrorMsg] = useState("");

    const updateemp = (e, key) => {
        seterrorMsg("");
        let tempemp = {...emp};
        tempemp[key] = e.target.value;
        setemp(tempemp);
        tempemp[key] = key === 'email' ? e.target.value.toLowerCase() : e.target.value;
        setemp(tempemp);
    };

    const submitHandler = () => {
        let proceed = true;
        if (emp.name === "" || emp.role === "" || emp.access === "") {
            //name.closest(".field_wrapper").classList.add("warn");
            proceed = false;
        }
        if (emp.phone === "" || emp.phone.length < 10) {
            //phone.closest(".field_wrapper").classList.add("warn");
            proceed = false;
        } else {
            emp["password"] = emp.phone;
        }
        if (!(/(?!.*\.\.)(^[^.][^@\s]+@[^@\s]+\.[^@\s.]+$)/).test(emp.email)) {
            //email.closest(".field_wrapper").classList.add("warn");
            proceed = false;
        }
        if (proceed) {
          
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            };
            console.log(config)
            axios.post(process.env.REACT_APP_KC_API_URL + 'auth/kuroregister', emp, config)
            .then((res) => {
                if (res.data.status === "SUCCESS") {
                    setemp(defaultemp)
                    seterrorMsg("Employee details have been submitted successfully")
                } else {
                    seterrorMsg(res.data.msg);
                }
            });
        } else {
            seterrorMsg("Please Enter Valid details");
        }
    };

    return (
        <div className="employee txt-light-grey mx-width">
            <h2>Enroll New Employee</h2>
            <div className="form_wrapper">
                <div className="app_form">
                    <div className="field_wrapper">
                        <div className="label">Full Name (As Per PAN)</div>
                        <div className="field">
                            <input placeholder="Full Name" onChange={(e) => updateemp(e, "name")} value={emp.name} />
                        </div>
                    </div>
                    <div className="field_wrapper">
                        <div className="label">Mobile No.</div>
                        <div className="field">
                            <input placeholder="Mobile Number" onChange={(e) => updateemp(e, "phone")} value={emp.phone} />
                        </div>
                    </div>
                    <div className="field_wrapper">
                        <div className="label">Email Address</div>
                        <div className="field">
                            <input placeholder="Email Address" onChange={(e) => updateemp(e, "email")} value={emp.email} />
                        </div>
                    </div>
                    <div className="field_wrapper">
                        <div className="label">Role</div>
                        <div className="field">
                            <select onChange={(e) => updateemp(e, "role")} value={emp.role}>
                                <option value="">Select Role</option>
                                <option value="KC Admin">Kuro Cadence Admin</option>
                                <option value="KC Staff">Kuro Cadence Staff</option>
                                <option value="KG Staff">Kuro Gaming Staff</option>
                                <option value="RE Staff">Rebellion eSports Staff</option>
                            </select>
                        </div>
                    </div>
                    <div className="field_wrapper">
                        <div className="label">Access Level</div>
                        <div className="field">
                            <select onChange={(e) => updateemp(e, "access")} value={emp.access}>
                                <option value="">Select Data Access Level</option>
                                <option value="Basic">Basic</option>
                                <option value="Super">Super</option>
                                <option value="Read-Only">Read-Only</option>
                            </select>
                        </div>
                    </div>
                    <div className="field_wrapper">
                        <div className="label"></div>
                        <div className="field">
                            <ul className='btns'>
                                <li>
                                    <button onClick={submitHandler}>Submit</button>
                                </li>
                            </ul>
                            {errorMsg !== "" && <span className="error_msg">{errorMsg}</span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(CreateEmp)