import Hero from '../components/portal/Hero';
import { useState, useEffect, useCallback } from 'react';
import '../styles/portaleditor.css';
import { connect } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const titleOptions = {
  payment_terms: "Payment Terms",
  ds_ot: "Dispatch Schedule & Other Terms",
  po_terms: "Purchase Order Terms"
};

const PortalEditor = ({ user: { token } }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [admindata, setAdmindata] = useState({});
  const [editfalg, setEditflag] = useState(false);
  const history = useHistory();

  const fetchAdminData = useCallback(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
    };

    axios.get(`${process.env.REACT_APP_KC_API_URL}kuroadmin/adminportal`, config)
      .then((res) => {
        setAdmindata(res.data[0] || {});
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          history.push("/unauthorized");
        }
      });
  }, [token, history]);

  useEffect(() => {
    if (tabIndex === 1 && Object.keys(admindata).length === 0) {
      fetchAdminData();
    }
  }, [tabIndex, fetchAdminData]);

  const updatevalue = (key, i, e) => {
    setAdmindata(prev => ({
      ...prev,
      [key]: prev[key].map((item, idx) => idx === i ? e.target.value : item)
    }));
  };

  const removefield = (key, bindex) => {
    setAdmindata(prev => ({
      ...prev,
      [key]: prev[key].filter((_, idx) => idx !== bindex)
    }));
  };

  const addfield = (key, bindex) => {
    setAdmindata(prev => ({
      ...prev,
      [key]: [...prev[key].slice(0, bindex), "", ...prev[key].slice(bindex)]
    }));
  };

  const submitdata = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
      };

      const response = await axios.post(`${process.env.REACT_APP_KC_API_URL}kuroadmin/adminportal`, admindata, config);

      if (response.status === 200) {
        alert("Data submitted successfully");
      }
    } catch (error) {
      console.error("Error submitting data", error);
      alert("Failed to submit data");
    }
  };

  return (
    <div>
      <h1 className="txt-light">Portal Editor</h1>
      <div className="btns tabs">
        <button
          onClick={() => setTabIndex(0)}
          className={`tab txt-grey${tabIndex === 0 ? " active" : ""}`}>
          Kuro Gaming
        </button>
        <button
          onClick={() => setTabIndex(1)}
          className={`tab txt-grey${tabIndex === 1 ? " active" : ""}`}>
          Admin Portal
        </button>
      </div><br />

      {tabIndex === 0 ? (
        <Hero />
      ) : (
        <div>
          {editfalg ? (
            <>
              {Object.entries(admindata).map(([key, value]) => (
                <div key={key}>
                  <h4 className="txt-light"><b>{titleOptions[key]}</b></h4>
                  <table className="hero_table border input_mob txt-light" cellPadding="0" cellSpacing="0">
                    <tbody>
                      {Array.isArray(value) && value.map((item, i) => (
                        <tr key={i}>
                          <td>
                            <textarea
                              className="admindata_width"
                              value={item}
                              onChange={e => updatevalue(key, i, e)}
                            />
                          </td>
                          <td>
                              <span className="prod-span" onClick={() => addfield(key, i)}>
                                  <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"></path>
                                  </svg>
                              </span>
                              <span className="prod-span" onClick={() => removefield(key, i)}>
                                <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
                                  <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" />
                                </svg>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
              <ul className='btns'>
                <li>
                  <button onClick={submitdata}>Submit</button>
                </li>
                <li>
                  <button onClick={() => setEditflag(false)}>Cancel</button>
                </li>
              </ul>
            </>
          ) : (
            <>
              {Object.entries(admindata).map(([key, value]) => (
                <div key={key}>
                  <h4 className="txt-light"><b>{titleOptions[key]}</b></h4>
                  <table className="hero_table border input_mob txt-light" cellPadding="0" cellSpacing="0">
                    <tbody>
                      {Array.isArray(value) && value.map((item, i) => (
                        <tr key={i}>
                          <td>
                            {item}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
              <ul className='btns'>
                <li>
                  <button onClick={() => setEditflag(true)}>Edit</button>
                </li>
              </ul>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(PortalEditor);
