import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { getVendors } from '../actions/admin'
import { useHistory } from 'react-router-dom'
import { CurrencyFormat } from '../components/common/CurrencyFormat'
import KuroLink from '../components/common/KuroLink'
import DeleteBox from '../components/DeleteBox'
import '../styles/table.css'
import moment from 'moment-timezone'

moment.tz.setDefault('Asia/Kolkata')

const PaymentVoucher = ({ match, user: { token, userDetails }, admin: { vendors }, getVendors }) => {

    let history = useHistory()
    const access = userDetails.accesslevel.paymentvouchers;
    const { params: { pv_no } } = match
    const [voucher, setvoucher] = useState(null)
    const [newvoucher, setnewvoucher] = useState({})
    const [editflag, seteditflag] = useState(false)
    const selectAllRef = useRef(null);
    const [loaddeletebox, setloaddeletebox] = useState(false)
    const [deleteid, setdeleteid] = useState("")
    const [emps, setemps] = useState(null)
    const [tagselectall, settagselectall] = useState(false)
    const VendorOptions = vendors ? vendors.map((vendor) => ({ value: vendor.vendor_code, label: vendor.name })) : []
    const empOptions = emps ? emps.filter(emp => emp.user_status === "Active").map(emp => (emp.name)) : []
    const typesOfpv = [
        "Trading Stock",
        "Fixed Assets",
        "Expenses - Food & Entertainment",
        "Expenses - Utilities",
        "Expenses - Office Supplies",
        "Expenses - Rent",
        "Expenses - Salaries",
        "Expenses - Logistics",
        "Expenses - Financial Services",
        "Expenses - Services",
        "Expenses - Petty Cash",
        "Govt Fees and Taxes",
        "Govt Penalties",
    ]

    const tagOptionsHandler = () => {
        const tagOptions = {
            "vendor": VendorOptions,
            "Expenses - Utilities": ["Electricity Bill", "Water Bill", "Internet Service"],
            "Expenses - Salaries": empOptions,
            "Expenses - Rent": ["Rent"],
            "Expenses - Food & Entertainment": ["Food", "Beverages"],
            "Expenses - Office Supplies": ["Stationary", "Cleaning"],
            "Expenses - Financial Services": ["Payment Gateway", "POS machine"],
            "Expenses - Services": ["TP Commission", "Advertisement", "Cloud Services"],
        }
        return tagOptions[newvoucher.type] || []
    }

    useEffect(() => {
        if (access === "NA") {
            history.push("/unauthorized")
            return;
        }
    }, [])

    useEffect(() => {
        getVendors()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/employees', config)
        .then((res) => {
            setemps(res.data)
        });
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/paymentvouchers?pv_no=' + pv_no, config).then(res => {
            setvoucher(res.data[0])
            setnewvoucher(res.data[0])
        }).catch((err) => {
            if (err.response.status == 401) {
                history.push("/unauthorized")
            }
        })
    }, [pv_no, getVendors])
    
    useEffect(() => {
        if (selectAllRef.current && newvoucher) {
            if (newvoucher.tags.length === 0) {
                selectAllRef.current.indeterminate = false
                selectAllRef.current.checked = false
            } else if (newvoucher.tags.length===  tagOptionsHandler(newvoucher.type).length) {
                selectAllRef.current.indeterminate = false
                selectAllRef.current.checked = true
            } else {
                selectAllRef.current.indeterminate = true
            }
        }
    }, [newvoucher.tags, newvoucher.type])

    const editvoucher = () => {
        seteditflag(true)
    }

    const updatevoucher = (e, key, num = false) => {
        let tempvoucher = { ...newvoucher }

        if (key === "type") {
            tempvoucher[key] = e.target.value
            tempvoucher.tags = []
        } else if (key === "tags") {
            const value = e.target.value
            const checked = e.target.checked
    
            let newtags = newvoucher.tags
    
            if (newvoucher.type === "Expenses - Salaries") {
                if (checked) {
                    newtags = [value]
                } else {
                    newtags = []
                }
            } else {           
                if (checked) {
                    newtags.push(value)
                } else {
                    newtags = newtags.filter(tag => tag !== value)
                }
            }
    
            tempvoucher.tags = newtags
        } else if (key === "vendor") {
            let vendor = e.target.value.split('+')
            tempvoucher[key] = vendor[0]
            tempvoucher["gstin"] = vendor[1]
        } else {
            tempvoucher[key] = (num === true) ? Number(e.target.value) : e.target.value
        }
        setnewvoucher(tempvoucher)
    }

    const toggleselectall = () => {
        let tempvoucher = {...newvoucher}
        let selecttedTags = []
        if (tagselectall) {
            settagselectall(false)
            selecttedTags = []
        } else {     
            tagOptionsHandler(tempvoucher.type).map((tag) => {
                selecttedTags.push(tag)
            })
            settagselectall(true)
        }
        tempvoucher.tags = selecttedTags
        setnewvoucher(tempvoucher)
    }
    
    const updatevoucherdate = (key, date) => {
        let tempvoucher = { ...newvoucher }
        tempvoucher[key] = date
        setnewvoucher(tempvoucher)
    }

    const updatenarration = (e, index) => {
        let tempvoucher = { ...newvoucher }
        tempvoucher["narration"][index]["desc"] = e.target.value
        setnewvoucher(tempvoucher)
    }

    const addrow = () => {
        let tempvoucher = { ...newvoucher }
        tempvoucher["narration"].push({ 'desc': '', 'narration_date': '', 'narration_by': '' })
        setnewvoucher(tempvoucher)
    }

    const removerow = (id) => {
        let tempvoucher = { ...newvoucher }
        delete tempvoucher.narration[id]
        setnewvoucher(tempvoucher)
    }

    const submitvoucher = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/paymentvouchers?pv_no=' + newvoucher["pv_no"], newvoucher, config)
        setvoucher(newvoucher)
        seteditflag(false)
    }

    const cancelupdates = () => {
        setnewvoucher(voucher)
        seteditflag(false)
    }

    const deleteHandler = (pv_no) => {
        setloaddeletebox(true)
        setdeleteid(pv_no)
    }

    const deletevoucher = (data) => {
        setloaddeletebox(false)
        setdeleteid("")
        history.push('/payment-vouchers')

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios.delete(process.env.REACT_APP_KC_API_URL + 'kuroadmin/paymentvouchers?pv_no=' + data.pv_no, config)
    }

    const cancelHandler = () => {
        setloaddeletebox(false)
        setdeleteid("")
    }

    return (
        <div className="voucher txt-light">
            {editflag ? (
                [newvoucher !== null &&
                    <div className="edit-voucher">
                        <table className='border' cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <th>Voucher No</th>
                                    <td>
                                        {newvoucher.pv_no}
                                    </td>
                                </tr>
                                <tr>
                                    <th>PO No</th>
                                    <td>{newvoucher.po_no}</td>
                                </tr>
                                <tr>
                                    <th>Vendor</th>
                                    <td>{(vendors !== null && voucher.vendor !== "") && vendors.filter(vendor => vendor.vendor_code === voucher.vendor)[0].name}</td>
                                </tr>
                                <tr>
                                    <th>Voucher Type</th>
                                    <td>
                                        <select value={newvoucher.type} onChange={(e) => updatevoucher(e, "type")}>
                                            <option value="">Select Vocher Type</option>
                                            {typesOfpv.map((type) => <option value={type}>{type}</option>)}
                                        </select>
                                    </td>
                                </tr>
                                {newvoucher.type && tagOptionsHandler(newvoucher.type).length > 0 && (
                                <tr>
                                    <th>Tags</th>
                                    <td>
                                    {(newvoucher.type !== "Expenses - Salaries" && tagOptionsHandler(newvoucher.type).length > 1) &&
                                        <div className="checkbox-container">
                                            <span>
                                                <input
                                                    type="checkbox"
                                                    ref={selectAllRef}
                                                    checked={tagselectall}
                                                    onChange={toggleselectall}
                                                />
                                            </span>
                                            <span>Select All</span>
                                        </div>
                                    }
                                    {tagOptionsHandler(newvoucher.type).map((type) =>
                                        <div key={type} className="checkbox-container-inner">
                                            <span>
                                                <input
                                                    type="checkbox"
                                                    value={type}
                                                    checked={newvoucher.tags.includes(type)}
                                                    onChange={(e) => updatevoucher(e, "tags")}
                                                />
                                            </span>
                                            <span>{type}</span>
                                        </div>
                                    )}
                                    </td>
                                </tr>
                                )}
                                <tr>
                                    <th>Voucher Amount</th>
                                    <td>
                                        <input type="number" value={newvoucher.amount} onChange={(e) => updatevoucher(e, "amount", true)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Paid By</th>
                                    <td>
                                        <select value={newvoucher.paid_by} onChange={(e) => updatevoucher(e, "paid_by")}>
                                            <option value="Payment Pending">Payment Pending</option>
                                            <option value="KURO CADENCE LLP">KURO CADENCE LLP</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Payment Method</th>
                                    <td>
                                        <select value={newvoucher.pay_method} onChange={(e) => updatevoucher(e, "pay_method")}>
                                            <option value="">Select Payment Method</option>
                                            <option value="NEFT">NEFT</option>
                                            <option value="IMPS">IMPS</option>
                                            <option value="RTGS">RTGS</option>
                                            <option value="Cash">Cash</option>
                                            <option value="UPI">UPI</option>
                                            <option value="Cheque">Cheque</option>
                                            <option value="Debit Card">Debit Card</option>
                                            <option value="Auto Debit">Auto Debit</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Pay Account</th>
                                    <td>
                                        <select value={newvoucher.pay_account} onChange={(e) => updatevoucher(e, "pay_account")}>
                                            <option value="">Payment Pending</option>
                                            {(newvoucher.pay_method === "NEFT" || newvoucher.pay_method === "IMPS" || newvoucher.pay_method === "RTGS" || newvoucher.pay_method === "UPI") &&
                                                <>
                                                    <option value="CA INDUSIND BANK 0571">CA INDUSIND BANK 0571</option>
                                                    <option value="CA HDFC BANK 8683">CA HDFC BANK 8683</option>
                                                </>
                                            }
                                            {newvoucher.pay_method === "Debit Card" &&
                                                <>
                                                    <option value="DC INDUSIND BANK 0443">DC INDUSIND BANK 0443</option>
                                                    <option value="DC INDUSIND BANK 0484">DC INDUSIND BANK 0484</option>
                                                </>
                                            }
                                            {newvoucher.pay_method === "Cash" &&
                                                <option value="Cash">Cash</option>
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Pay Date</th>
                                    <td>
                                        <DatePicker selected={newvoucher.pay_date === "" ? new Date() : new Date(newvoucher.pay_date)} className="large" dateFormat='dd-MMM-yyyy' onChange={date => updatevoucherdate("pay_date", date)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Pay Ref/UTR</th>
                                    <td>
                                        <input value={newvoucher.pay_ref_utr} onChange={(e) => updatevoucher(e, "pay_ref_utr")} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Payment Narration</th>
                                    <td>
                                        {newvoucher.narration.length > 0 ? (
                                            newvoucher.narration.map((item, i) =>
                                                <div key={i}>
                                                    <textarea value={item.desc} onChange={(e) => updatenarration(e, i)} />
                                                    <span className="prod-span" onClick={() => removerow(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                    {i === newvoucher.narration.length - 1 && <span className="prod-span" onClick={() => addrow()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>}
                                                </div>
                                            )
                                        ) : (
                                            <span className="prod-span" onClick={() => addrow()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ul className="btns">
                            <li><button onClick={submitvoucher}>Submit</button></li>
                            <li><button onClick={cancelupdates}>Cancel</button></li>
                        </ul>
                    </div>
                ]
            ) : (
                <div className="voucher">
                    {access === "edit" && <ul className="btns">
                        <li><button onClick={editvoucher}>Edit the Voucher Details</button></li>
                    </ul>}

                    {voucher !== null && newvoucher !== null &&
                        <table className="border" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    <th>Voucher No</th>
                                    <td>{voucher.pv_no}</td>
                                </tr>
                                <tr>
                                    <th>PO No.</th>
                                    <td>{voucher.po_no}</td>
                                </tr>
                                <tr>
                                    <th>Vendor</th>
                                    <td>{(vendors !== null && voucher.vendor !== "") && vendors.filter(vendor => vendor.vendor_code === voucher.vendor)[0].name}</td>
                                </tr>
                                {voucher.type && (
                                    <>
                                        <tr>
                                            <th>Voucher Type</th>
                                            <td>{voucher.type}</td>
                                        </tr>
                                        <tr> <th>Voucher Tags</th>
                                            {voucher.tags.length > 0 ? <td>{voucher.tags.join(",")}</td> : <td>No Tags</td>}
                                        </tr>

                                    </>
                                )}


                                <tr>
                                    <th>Amount</th>
                                    <td>{CurrencyFormat(voucher.amount)}</td>
                                </tr>
                                <tr>
                                    <th>Paid By</th>
                                    <td>{voucher.paid_by}</td>
                                </tr>
                                <tr>
                                    <th>Payment Method</th>
                                    <td>{voucher.pay_method}</td>
                                </tr>
                                <tr>
                                    <th>Pay Account</th>
                                    <td>{voucher.pay_account}</td>
                                </tr>
                                <tr>
                                    <th>Pay Date</th>
                                    <td>{voucher.pay_date === "" ? "" : moment(voucher.pay_date).format('DD-MM-YYYY')}</td>
                                </tr>
                                <tr>
                                    <th>Pay Ref/UTR</th>
                                    <td>{voucher.pay_ref_utr}</td>
                                </tr>
                                <tr>
                                    <th>Payment Narration</th>
                                    <td>
                                        {voucher.narration.map((item, j) =>
                                            <div key={j}>
                                                {item.desc} - {item.narration_date === "" ? "" : item.narration_date} - {item.narration_by}
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    }
                    <ul className="btns">
                        {userDetails.access === "Super" && <li><button onClick={(e) => deleteHandler(voucher.pv_no)}>Delete PaymentVoucher</button></li>}

                        <li><KuroLink to="/payment-vouchers"><button>Back</button></KuroLink> </li>
                    </ul>
                </div>
            )}
            {loaddeletebox && <DeleteBox msg="Are you sure you want to delete the PaymentVoucher?" okhandler={deletevoucher} cancelhandler={cancelHandler} data={{ "pv_no": deleteid }} />}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(PaymentVoucher)