import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import KuroLink from '../components/common/KuroLink'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const InvoiceCredit = ({ match, user: {token, userDetails} }) => {

    const { params: { fin_year, invoice_no } } = match
    const [cndata, setcndata] = useState(null)
    const history=useHistory();
    const access=userDetails.accesslevel.inward_debitnotes;
    useEffect(() => {
        if(access!=="edit"){
            history.push("/unauthorized");
            return;
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwardinvoices?invoice_no=' + invoice_no + "&fin_year=" + fin_year, config).then(res => {
            setcndata(res.data[0])
        }).catch((err)=>{
            if(err.response.status==401){
                history.push("/unauthorized")
            }
        })
    }, [])

    const updatedate = (date) => {
        let tempcreditnote = {...cndata}
        tempcreditnote.creditnote_date = (date == null) ? "" : date
        setcndata(tempcreditnote)
    }

    var fileDownload = require('js-file-download')

    const generatecreditnote = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let invoicedata = cndata
        invoicedata["creditnote_issued"] = true

        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwardinvoices?invoice_no=' + invoice_no + "&fin_year=" + fin_year, invoicedata, config).then(res => {
            axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwardcreditnotes', cndata, config).then(res => {
                fileDownload(res.data, res.data.creditnote_no + '.pdf')
            })
            setcndata(res.data[0])
        }).catch((err)=>{
            if(err.response.status==401){
                history.push("/unauthorized")
            }
        })
    }

    return (
        <div className='txt-light-grey'>
            <h2>Generate Credit Note</h2>
            {cndata !== null &&
            <div className="creditnote-wrap">
                {cndata.builds.length > 0 && 
                <div className='builds'>
                    {cndata.builds.map((build, i) =>
                    <table key={i} className="build border" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                            <td colSpan="3">Title</td>
                                <td>Price</td>
                                <td>Quantity</td>
                            </tr>
                            <tr>
                                <td colSpan="3">
                                    {build.title}<br/>
                                    {build.components.map((comp) =>
                                        comp.title !== "" && 
                                        <>
                                        {comp.title}<br/>
                                        </>
                                    )}
                                </td>
                                <td>{build.price}</td>
                                <td>{build.quantity}</td>
                            </tr>
                            
                        </tbody>
                    </table>
                    )}
                </div>
                }
                {cndata.products.length > 0 &&
                <div className='products'>
                    <h3>Products</h3>
                    <table className="border" cellSpacing="0" cellPadding="0">
                        <tbody>
                            {cndata.products.map((prod, i) =>
                            <tr key={i}>
                                <td colSpan="3">{prod.title}</td>
                                <td>{prod.quantity}</td>
                                <td>{prod.price}</td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                }
                <p></p>
                <table className="border" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Taxable Amount</td>
                            <td>{cndata.totalpricebgst}</td>
                        </tr>
                        <tr>
                            <td>IGST</td>
                            <td>{cndata.gst}</td>
                        </tr>
                        <tr>
                            <td>SGST</td>
                            <td>{cndata.cgst}</td>
                        </tr>
                        <tr>
                            <td>CGST</td>
                            <td>{cndata.cgst}</td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>{cndata.totalprice}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="border" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Credit Note Date</td>
                            <td>
                                <DatePicker selected={cndata.creditnote_date && new Date(cndata.creditnote_date)} dateFormat='dd-MMM-yyyy' onChange={date => updatedate(date)} />
                            </td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{cndata.user.name}</td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td>{cndata.user.phone}</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Billing Address</td>
                        </tr>
                        <tr>
                            <td>Company Name</td>
                            <td>{cndata.billadd.company}</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{cndata.billadd.name}</td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td>{cndata.billadd.phone}</td>
                        </tr>
                        <tr>
                            <td>Address Line1</td>
                            <td>{cndata.billadd.addressline1}</td>
                        </tr>
                        <tr>
                            <td>Address Line2</td>
                            <td>{cndata.billadd.addressline2}</td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td>{cndata.billadd.city}</td>
                        </tr>
                        <tr>
                            <td>Pincode</td>
                            <td>{cndata.billadd.pincode}</td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>{cndata.billadd.state}</td>
                        </tr>
                        <tr>
                            <td>GSTIN</td>
                            <td>{cndata.billadd.gstin}</td>
                        </tr>
                        <tr>
                            <td>PAN</td>
                            <td>{cndata.billadd.pan}</td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <ul className='btns'>
                                    <li>
                                        <button onClick={generatecreditnote}>Generate Credit Note</button>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='col_2'>
                    <KuroLink to="/outward-invoices"><button>Back</button></KuroLink>
                </div>
            </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(InvoiceCredit)