import { useState, useEffect, useRef, useMemo } from "react"; 
import { connect } from 'react-redux';
import axios from 'axios';
import { utils, writeFile } from 'xlsx';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const EmployeesSalary = ({ user: { token, userDetails } }) => {
    const [selectAll, setSelectAll] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [salarysheet, setSalarysheet] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const history=useHistory();
    const access=userDetails.accesslevel.employees_salary;

    const pt = useMemo(() => [
        { min: 0, max: 15000, pt: 0 },
        { min: 15001, max: 20000, pt: 150 },
    ], []);

    const selectAllRef = useRef();

    useEffect(() => {
        if(access==="NA"){
            history.push("/unauthorized")
            return;
        }
        const fetchEmployees = async () => {
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_KC_API_URL}employeesdata`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Token ${token}`,
                        },
                    }
                );

    
                const updatedData = data.map(employee => {
                    const { userid__name, ...rest } = employee;
                    return {
                        ...rest,
                        name: userid__name,
                        working_days: 0,
                        monthly_offs: 0,
                        present_days: 0,
                        salary_as_per_pd: 0,
                        professionalTax: employee.salary <= 20000 ? (pt.find(item => employee.salary >= item.min && employee.salary <= item.max)?.pt) ?? undefined: 200,
                        advance: 0,
                        overtime: 0,
                        net_salary: 0,
                        amount_payable: 0,
                    };
                    
                });

                setEmployees(updatedData);
            } catch (err) {
                console.error(err);
            }
        };
    
        fetchEmployees();
    }, [token, pt]);

    useEffect(() => {
        const allSelected = employees.length > 0 && salarysheet.length === employees.length;
        setSelectAll(allSelected);

        if (selectAllRef.current) {
            selectAllRef.current.indeterminate = salarysheet.length > 0 && !allSelected;
            selectAllRef.current.checked = allSelected;
        }
    }, [salarysheet, employees]);

    const handleSelectAll = () => {
        if (employees.every(({ present_days,  amount_payable }) =>
            present_days > 0 && amount_payable > 0
        )) {
            setErrorMsg("");
            setSalarysheet(selectAll ? [] : [...employees]);
        } else {
            setErrorMsg("Please fill in all details before selecting.");
        }
    };

    const handleCheckboxChange = (employee) => {
        if (employee.present_days > 0 && employee.amount_payable > 0) {
            setErrorMsg("");
            setSalarysheet(prevSheet => {
                const isSelected = prevSheet.some(item => item.userid === employee.userid);
                return isSelected
                    ? prevSheet.filter(item => item.userid !== employee.userid)
                    : [...prevSheet, employee];
            });
        } else {
            setErrorMsg("Please fill in all details before selecting.");
        }
    };

    const roundToTwoDecimalPlaces = (num) => Math.round(num * 100) / 100;

    const handleInputChange = (userid, field, value) => {
        const numValue = Number(value);
        if (numValue < 0) {
            setErrorMsg("Values cannot be negative.");
            return;
        }
    
        setEmployees(prevEmployees => prevEmployees.map(employee => {
            if (employee.userid !== userid) return employee;
    
            const updatedEmployee = {
                ...employee,
                [field]: numValue,
            };
    
            const { salary, working_days, present_days, monthly_offs, overtime, advance } = updatedEmployee;
            if(field === 'professionalTax'){
                updatedEmployee.professionalTax = value
            }
            if (field !== 'amount_payable') {
                
    
                const totalPresentDays = Math.min(present_days + monthly_offs, working_days);
                const salaryAsPerPD = (salary / working_days) * totalPresentDays;
    
                updatedEmployee.salary_as_per_pd = roundToTwoDecimalPlaces(salaryAsPerPD);
                updatedEmployee.net_salary = roundToTwoDecimalPlaces(salaryAsPerPD + (overtime - updatedEmployee.professionalTax) - advance);
                updatedEmployee.amount_payable = updatedEmployee.net_salary;
               
            }
    
            return updatedEmployee;
        }));
    };
    

    const salarySheetSubmission = async () => {
        try {
            if (salarysheet.length > 0) {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_KC_API_URL}kuroadmin/bulk_payments?empdata=true`,
                    salarysheet,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Token ${token}`,
                        },
                    }
                );
                console.log(data);

                const wb = utils.book_new();
                const ws = utils.json_to_sheet(data);
                utils.book_append_sheet(wb, ws, 'empSalaries');
                writeFile(wb, 'empSalaries.xlsx');
            } else {
                setErrorMsg("Please select at least one document before submitting.");
            }
        } catch (err) {
            console.error("Error submitting salary sheet", err);
        }
    };

    return (
        <div className='audit mx-width txt-light-grey'>
            <h2>Employees Salary</h2>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <input
                                type="checkbox"
                                ref={selectAllRef}
                                checked={selectAll}
                                onChange={handleSelectAll}
                            />
                        </td>
                        <td>Select all</td>
                        {errorMsg && <td style={{ color: 'red', fontSize: "15px" }}>{errorMsg}</td>}
                    </tr>
                </tbody>
            </table>

            <table className="border even_odd" cellSpacing="0" cellPadding="0">
                <thead>
                    <tr>
                        <th>Select</th>
                        <th>Employee ID</th>
                        <th>Employee Name</th>
                        <th>Salary</th>
                        <th>Total Working Days</th>
                        <th>Monthly Offs</th>
                        <th>Total Present Days</th>
                        <th>Salary as per PD</th>
                        <th>Professional Tax</th>
                        <th>Advance</th>
                        <th>Over Time (OT)</th>
                        <th>Net Salary</th>
                        <th>Amount Payable</th>
                    </tr>
                </thead>
                <tbody>
                    {employees.map(employee => {
                        const isChecked = salarysheet.some(item => item.userid === employee.userid);
                        return (<tr key={employee.userid}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={() => handleCheckboxChange(employee)}
                                    disabled={access !== "edit"}  
                                />
                            </td>
                            <td>{employee.userid}</td>
                            <td>{employee.name}</td>
                            <td>{employee.salary}</td>
                            <td>
                                {access === "edit" ? (
                                    <input
                                        type="number"
                                        placeholder="Working Days"
                                        value={employee.working_days}
                                        onChange={e => handleInputChange(employee.userid, 'working_days', e.target.value)}
                                    />
                                ) : (
                                    employee.working_days
                                )}
                            </td>
                            <td>
                                {access === "edit" ? (
                                    <input
                                        type="number"
                                        placeholder="Monthly Offs"
                                        value={employee.monthly_offs}
                                        onChange={e => handleInputChange(employee.userid, 'monthly_offs', e.target.value)}
                                    />
                                ) : (
                                    employee.monthly_offs
                                )}
                            </td>
                            <td>
                                {access === "edit" ? (
                                    <input
                                        type="number"
                                        placeholder="Present Days"
                                        value={employee.present_days}
                                        onChange={e => handleInputChange(employee.userid, 'present_days', e.target.value)}
                                    />
                                ) : (
                                    employee.present_days
                                )}
                            </td>
                            <td>{employee.salary_as_per_pd}</td>
                            <td>
                                {access === "edit" ? (
                                    <input
                                        type="number"
                                        placeholder="professionalTax"
                                        value={employee.professionalTax}
                                        onChange={e => handleInputChange(employee.userid, 'professionalTax', e.target.value)}
                                    />
                                ) : (
                                    employee.advance
                                )}
                            </td>
                            <td>
                                {access === "edit" ? (
                                    <input
                                        type="number"
                                        placeholder="Advance"
                                        value={employee.advance}
                                        onChange={e => handleInputChange(employee.userid, 'advance', e.target.value)}
                                    />
                                ) : (
                                    employee.advance
                                )}
                            </td>
                            <td>
                                {access === "edit" ? (
                                    <input
                                        type="number"
                                        placeholder="Over Time"
                                        value={employee.overtime}
                                        onChange={e => handleInputChange(employee.userid, 'overtime', e.target.value)}
                                    />
                                ) : (
                                    employee.overtime
                                )}
                            </td>
                            <td>{employee.net_salary}</td>
                            <td>
                                {access === "edit" ? (
                                    <input
                                        type="number"
                                        placeholder="Amount Payable"
                                        value={employee.amount_payable}
                                        onChange={e => handleInputChange(employee.userid, 'amount_payable', e.target.value)}
                                    />
                                ) : (
                                    employee.amount_payable
                                )}
                            </td>
                        </tr>
                        
                        );
                    })}
                </tbody>
            </table>
            <ul className='btns'>
                <li>
                    <button onClick={salarySheetSubmission}>Submit</button>
                </li>
            </ul>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user,
});

export default connect(mapStateToProps)(EmployeesSalary);
