import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import axios from 'axios'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
moment.tz.setDefault('Asia/Kolkata')

const OutwardCreditNotes = ({ user: { token, userDetails } }) => {
    const access = userDetails.accesslevel.outward_creditnotes;
    const history = useHistory();
    const [cnData, setcnData] = useState(null)

    useEffect(() => {
        if (access === "NA") {
            history.push("/unauthorized");
            return;
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwardcreditnotes?limit=30', config).then(res => {
            setcnData(res.data)
        }).catch((err) => {
            if (err.response.status == 401) {
                history.push("/unauthorized")
            }
        })
    }, [])

    var fileDownload = require('js-file-download')

    const downloadcreditnote = (creditnote_no) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwardcreditnotes?creditnote_no=' + creditnote_no + "&download=true", config)
            .then((res) => {
                fileDownload(res.data, creditnote_no + '.pdf')
            }).catch((err) => {
                if (err.response.status == 401) {
                    history.push("/unauthorized")
                }
            })
    }

    const deleteHandler = (creditnote_no) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios.delete(process.env.REACT_APP_KC_API_URL + 'kurostaff/outwardcreditnotes?creditnote_no=' + creditnote_no, config)
            .then((res) => {
                setcnData(res.data)
            }).catch((err) => {
                if (err.response.status == 401) {
                    history.push("/unauthorized")
                }
            })
    }
    return (
        <div>
            <h2 className="txt-light-grey">Sales Credit Notes</h2>
            {(cnData !== null && cnData.length > 0) ? <table className="even_odd border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <th>Credit Note No</th>
                        <th>Credit Note Date</th>
                        <th>Customer Name</th>
                        <th>Mobile Number</th>
                        <th>Credit Note Total</th>
                        {(access === "write" || access === "edit") && <th></th>}
                    </tr>
                    {cnData !== null && cnData.map((creditnote, i) =>
                        <tr key={i}>
                            <td>{creditnote.creditnote_no}</td>
                            <td>{moment(creditnote.creditnote_date).format('DD-MM-YYYY')}</td>
                            <td>{creditnote.user.name}</td>
                            <td>{creditnote.user.phone}</td>
                            <td>{creditnote.totalprice}</td>
                            <td>
                                <ul className='btns'>
                                    {(access === "write" || access === "edit") && <li>
                                        <button onClick={() => downloadcreditnote(creditnote.creditnote_no)}>Download Credit Note</button>
                                    </li>}
                                    {userDetails.access === "Super" && <li>
                                        <button onClick={(e) => deleteHandler(creditnote.creditnote_no)}>Delete Credit Note</button>
                                    </li>}

                                </ul>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table> : (<p className="txt-light-grey">No data</p>)}

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(OutwardCreditNotes)