import { USER_LOADED, USER_LOADING, AUTH_FAIL, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, OTP_FAIL, LOGIN_MESSAGE, REDIRECT_TO } from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    isLoading: false,
    userDetails: null,
    redirectTo: "",
    loginMsg: ""
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                userDetails: action.payload,
                loginMsg: ""
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.token)
            return {
                ...state,
                token: action.payload.token,
                isAuthenticated: true,
                isLoading: false,
                userDetails: action.payload.user,
                loginMsg: ""
            }
        case AUTH_FAIL:
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
        case OTP_FAIL:
            localStorage.removeItem('token')
            return {
                ...state,
                token: null,
                userDetails: null,
                isAuthenticated: false,
                isLoading: false,
            }
        case LOGIN_MESSAGE:
            return {
                ...state,
                loginMsg: action.msg
            }
        case REDIRECT_TO:
            return {
                ...state,
                redirectTo: action.url
            }
        default:
            return state
    }
}