import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

moment.tz.setDefault('Asia/Kolkata')


const OfflineOrderStatus = ({match, user: {token}}) => {

    const { params: { orderid } } = match
    const [orderdata, setorderdata] = useState(null)
    let history = useHistory()

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/kgorders?orderid=' + orderid, config).then(res => {
            setorderdata(res.data[0])
        }).catch((err)=>{
            if(err.response.status==401){
                history.push("/unauthorized")
            }
        })
    }, [])

    const orderStatus = (e, key) => {
        let temporder = {...orderdata}
        temporder[key] = e.target.value
        setorderdata(temporder)
    }

    const shippingHandler = (e, key) => {
        let temporder = {...orderdata}
        temporder[key] = e.target.value
        setorderdata(temporder)
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/kgorders?orderid=' + orderid, orderdata, config).then(res => {
            setorderdata(res.data[0])
        }).catch((err)=>{
            if(err.response.status==401){
                history.push("/unauthorized")
            }
        })

    }

    const backHandler = () => {
        history.push("/offlineorders")
    }


  return (
    <div>
        {orderdata !== null &&
        <div>
            <table className="border txt-light" cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <th>OrderId</th>
                        <th colSpan="2">Order Details</th>
                        <th>Amount</th>
                        <th>Customer Details</th>
                        <th colSpan="2">Billing Address</th>
                        <th colSpan="2">Shipping Address</th>
                    </tr>
                    <tr>
                        <td>{orderdata.orderid}</td>
                        <td colSpan="2">
                            <p>PO/Ref: {orderdata.po_ref}</p>
                            <p>Order Date: {moment(orderdata.order_date).format('DD-MM-YYYY')}</p>
                        </td>
                        <td>
                            <p className='txt-light'>Order Total: <b>{orderdata.totalprice}</b></p>
                            <p className='txt-light'>Amount Due: <b>{orderdata.amount_due}</b></p>
                        </td>
                        <td>
                            <p>Name: {orderdata.user.name}</p>
                            <p>Mobile: {orderdata.user.phone}</p>
                        </td>
                        <td colSpan="2">
                            <p>
                                {orderdata.billadd.name && 
                                <>
                                {orderdata.billadd.name}<br/>
                                </>
                                }
                                {orderdata.billadd.phone && 
                                <>
                                {orderdata.billadd.phone}<br/>
                                </>}
                                {orderdata.billadd.addressline1 && 
                                <>
                                {orderdata.billadd.addressline1}, &nbsp;
                                </>
                                }
                                {orderdata.billadd.addressline2 && 
                                <>
                                {orderdata.billadd.addressline2}<br/>
                                </>
                                }
                                {orderdata.billadd.city && 
                                <>
                                {orderdata.billadd.city}<br/>
                                </>
                                }
                                {orderdata.billadd.state && 
                                <>
                                {orderdata.billadd.state}
                                </>
                                }
                                {orderdata.billadd.pincode && 
                                <>
                                &nbsp;- {orderdata.billadd.pincode}<br/>
                                </>
                                }
                                {orderdata.billadd.gstin && 
                                <>
                                {orderdata.billadd.gstin}<br/>
                                </>}
                                {orderdata.billadd.pan && 
                                <>
                                {orderdata.billadd.pan}<br/>
                                </>
                                }
                            </p>
                        </td>
                        {orderdata.addressflag ? (
                        <td colSpan="2">
                            <p>
                                {orderdata.billadd.name && 
                                <>
                                {orderdata.billadd.name}<br/>
                                </>
                                }
                                {orderdata.billadd.phone && 
                                <>
                                {orderdata.billadd.phone}<br/>
                                </>}
                                {orderdata.billadd.addressline1 && 
                                <>
                                {orderdata.billadd.addressline1}, &nbsp;
                                </>
                                }
                                {orderdata.billadd.addressline2 && 
                                <>
                                {orderdata.billadd.addressline2}<br/>
                                </>
                                }
                                {orderdata.billadd.city && 
                                <>
                                {orderdata.billadd.city}<br/>
                                </>
                                }
                                {orderdata.billadd.state && 
                                <>
                                {orderdata.billadd.state}
                                </>
                                }
                                {orderdata.billadd.pincode && 
                                <>
                                &nbsp;- {orderdata.billadd.pincode}<br/>
                                </>
                                }
                                {orderdata.billadd.gstin && 
                                <>
                                {orderdata.billadd.gstin}<br/>
                                </>}
                                {orderdata.billadd.pan && 
                                <>
                                {orderdata.billadd.pan}<br/>
                                </>
                                }
                            </p>
                        </td>
                        ) : (
                        <td>
                            <p> {orderdata.shpadd.name && 
                                <>
                                {orderdata.shpadd.name}<br/>
                                </>
                                }
                                {orderdata.shpadd.phone && 
                                <>
                                {orderdata.shpadd.phone}<br/>
                                </>}
                                {orderdata.shpadd.addressline1 && 
                                <>
                                {orderdata.shpadd.addressline1}, &nbsp;
                                </>
                                }
                                {orderdata.shpadd.addressline2 && 
                                <>
                                {orderdata.shpadd.addressline2}<br/>
                                </>
                                }
                                {orderdata.shpadd.city && 
                                <>
                                {orderdata.shpadd.city}<br/>
                                </>
                                }
                                {orderdata.shpadd.state && 
                                <>
                                {orderdata.shpadd.state} &nbsp;   &nbsp;
                                </>
                                }
                                {orderdata.shpadd.pincode && 
                                <>
                                &nbsp;- {orderdata.shpadd.pincode}<br/>
                                </>
                                }
                                {orderdata.shpadd.gstin && 
                                <>
                                {orderdata.shpadd.gstin}<br/>
                                </>}
                                {orderdata.shpadd.pan && 
                                <>
                                {orderdata.shpadd.pan}<br/>
                                </>
                                }
                            </p>
                        </td>
                        )}
                    </tr>
                </tbody>
            </table>
        </div>
        }
        {orderdata !== null &&
        <div className='order-status'>
            {orderdata.order_status !== "Created" && orderdata.order_status !== "Authorized" &&
            <table className="border txt-light" cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <th>Order Status</th>
                        {orderdata.order_status === "Shipped" &&
                        <>
                        <th>Shp Agency</th>
                        <th>Shp Url</th>
                        <th>Shp AWB</th>
                        </>
                        }
                    </tr>
                    <tr>
                        <td>
                            <select className="order_status" value={orderdata.order_status} onChange = {(e) => orderStatus(e, "order_status")}>
                                <option value="Inventory Added">Inventory Added</option>
                                <option value="Build Started">Build Started</option>
                                <option value="Build Completed">Build Completed</option>
                                <option value="Testing Started">Testing Started</option>
                                <option value="Testing Completed">Testing Completed</option>
                                <option value="Packed">Packed</option>
                                <option value="Shipped">Shipped</option>
                                <option value="Delivered">Delivered</option>
                                <option value="Cancelled">Cancelled</option>
                                <option value="Refunded">Refunded</option>
                            </select>
                        </td>
                        {orderdata.order_status === "Shipped" &&
                        <>
                            <td>
                                <select className="shp_agency" value={orderdata.shp_agency} onChange={(e) => shippingHandler(e, "shp_agency")}>
                                    <option value="">Select One</option>
                                    <option value="Delhivery">Delhivery</option>
                                    <option value="BlueDart">BlueDart</option>
                                    <option value="FedEx">FedEx</option>
                                    <option value="DTDC">DTDC</option>
                                    <option value="Xpressbees">Xpressbees</option>
                                    <option value="Amazon Shipping">Amazon Shipping</option>
                                    <option value="DHL">DHL</option>
                                    <option value="Ecom Express">Ecom Express</option>
                                    <option value="Dunzo">Dunzo</option>
                                    <option value="Porter">Porter</option>
                                    <option value="Rapido">Rapido</option>
                                    <option value="Gati">Gati</option>
                                    <option value="Shadowfax">Shadowfax</option>
                                    <option value="WeFast">WeFast</option>
                                    <option value="DotZot">DotZot</option>
                                    <option value="Ekart Logistics">Ekart Logistics</option>
                                    <option value="Aramex International">Aramex International</option>
                                    <option value="Kerry Indev Express">Kerry Indev Express</option>
                                </select>
                            </td>
                            <td><input className="shp_url" value={orderdata.shp_url} onChange={(e) => shippingHandler(e, "shp_url")}/></td>
                            <td><input className="shp_awb" value={orderdata.shp_awb} onChange={(e) => shippingHandler(e, "shp_awb")}/></td>
                        </>
                        }
                    </tr>
                </tbody>
            </table>
            }
            <ul className='btns'>
                <li><button onClick={submitHandler}>Submit</button></li>
                <li><button onClick={backHandler}>Back</button></li>
            </ul>
        </div>
        }
    </div>
  )
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(OfflineOrderStatus)