import React, { useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'

const Inventorydetails = ({ user: {token}, admin: {inwardinvoices}, products: { compData, accessData, monitorData, extDeviceData, networkData }}) => {

    const [srno, setsrno] = useState("")
    const [prods, setprods] = useState(null)

    const inputHandler = (e) => {
        setsrno(e.target.value.toUpperCase())
    }

    const getdetails = () => {
        if(srno.length > 0){
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }
            axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/inventory?sr_no=' + srno, config).then(res => {
                setprods(res.data)
            })
        }
    }

    const resetDetails = () => {
        setprods(null)
        setsrno("")
    }

    const getprodtitle = (productid, collection) => {
        let collData = null
        if (collection === "components") {
            collData = compData
        } else if (collection === "accessories") {
            collData = accessData
        } else if (collection === "monitors") {
            collData = monitorData
        } else if (collection === "external") {
            collData = extDeviceData
        } else if (collection === "networking") {
            collData = networkData
        }
        let title = collData.filter(prod => prod.productid === productid)[0]["title"]
        return title
    }

    return (
        <div>
            <div className='col_2'>
                <h4 className='txt-light'>Enter Serial No.</h4>
                <span><input onChange={inputHandler} value={srno} /></span>
                <button onClick={getdetails}>Get Details</button>
                {srno.length > 0 && <button onClick={resetDetails}>Reset</button>}
            </div>
            <div>
            {prods !== null &&
                (prods.length > 0 ? (
                    prods.map((prod, i) => 
                    <table key={i} className='border txt-light tableid_mobi' cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <th>Title</th>
                                <td>{getprodtitle(prod.productid, prod.collection)}</td>
                            </tr>
                            <tr>
                                <th>Invoice No</th>
                                <td>{inwardinvoices !== null && inwardinvoices.filter(item => item.invoiceid === prod.invoiceid)[0]["invoice_no"]}</td>
                            </tr>
                            <tr>
                                <th>Invoice Date</th>
                                <td>{prod.invoice_date}</td>
                            </tr>
                            <tr>
                                <th>Serial No</th>
                                <td>{prod.sr_no}</td>
                            </tr>
                        </tbody>
                    </table>
                    )
                ) : (
                    <p className='txt-light-grey'>No Stock found matching the Serial Number</p>
                ))
            }
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps)(Inventorydetails)