import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'

moment.tz.setDefault('Asia/Kolkata')

const Dashboard = ({user: {token}}) => {

    const [attData, setattData] = useState(null)
    const [emps, setemps] = useState(null)

    useEffect(() => {const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
    }

    const months = [1,2,3,4,5,6,7,8,9,10,11,12]
    const date = new Date()
    let month = months[date.getMonth()]
    let year = new Date().getFullYear()

    axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/emp_dashboard?month=' + month + '&year=' + year, config)
    .then((res) => {
        setattData(res.data)
    })

    axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/employees', config)
    .then((res) => {
        setemps(res.data)
    })

    }, [])

    return (
    <div className='dashboard'>
        <h3 className='txt-light-grey'>Employees Attendance</h3>
        <div className='att-dashboard'>
            <table className='border even_odd'>
                <tbody>
                    <tr>
                        <th>UserId</th>
                        <th>Name</th>
                        <th>Present</th>
                        <th>Absent</th>
                        <th>Half Day</th>
                        <th>Holiday</th>
                        <th>Leave</th>
                        <th>Week Off</th>
                    </tr>
                    {attData !== null && emps !== null && attData.map((att, i) =>
                    !att.userid.startsWith("KCAD") &&
                    <tr key={i}>
                        <td>{att['userid']}</td>
                        <td>{emps.filter(emp => emp.userid === att.userid)[0]["name"]}</td>
                        <td>{att['Present']}</td>
                        <td>{att['Absent']}</td>
                        <td>{att['Half Day']}</td>
                        <td>{att['Holiday']}</td>
                        <td>{att['Leave']}</td>
                        <td>{att['Week Off']}</td>
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(Dashboard)
