import React, {useState, useEffect} from 'react'
import axios from 'axios'

const EditableOrder = ({ order , access}) => {

    const [orderInfo, setorderInfo] = useState(null)
    const [orderData, setorderData] = useState(null)

    useEffect(() => {
        setorderInfo(order)
        setorderData(order)
    },[order])

    const categoryHandler = (category) => {
        switch (category) {
            case "component":
                return "/p/"
            case "monitor":
                return "/m/"
            case "build":
                return "/b/"
            case "accessory":
                return "/a/"
            case "networking":
                return "/n/"
            case "external":
                return "/e/"
            default:
                return "/p/"
        }
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        let data = {'orderid': orderInfo["orderid"]}
        if (orderData["order_status"] !== orderInfo["order_status"]) {
                data["order_status"] = orderInfo["order_status"]
                if (orderInfo["order_status"] === "Shipped") {
                    data["shp_agency"] = orderInfo["shp_agency"]
                    data["shp_url"] = orderInfo["shp_url"]
                    data["shp_awb"] = orderInfo["shp_awb"]
                    data["shp_status"] = orderInfo["shp_status"]
                }
            axios.post(process.env.REACT_APP_KG_API_URL + 'kuroadmin/orders?phone=9492540571', data, config)
        }
    }

    const OrderStatus = (e, key) => {
        let temporderInfo = {...orderInfo}
        temporderInfo[key] = e.target.value
        setorderInfo(temporderInfo)
    }

    return (
        <div className={"order order_" + order.orderid }>
            <table className="border" cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <th>Order Id</th>
                        <th>Order Total</th>
                        <th>Order Status</th>
                        <th>Order Created</th>
                        <th>Order Placed</th>
                        <th>PKG Fees</th>
                        <th>Build Fees</th>
                        <th>Shp Fees</th>
                        <th>Kuro Discount</th>
                        <th>Tax State Code</th>
                        <th>Tax Total</th>
                        <th>Shipping Address</th>
                        <th>Billing Address</th>
                        <th>Temp Reference</th>
                        <th>Pay Reference</th>
                      {access==="edit" && <td rowSpan="3"><button className="sm-btn" onClick={submitHandler}>Submit</button></td>}  
                    </tr>
                    {orderData !== null &&
                    <tr>
                        <td rowSpan="2">{orderData.orderid.toUpperCase()}</td>
                        <td rowSpan="2">{orderData.order_total}</td>
                        <td>{orderData.order_status}</td>
                        <td>{orderData.order_created}</td>
                        <td>{orderData.order_placed}</td>
                        <td>{orderData.pkg_fees}</td>
                        <td>{orderData.build_fees}</td>
                        <td>{orderData.shp_fees}</td>
                        <td>{orderData.kuro_discount}</td>
                        <td>{orderData.tax_state_code}</td>
                        <td>{orderData.tax_total}</td>
                        <td rowSpan="2">{orderData.shp_address.map((item, i) => 
                            item !== "" && 
                            <>
                            {item}<br/>
                            </>
                        )}</td>
                        <td rowSpan="2">{orderData.bill_address.map((item, i) => 
                            item !== "" && 
                            <>
                            {item}<br/>
                            </>
                        )}</td>
                        <td rowSpan="2">{orderData.temp_reference}</td>
                        <td rowSpan="2">{orderData.pay_reference}</td>
                    </tr>
                    }
                    {orderInfo !== null &&
                    <tr>
                      <td>  {access==="edit" && 
                            <select className="order_status" value={orderInfo.order_status} onChange = {(e) => OrderStatus(e, "order_status")}>
                                <option value="Payment Pending">Payment Pending</option>
                                <option value="Order Placed">Order Placed</option>
                                <option value="Order Confirmed">Order Confirmed</option>
                                <option value="Build Started">Build Started</option>
                                <option value="Build Completed">Build Completed</option>
                                <option value="Testing Started">Testing Started</option>
                                <option value="Testing Completed">Testing Completed</option>
                                <option value="Packed">Packed</option>
                                <option value="Shipped">Shipped</option>
                                <option value="Delivered">Delivered</option>
                                <option value="Cancelled">Cancelled</option>
                                <option value="Refunded">Refunded</option>
                            </select>
                       } </td>
                        <td>{orderInfo.order_created}</td>
                        <td>{orderInfo.order_placed}</td>
                        <td>{orderInfo.pkg_fees}</td>
                        <td>{orderInfo.build_fees}</td>
                        <td>{orderInfo.shp_fees}</td>
                        <td>{orderInfo.kuro_discount}</td>
                        <td>{orderInfo.tax_state_code}</td>
                        <td>{orderInfo.tax_total}</td>
                    </tr>
                    }
                </tbody>
            </table>
          {access==="edit" && <table className="border" cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <th>Shp Agency</th>
                        <th>Shp Url</th>
                        <th>Shp AWB</th>
                        <th>Shp Status</th>
                    </tr>
                    <tr>
                        {order.shp_agency === null || order.shp_agency === "" ? (
                        <td>
                            <select className="shp_agency">
                                <option value="">Select One</option>
                                <option value="Delhivery">Delhivery</option>
                                <option value="BlueDart">BlueDart</option>
                                <option value="FedEx">FedEx</option>
                                <option value="DTDC">DTDC</option>
                                <option value="Xpressbees">Xpressbees</option>
                                <option value="Amazon Shipping">Amazon Shipping</option>
                                <option value="DHL">DHL</option>
                                <option value="Ecom Express">Ecom Express</option>
                                <option value="Dunzo">Dunzo</option>
                                <option value="Porter">Porter</option>
                                <option value="Rapido">Rapido</option>
                                <option value="Gati">Gati</option>
                                <option value="Shadowfax">Shadowfax</option>
                                <option value="WeFast">WeFast</option>
                                <option value="DotZot">DotZot</option>
                                <option value="Ekart Logistics">Ekart Logistics</option>
                                <option value="Aramex International">Aramex International</option>
                                <option value="Kerry Indev Express">Kerry Indev Express</option>
                            </select>
                        </td>
                        ) : (
                        <td>{order.shp_agency}</td>
                        )}
                        {order.shp_url === null || order.shp_url === "" ? (<td><input className="shp_url" /></td>) : (<td>{order.shp_url}</td>)}
                        {order.shp_awb === null || order.shp_awb === "" ? (<td><input className="shp_awb" /></td>) : (<td>{order.shp_awb}</td>)}
                        {order.shp_status === null || order.shp_status === "" ? (<td><input className="shp_status" /></td>) : (<td>{order.shp_status}</td>)}
                    </tr>
                </tbody>
            </table>}  
            <table className="border" cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <td>ID</td>
                        <td>Productid</td>
                        <td>Title</td>
                        <td>Category</td>
                        <td>Price</td>
                        <td>Quantity</td>
                    </tr>
                    {order.orderitems.map((item, i) =>
                    <tr key={i}>
                        <td>{item.id}</td>
                        <td>{item.productid}</td>
                        <td><a href={"https://kurogaming.com" + categoryHandler(item.category) + item.prod_url} target="_blank" rel="noreferrer">{item.title}</a></td>
                        <td>{item.category}</td>
                        <td>{item.price}</td>
                        <td>{item.quantity}</td>
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default EditableOrder