import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import '../styles/tabs.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment-timezone'

moment.tz.setDefault('Asia/Kolkata')

const TPOrder = ({ match, user: {token, userDetails}, admin: { provinces }, products: { compData, monitorData, accessData, networkData, extDeviceData }}) => {

    const { params: { orderId } } = match
    const [orderdata, setorderdata] = useState(null)
    const [compMakers, setCompMakers] = useState([])
    const [monitorMakers, setMonitorMakers] = useState([])
    const [accessMakers, setAccessMakers] = useState([])
    const [networkMakers, setNetworkMakers] = useState([])
    const [extMakers, setExtMakers] = useState([])
    const [updatedorder, setupdatedorder] = useState(null)
    const [editflag, seteditflag] = useState(false)
    const [errormsg, seterrormsg] = useState("")
    const [submitted, setsubmitted] = useState(false)
    const [errmsg, seterrmsg] = useState({
        "pan": "",
        "gstin": ""
    })
    const history=useHistory()
    const access=userDetails.accesslevel.orders;
    const selectedProd = useRef()

    useEffect(() => {
        if(access==="NA"){
            history.push("/unauthorized");
            return;
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/tporders?orderid=' + orderId, config).then(res => {
            setorderdata(res.data[0])
        }).catch((err)=>{
            if(err.response.status==401){
                history.push("/unauthorized")
            }
        })
    }, [])

    const handleBack = () => {
        history.goBack();
      };
    useEffect(() => {
        if (compData != null) {
            setCompMakers([...new Set(compData.map(item => item.maker))])
        }
        if (monitorData != null) {
            setMonitorMakers([...new Set(monitorData.map(item => item.maker))])
        }
        if (accessData != null) {
            setAccessMakers([...new Set(accessData.map(item => item.maker))])
        }
        if (networkData != null) {
            setNetworkMakers([...new Set(networkData.map(item => item.maker))])
        }
        if (extDeviceData != null) {
            setExtMakers([...new Set(extDeviceData.map(item => item.maker))])
        }
    }, [compData, monitorData, accessData, networkData, extDeviceData])

    const defbuild = {
        "title": "",
        "components": [
            {
                "collection": "components",
                "type": "cpu",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "mob",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "gpu",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "ram",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "ssd",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "hdd",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "psu",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "tower",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "cooler",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "fan",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "networking",
                "type": "wifi-card",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            }
        ],
        'hsncode': '',
        'tax_rate': 18,
        'buildprice': 0,
        'discount': 0,
        'totaldiscount': 0,
        'pricebgst': 0,
        "price": 0,
        "quantity": 1,
        'totalpricebgst': 0,
        'totalprice': 0
    }

    const defser = {
        "serials": [
            {
                "productid": "",
                "title": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "title": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "title": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "title": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "title": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "title": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "title": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "title": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "title": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "title": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "title": "",
                "sr_no": [""]
            }
        ]
    }

    const round = (type, value, decimals = 0, even=false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updateroundoff = (temporder) => {
        let roundoff = 0
        if (temporder.billadd.state === "Telangana") {
            roundoff = ((temporder.totalprice * 100) - (200 * temporder.cgst) - (100 * temporder.totalpricebgst))
        } else {
            roundoff = ((temporder.totalprice * 100) - (100 * temporder.gst) - (100 * temporder.totalpricebgst))
        }
        temporder.roundoff = round("round", Math.abs(roundoff) >= 1 ? roundoff / 100 : 0, 2)
        return temporder
    }

    const updatetotal = (temporder) => {
        temporder.taxes = {}
        let totalprice = 0
        let totaldiscount = 0
        let totalpricebgst = 0
        let gst = 0
        let cgst = 0
        let subtotals = {
            "5": 0,
            "12": 0,
            "18": 0,
            "28": 0
        }

        for (let i=0; i<temporder.builds.length; i++) {
            totalprice += temporder.builds[i].totalprice
            totaldiscount += temporder.builds[i].totaldiscount
            subtotals[temporder.builds[i].tax_rate] += totalprice
        }
        for (let i=0; i<temporder.products.length; i++) {
            totalprice += temporder.products[i].totalprice
            totaldiscount += temporder.products[i].totaldiscount
            subtotals[temporder.products[i].tax_rate] += totalprice
        }
        for (const [key, value] of Object.entries(subtotals)) {
            let tpbg = 0
            let trgst = 0
            tpbg = round("round", value / (1 + (key / 100)), 2)
            totalpricebgst += tpbg
            trgst = round("round", ((value / (1 + (key / 100))) * (key / 100)), 2)
            temporder.taxes[key] = trgst
            gst += trgst
            cgst += round("round", ((value / (1 + (key / 100))) * (key / 200)), 2)
        }
        temporder.totaldiscount = totaldiscount
        temporder.totalprice = totalprice
        temporder.totalpricebgst = totalpricebgst
        temporder.gst = gst
        temporder.cgst = cgst

        temporder = updateroundoff(temporder)
        return temporder
    }

    const addbuild = () => {
        seterrormsg("")
        let temporder = {...updatedorder}
        if (temporder.builds === undefined) {
            temporder.builds = []
            if (temporder.outward) {
                temporder.outward.builds = []
            }
        }
        temporder.builds.push(defbuild)
        if (temporder.outward) {
            temporder.outward.builds.push(defser)
        }
        setupdatedorder(temporder)
    }

    const removebuild = (index) => {
        seterrormsg("")
        let temporder = {...updatedorder}
        if (temporder.builds.length === 1) {
            delete temporder.builds
            if (temporder.outward) {
                delete temporder.outward.builds
            }
        } else {
            temporder.builds.splice(index, 1)
            if (temporder.outward) {
                temporder.outward.builds.splice(index, 1)
            }
        }
        temporder = updatetotal(temporder)
        setupdatedorder(temporder)
    }

    const updatebuild = (bindex, key, e, num=false) => {
        seterrormsg("")
        let temporder = {...updatedorder}
        temporder.builds[bindex][key] = num ? Number(e.target.value) : e.target.value.trim()
        if (key === "price" || key === "quantity") {
            let totalprice = temporder.builds[bindex].price * temporder.builds[bindex].quantity
            temporder.builds[bindex].pricebgst = round("round", temporder.builds[bindex].price / 1.18, 2)
            temporder.builds[bindex].totalprice = totalprice
            temporder.builds[bindex].totalpricebgst = round("round", totalprice / 1.18, 2)
            temporder = updatetotal(temporder)
        }
        setupdatedorder(temporder)
    }

    const addcomp = (bindex, cindex) => {
        seterrormsg("")
        let temporder = {...updatedorder}
        temporder.builds[bindex].components.splice(cindex + 1, 0, { "collection": "components", "type": "", "maker": "", "productid": "", "quantity": 1 })
        if (temporder.outward) {
            temporder.outward.builds[bindex].serials.splice(cindex + 1, 0, { "productid": "", "title": "", "sr_no": [""] })
        }
        setupdatedorder(temporder)
    }

    const removecomp = (bindex, cindex) => {
        seterrormsg("")
        let temporder = {...updatedorder}
        if (temporder.builds[bindex].components.length === 1) {
            delete temporder.builds[bindex]
            if (temporder.outward) {
                delete temporder.outward.builds[bindex]
            }
        } else {
            temporder.builds[bindex].components.splice(cindex, 1)
            if (temporder.outward) {
                temporder.outward.builds[bindex].serials.splice(cindex, 1)
            }
        }
        setupdatedorder(temporder)
    }

    const updatecomp = (bindex, cindex, key, e, num=false) => {
        seterrormsg("")
        let temporder = {...updatedorder}
        temporder.builds[bindex].components[cindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "productid") {
            if (temporder.outward && temporder.outward.builds) {
                temporder.outward.builds[bindex].serials[cindex].productid = e.target.value
            }
            if (temporder.builds[bindex].components[cindex].collection === "networking") {
                temporder.builds[bindex].components[cindex].title = networkData.filter(item => item.productid === e.target.value)[0].title
                if (temporder.outward && temporder.outward.builds) {
                    temporder.outward.builds[bindex].serials[cindex].title = networkData.filter(item => item.productid === e.target.value)[0].title
                }
            } else {
                temporder.builds[bindex].components[cindex].title = compData.filter(item => item.productid === e.target.value)[0].title
                if (temporder.outward && temporder.outward.builds) {
                    temporder.outward.builds[bindex].serials[cindex].title = compData.filter(item => item.productid === e.target.value)[0].title
                }
            }
        } else if (key === "quantity") {
            if (temporder.outward) {
                let tempsr = temporder.outward.builds[bindex].serials[cindex].sr_no
                if (tempsr.length > e.target.value) {
                    temporder.outward.builds[bindex].serials[cindex].sr_no.splice(e.target.value, tempsr.length - e.target.value)
                } else {
                    for (let q = tempsr.length; q < e.target.value; q++) {
                        tempsr.push('')
                    }
                }
                temporder.outward.builds[bindex].serials[cindex].sr_no = tempsr
            }
        }
        setupdatedorder(temporder)
    }

    const updateBuildComp = (bindex, cindex, key, e) => {
        let temporder = {...orderdata}
        let productid = e.value
        temporder.builds[bindex].components[cindex][key] = productid
        if (key === "productid") {
            if (temporder.builds[bindex].components[cindex].collection === "networking") {
                temporder.builds[bindex].components[cindex].title = (temporder.builds[bindex].components[cindex][key] === "other") ? "" : networkData.filter(item => item.productid === productid)[0].title
            }
            else {
                temporder.builds[bindex].components[cindex].title = (temporder.builds[bindex].components[cindex][key] === "other") ? "" : compData.filter(item => item.productid === productid)[0].title
            }
        }
        setorderdata(temporder)
    }

    const selectBuildComp = (comp) => {
        const tempArray = []
        if (comp.collection === "components") {
            compData !== null && compData.filter(item => item.type === comp.type && item.maker === comp.maker).map(prod => {
                return (
                        tempArray.push({ value: prod.productid, label: prod.title})
                    )
                })
        }
        if (comp.collection === "networking") {
            networkData !== null && networkData.filter(item => item.type === comp.type && item.maker === comp.maker).map(prod => {
                return (
                    tempArray.push({ value: prod.productid, label: prod.title})
                )
            })
        }
        tempArray.push({ value: 'other', label: 'Other'})
        return tempArray
    }

    const addprod = () => {
        seterrormsg("")
        let temporder = {...updatedorder}
        if (temporder.products === undefined) {
            temporder.products = []
            if (temporder.outward) {
                temporder.outward.products = []
            }
        }
        temporder.products.push({ "collection": "", "type": "", "category": "", "maker": "", "productid": "", "quantity": 1, "price": 0 })
        if (temporder.outward && temporder.outward.products) {
            temporder.outward.products.push({ "productid": "", "title": "", "sr_no": [""] })
        }
        setupdatedorder(temporder)
    }

    const removeprod = (index) => {
        seterrormsg("")
        let temporder = {...updatedorder}
        if (temporder.products.length === 1) {
            delete temporder.products
            if (temporder.outward) {
                delete temporder.outward.products
            }
        } else {
            temporder.products.splice(index, 1)
            if (temporder.outward) {
                temporder.outward.products.splice(index, 1)
            }
        }
        setupdatedorder(temporder)
    }

    const updateprod = (index, key, e, num=false) => {
        seterrormsg("")
        let temporder = {...updatedorder}
        temporder.products[index][key] = num ? Number(e.target.value) : e.target.value
        if (key === "maker" || key === "type" || key === "collection") {
            selectedProd.current.setValue('')
        }
        if (key === "productid") {
            let collData = compData
            let collection = temporder.products[index].collection
            if (collection === "monitors") {
                collData = monitorData
            } else if (collection === "accessories") {
                collData = accessData
            } else if (collection === "networking") {
                collData = networkData
            } else if (collection === "external") {
                collData = extDeviceData
            }
            temporder.products[index].title = collData.filter(item => item.productid === e.target.value)[0].title
            if (temporder.outward) {
                temporder.outward.products[index].productid = e.target.value
                temporder.outward.products[index].title = collData.filter(item => item.productid === e.target.value)[0].title
            }
        } else if (key === "quantity") {
            if (temporder.outward) {
                let tempsr = temporder.outward.products[index].sr_no
                if (tempsr.length > e.target.value) {
                    temporder.outward.products[index].sr_no.splice(e.target.value, tempsr.length - e.target.value)
                } else {
                    for (let q = tempsr.length; q < e.target.value; q++) {
                        tempsr.push('')
                    }
                }
                temporder.outward.products[index].sr_no = tempsr
            }
        }

        setupdatedorder(temporder)
    }

    const updateprodmodel = (index, e, collection) => {
        let prodid = e.value
        let temporder = {...updatedorder}
        temporder.products[index].productid = e.value
        let collData = compData
        if (collection === "monitors") {
            collData = monitorData
        } else if (collection === "accessories") {
            collData = accessData
        } else if (collection === "networking") {
            collData = networkData
        } else if (collection === "external") {
            collData = extDeviceData
        }
        if (e)  {
            temporder.products[index].title = (prodid === "other") ? "" : collData.filter(item => item.productid === prodid)[0].title
        } else {
            temporder.products[index].title = ''
        }
        setupdatedorder(temporder)
    }

    const editorder = () => {
        setupdatedorder(orderdata)
        seteditflag(true)
    }

    const updateorder = (category, key, e) => {
        seterrormsg("")
        let temporder = {...updatedorder}
        if (category === "order") {
            temporder[key] = e.target.value.trim()
        } else {
            temporder[category][key] = e.target.value.trim()
        }
        if (key === "totalprice") {
            let reg = /^[0-9.]*$/
            if (!reg.test(temporder[key])) {
                return temporder[key]
            }
        }
        setupdatedorder(temporder)
    }

    const updateorderdate = (key, date) => {
        seterrormsg("")
        let temporder = {...updatedorder}
        temporder[key] = (date === null) ? "" : date
        setupdatedorder(temporder)
    }

    const updatepangstin = (order) => {
        let tempmsg = {...errmsg}
        if (order.billadd.pan.length !== 10 || !(/[A-Z]{5}[0-9]{4}[A-Z]{1}/).test(order.billadd.pan)) {
            tempmsg["pan"] = "*** Please Enter Valid PAN number"
        } else {
            tempmsg["pan"] = ""
        }
        if (order.billadd.gstin !== "" && (order.billadd.gstin.length !== 15 || order.billadd.gstin.slice(2,12) !== order.billadd.pan || !(/^[0-9]*$/).test(order.billadd.gstin.slice(0,2)))) {
            tempmsg["gstin"] = "*** Please Enter Valid GSTIN number"
        } else {
            tempmsg["gstin"] = ""
        }
        seterrmsg(tempmsg)
    }

    const updateaddress = (key, subkey, e) => {
        let value = e.target.value
        let temporder = {...orderdata}
        if (key === "billadd" && subkey === "state") {
            if (value !== "") {
                seterrmsg(msg => msg.state === "")
            }
            temporder[key][subkey] = value
            temporder = updatetotal(temporder)
        } else if (subkey === "pincode") {
            if (value.length <= 6 && (/^[0-9]*$/).test(value)) {
                temporder[key][subkey] = value
            }
        } else if (subkey === "pan" || subkey === "gstin") {
            if ((/^[A-Z0-9]*$/).test(value.toUpperCase())) {
                temporder[key][subkey] = value.toUpperCase()
            }
            updatepangstin(temporder)
        } else {
            temporder[key][subkey] = value.trim()
        }
        setupdatedorder(temporder)
    }

    const updateoutward = (type, e, index, ind, i) => {
        seterrormsg("")
        let temporder = {...updatedorder}
        if (type === "builds") {
            temporder.outward.builds[index].serials[ind].sr_no[i] = e.target.value.trim().toUpperCase()
        } else {
            temporder.outward.products[index].sr_no[ind] = e.target.value.trim().toUpperCase()
        }
        setupdatedorder(temporder)
    }

    const selectionOptions = (prod) => {
        const tempArray = []
        if (prod.collection === 'monitors') {
            monitorData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title})
                )
            })
        }
        if (prod.collection === 'components') {
            compData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title})
                )
            })
        }
        if (prod.collection === 'accessories') {
            accessData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title})
                )
            })
        }
        if (prod.collection === 'networking') {
            networkData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title})
                )
            })
        }
        if (prod.collection === 'external') {
            extDeviceData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title})
                )
            })
        }
        tempArray.push({ value:'other', label: 'Other'})
        return tempArray
    }

    const disableScroll = (e) => {
        e.target.blur()
    }

    const submitOrder = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let submit = true

        const builds = updatedorder.builds
        if (builds && builds.length > 0) {
            for (let i = 0; i < builds.length; i++) {
                if (builds[i].title === "") {
                    submit = false
                    break
                } else {
                    for (let j = 0; j < builds[i].components.length; j++) {
                        if (builds[i].components[j].productid === "" || builds[i].components[j].title === "") {
                            submit = false
                            break
                        }
                    }
                }
                if (submit === false) {
                    break
                }
            }
        }

        const products = updatedorder.products
        if (submit && products && products.length > 0) {
            for (let i = 0; i < products.length; i++) {
                if (products[i].productid === "" || products[i].title === "") {
                    submit = false
                    break
                }
            }
        }

        if (submit) {
            let finalorder = updatedorder
            finalorder.totalprice = parseFloat(finalorder.totalprice)
            if (finalorder.totalprice > 200000 && finalorder.billadd.pan === "") {
                seterrormsg("Invoice value is more than 2 lakhs, So PAN is mandatory")
            } else {
                axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/tporders?orderid=' + orderId, finalorder, config)
                setsubmitted(true)
            }
        } else {
            seterrormsg("*Please Enter all the fields")
        }
    }

    const cancelupdates = () => {
        setupdatedorder(orderdata)
        seteditflag(false)
    }

    const backhandler = () => {
        seteditflag(false)
        setsubmitted(false)
    }

    return (
        <div className="tp-orders txt-light-grey">
            <h2>Third Party Order</h2>
            {editflag ? (
                submitted ? (
                    <>
                    <p>The Order {orderdata.orderid} updates have been successfully submitted.</p>
                    <ul className='btns'>
                        <li>
                            <button onClick={backhandler}>Order Details</button>
                        </li>
                    </ul>
                    </>
                ) : (
            <>
            <div className="order-details">
                {updatedorder !== null && 
                <table className="border" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Channel</td>
                            <td>
                                <select className="large" value={updatedorder.channel} onChange={(e) => updateorder("order", "channel", e)}>
                                    <option value="">Select Channel</option>
                                    <option value="Amazon">Amazon</option>
                                    <option value="Flipkart">Flipkart</option>
                                    <option value="Kurogaming">Kuro Gaming</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Third Party Order ID</td>
                            <td><input className="large" value={updatedorder.tporderid} onChange={(e) => updateorder("order", "tporderid", e)} /></td>
                        </tr>
                        <tr>
                            <td>PO/Ref No</td>
                            <td><input className="large" value={updatedorder.po_ref} onChange={(e) => updateorder("order", "po_ref", e)} /></td>
                        </tr>
                        <tr>
                            <td>Order Date</td>
                            <td>
                                {updatedorder.order_date !== "" && <DatePicker selected={new Date(updatedorder.order_date)} className="large" dateFormat='dd-MMM-yyyy' onChange={date => updateorderdate("order_date", date)} />}
                            </td>
                        </tr>
                        <tr>
                            <td>Order Total</td>
                            <td><input className="large" value={updatedorder.totalprice} onChange={(e) => updateorder("order", "totalprice", e)} /></td>
                        </tr>
                        <tr>
                            <td>Dispatch Due Date</td>
                            <td>
                                <DatePicker selected={updatedorder.dispatchby_date === "" ? new Date() : new Date(updatedorder.dispatchby_date)} className="large" dateFormat='dd-MMM-yyyy' onChange={date => updateorderdate("dispatchby_date", date)} />
                            </td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="large" value={updatedorder.user.name} onChange={(e) => updateorder("user", "name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="noarrows large" value={updatedorder.user.phone} onChange={(e) => updateorder("user", "phone", e)} /></td>
                        </tr>
                        {/* <tr>
                            <td>Email</td>
                            <td><input className="large" value={updatedorder.email} onChange={(e) => updateorder("user", "email", e)} /></td>
                        </tr> */}
                        <tr>
                            <td colSpan="2">Billing Address</td>
                        </tr>
                        <tr>
                            <td>Company Name</td>
                            <td><input className="large" value={updatedorder.billadd.company} onChange={(e) => updateaddress("billadd", "company", e)} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="large" value={updatedorder.billadd.name} onChange={(e) => updateaddress("billadd", "name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="noarrows large" value={updatedorder.billadd.phone} onChange={(e) => updateaddress("billadd", "phone", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line1</td>
                            <td><textarea className="large" value={updatedorder.billadd.addressline1} onChange={(e) => updateaddress("billadd", "addressline1", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line2</td>
                            <td><textarea className="large" value={updatedorder.billadd.addressline2} onChange={(e) => updateaddress("billadd", "addressline2", e)} /></td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td><input className="large" value={updatedorder.billadd.city} onChange={(e) => updateaddress("billadd", "city", e)} /></td>
                        </tr>
                        <tr>
                            <td>Pincode</td>
                            <td><input className="noarrows large" value={updatedorder.billadd.pincode} onChange={(e) => updateaddress("billadd", "pincode", e)} /></td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                <select className="large" value={updatedorder.billadd.state} onChange={(e) => updateaddress("billadd", "state", e)}>
                                    <option value="">Select State</option>
                                    {provinces.map((province, i) =>
                                    <option key={i} value={province["state"]}>{province["state"]}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>PAN</td>
                            <td><input className="large" value={updatedorder.billadd.pan} onChange={(e) => updateaddress("billadd", "pan", e)} /></td>
                        </tr>
                        <tr>
                            <td>GSTIN</td>
                            <td><input className="large" value={updatedorder.billadd.gstin} onChange={(e) => updateaddress("billadd", "gstin", e)} /></td>
                        </tr>
                        <tr>
                            <td colSpan="2">Shipping Address</td>
                        </tr>
                        <tr>
                            <td>Company Name</td>
                            <td><input className="large" value={updatedorder.shpadd.company} onChange={(e) => updateaddress("shpadd", "company", e)} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="large" value={updatedorder.shpadd.name} onChange={(e) => updateaddress("shpadd", "name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="noarrows large" value={updatedorder.shpadd.phone} onChange={(e) => updateaddress("shpadd", "phone", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line1</td>
                            <td><textarea className="large" value={updatedorder.shpadd.addressline1} onChange={(e) => updateaddress("shpadd", "addressline1", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line2</td>
                            <td><textarea className="large" value={updatedorder.shpadd.addressline2} onChange={(e) => updateaddress("shpadd", "addressline2", e)} /></td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td><input className="large" value={updatedorder.shpadd.city} onChange={(e) => updateaddress("shpadd", "city", e)} /></td>
                        </tr>
                        <tr>
                            <td>Pincode</td>
                            <td><input className="noarrows large" value={updatedorder.shpadd.pincode} onChange={(e) => updateaddress("shpadd", "pincode", e)} /></td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                <select className="large" value={updatedorder.shpadd.state} onChange={(e) => updateaddress("shpadd", "state", e)}>
                                    <option value="">Select State</option>
                                    {provinces.map((province, i) =>
                                    <option key={i} value={province["state"]}>{province["state"]}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>PAN</td>
                            <td><input className="large" value={updatedorder.shpadd.pan} onChange={(e) => updateaddress("shpadd", "pan", e)} /></td>
                        </tr>
                        <tr>
                            <td>GSTIN</td>
                            <td><input className="large" value={updatedorder.shpadd.gstin} onChange={(e) => updateaddress("shpadd", "gstin", e)} /></td>
                        </tr>
                    </tbody>
                </table>
                }
            </div>
            {Object.keys(errmsg).map((err) =>
                <>{errmsg[err] !== "" && <p>{errmsg[err]}</p>}</>
            )}
            {orderdata.order_status !== "Created" && 
            <div className="products">
                <div className='title_wrap col_mob'>
                    <p>Builds</p>
                    <span className="prod-span" onClick={() => addbuild(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                </div>
                {updatedorder.builds && updatedorder.builds.map((build, j) =>
                <table key={j} className="build border" cellPadding="0" cellSpacing="0">
                   <tbody>
                        <tr>
                            <td>Title</td>
                            <td colSpan="3"><textarea className="x-large" value={build.title} onChange={(e) => updatebuild(j, "title", e)} /></td>
                            <td><span className="prod-span" onClick={() => removebuild(j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span></td>
                        </tr>
                        <tr>
                            <td>Price</td>
                            <td><input type="number" onWheel={disableScroll} className="large" value={build.price} onChange={(e) => updatebuild(j, "price", e, true)} /></td>
                            <td>Quantity</td>
                            <td><input className="small" type="number" onWheel={disableScroll} value={build.quantity} onChange={(e) => updatebuild(j, "quantity", e, true)} /></td>
                            <td></td>
                        </tr>
                        {build.components.map((comp, k) =>
                        <tr key={k}>
                            <td>
                                {comp.collection === "components" &&
                                <select className="large" value={comp.type} onChange={(e) => updatecomp(j, k, "type", e)}>
                                    <option value="">Select Type</option>
                                    <option value="cpu">Processor</option>
                                    <option value="mob">Motherboard</option>
                                    <option value="ram">RAM</option>
                                    <option value="gpu">Graphics Card</option>
                                    <option value="psu">Power Supply</option>
                                    <option value="ssd">SSD</option>
                                    <option value="hdd">HDD</option>
                                    <option value="cooler">CPU Cooler</option>
                                    <option value="tower">Case</option>
                                    <option value="fan">Fan</option>
                                </select>
                                }
                                {comp.collection === "networking" && "WiFi Card"}
                            </td>
                            <td>
                                <select className="large" value={comp.maker} onChange={(e) => updatecomp(j, k, "maker", e)}>
                                    <option value="">Select Brand</option>
                                {comp.collection === "components" && compMakers !== null && compMakers.map((maker, l) =>
                                    compData.filter(item => item.type === comp.type && item.maker === maker).length > 0 && <option key={l} value={maker}>{maker}</option>
                                )}
                                {comp.collection === "networking" && networkMakers !== null && networkMakers.map((maker, l) =>
                                    <option key={l} value={maker}>{maker}</option>
                                )}
                                </select>
                            </td>
                            <td>
                                <Select classNamePrefix="kuro-search-select" options={selectBuildComp(comp)} value={{ value: comp.productid, label: comp.title }} onChange={(e) => updateBuildComp(j, k, "productid", e)} placeholder="Select Component" className="react-select-container" />
                            </td>
                            <td>
                                <input value={comp.quantity} type="number" onWheel={disableScroll} className="small" min="1" max="100" onChange={(e) => updatecomp(j, k, "quantity", e, true)} />
                            </td>
                            <td>
                                <span className="prod-span" onClick={() => removecomp(j, k)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span>
                                <span className="prod-span" onClick={() => addcomp(j, k)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                            </td>
                        </tr>
                        )}
                    </tbody>
                </table>
                )}
                <div className='title_wrap col_mob'>
                    <p>Peripherals</p>
                    <span className="prod-span" onClick={() => addprod(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                </div>
                {updatedorder.products &&
                <table className="border" cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <td>Collection</td>
                            <td>Type</td>
                            <td>Category</td>
                            <td>Brand</td>
                            <td>Model</td>
                            <td>Quantity</td>
                            <td>Price</td>
                            <td>
                                <span className="prod-span" onClick={() => addprod()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                            </td>
                        </tr>
                        {updatedorder.products.map((prod, i) =>
                        <tr key={i}>
                            <td>
                                <select value={prod.collection} onChange={(e) => updateprod(i, "collection", e)}>
                                    <option value="">Select Collection</option>
                                    <option value="components">Components</option>
                                    <option value="monitors">Monitors</option>
                                    <option value="accessories">Accessories</option>
                                    <option value="networking">Networking</option>
                                    <option value="external">External Devices</option>
                                </select>
                            </td>
                            <td>
                                <select value={prod.type} onChange={(e) => updateprod(i, "type", e)}>
                                    <option value="">Select Type</option>
                                {prod.collection === "components" &&
                                <>
                                    <option value="cpu">Processor</option>
                                    <option value="mob">Motherboard</option>
                                    <option value="ram">RAM</option>
                                    <option value="gpu">Graphics Card</option>
                                    <option value="psu">Power Supply</option>
                                    <option value="ssd">SSD</option>
                                    <option value="hdd">HDD</option>
                                    <option value="cooler">CPU Cooler</option>
                                    <option value="tower">Case</option>
                                    <option value="fan">Fan</option>
                                </>
                                }
                                {prod.collection === "monitors" && <option value="monitor">Monitor</option>}
                                {prod.collection === "accessories" &&
                                <>
                                    <option value="keyboard">Keyboard</option>
                                    <option value="keyboard-accessories">Keyboard Accessories</option>
                                    <option value="mouse">Mouse</option>
                                    <option value="mouse-accessories">Mouse Accessories</option>
                                    <option value="kbdmouse">Keyboard Mouse Combo</option>
                                    <option value="streaming-gear">Streaming Gear</option>
                                </>
                                }
                                {prod.collection === "networking" && <option value="wifi-card">Wifi Card</option>}
                                {prod.collection === "external" && 
                                <>
                                    <option value="hdd">HDD</option>
                                    <option value="expansion-card">Expansion Card</option>
                                </>
                                }
                                </select>
                            </td>
                            <td>
                                <select value={prod.category} onChange={(e) => updateprod(i, "category", e)}>
                                    <option value="">Select Category</option>
                                {prod.type === "streaming-gear" &&
                                <>
                                    <option value="capture-card">Capture Card</option>
                                    <option value="gaming-chair">Gaming Chair</option>
                                    <option value="streaming-mic">Streaming Mic</option>
                                    <option value="webcam">Webcam</option>
                                    <option value="gaming-headset">Gaming Headset</option>
                                </>
                                }
                                </select>
                            </td>
                            <td>
                                <select value={prod.maker} onChange={(e) => updateprod(i, "maker", e, false)}>
                                    <option value="">Select Brand</option>
                                {prod.collection === "components" && compMakers !== null && compMakers.map((maker, j) =>
                                    compData.filter(item => item.type === prod.type && item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                )}
                                {prod.collection === "monitors" && monitorMakers !== null && monitorMakers.map((maker, j) =>
                                    monitorData.filter(item => item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                )}
                                {prod.collection === "accessories" && accessMakers !== null && accessMakers.map((maker, j) =>
                                    accessData.filter(item => item.type === prod.type && item.category === prod.category && item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                )}
                                {prod.collection === "networking" && networkMakers !== null && networkMakers.map((maker, j) =>
                                    networkData.filter(item => item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                )}
                                {prod.collection === "external" && extMakers !== null && extMakers.map((maker, j) =>
                                    extDeviceData.filter(item => item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                )}
                                </select>
                            </td>
                            <td>
                                <Select classNamePrefix="kuro-search-select" options={selectionOptions(prod)} defaultValue={{ value: prod.presetid, label: prod.title}} onChange={(e) => updateprodmodel(i, e, prod.collection)}  placeholder="Select Model" className="react-select-container" ref={selectedProd}/>
                            </td>
                            <td>
                                <input value={prod.quantity} type="number" onWheel={disableScroll} className="small" min="1" max="100" onChange={(e) => updateprod(i, "quantity", e, true)} />
                            </td>
                            <td>
                                <input value={prod.price} type="number" onWheel={disableScroll} className="small" onChange={(e) => updateprod(i, "price", e, true)} />
                            </td>
                            <td>
                                <span className="prod-span" onClick={() => removeprod(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span>
                                <span className="prod-span" onClick={() => addprod()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                            </td>
                        </tr>
                        )}
                    </tbody>
                </table>
                }
                <p>&nbsp;</p>
                {updatedorder.outward &&
                <table className="build border" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Product Title</td>
                            <td>Serial Number</td>
                        </tr>
                    {updatedorder.outward.builds && updatedorder.outward.builds.map((build, i) =>
                        build.serials.map((comp, j) =>
                        <tr key={j}>
                            <td>
                                {comp.title}
                            </td>
                            <td>
                                {comp.sr_no.map((e, k) => {
                                    return <><input value={e} className="large" onChange={(e) => updateoutward("builds", e, i, j, k)} /><br/></>
                                })}
                            </td>
                        </tr>
                        )
                    )}
                    {updatedorder.outward !== null && updatedorder.outward.products && updatedorder.outward.products.map((prod, i) =>
                        <tr>
                            <td>
                                {prod.title}
                            </td>
                            <td>
                                {prod.sr_no.map((e, j) => {
                                    return <><input value={e} className="large" onChange={(e) => updateoutward("products", e, i, j)} /><br/></>
                                })}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
                }
            </div>
            }
            <ul className='btns'>
                <li>
                    <button onClick={cancelupdates}>Cancel</button>
                </li>
                <li>
                    <button onClick={submitOrder}>Submit Order</button>
                </li>
            </ul>
            {errormsg !== "" && <p className='util txt-light'>{errormsg}</p>}
            </>
                )
            ) : (
            <div className="order-details">
                {access==="edit" &&   <ul className="btns">
                    <li><button onClick={editorder}>Edit the Order</button></li>
                </ul> }
              
            {orderdata !== null &&
                <div>
                    <table className="border" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td>OrderId</td>
                                <td colSpan="2">Order Details</td>
                                <td>Customer Details</td>
                                <td colSpan="2">Billing Address</td>
                                <td colSpan="2">Shipping Address</td>
                            </tr>
                            <tr>
                                <td>{orderdata.orderid}</td>
                                <td colSpan="2">
                                    <p>Channel: {orderdata.channel}</p>
                                    <p>TP OrderId: {orderdata.tporderid}</p>
                                    {orderdata.po_ref !== "" && <p>PO/Ref: {orderdata.po_ref}</p>}
                                    <p>Order Date: {moment(orderdata.order_date).format('DD-MM-YYYY')}</p>
                                    <p className='txt-light'>Order Total: <b>{orderdata.totalprice}</b></p>
                                </td>
                                <td>
                                    <p>Name: {orderdata.user.name}</p>
                                    {orderdata.user.phone !== "" && <p>Mobile: {orderdata.user.phone}</p>}
                                    {/* {orderdata.user.email !== "" && <p>Email: {orderdata.user.email}</p>} */}
                                </td>
                                <td colSpan="2">
                                    {orderdata.billadd.company &&
                                    <>
                                    {orderdata.billadd.company}<br/>
                                    </>
                                    }
                                    {orderdata.billadd.name &&
                                    <>
                                    {orderdata.billadd.name}
                                    </>
                                    }
                                    {orderdata.billadd.phone &&
                                    <>
                                    <br/>{orderdata.billadd.phone}
                                    </>
                                    }
                                    {orderdata.billadd.addressline1 &&
                                    <>
                                    <br/>{orderdata.billadd.addressline1}
                                    </>
                                    }
                                    {orderdata.billadd.addressline2 &&
                                    <>
                                    ,&nbsp;{orderdata.billadd.addressline2}
                                    </>
                                    }
                                    {orderdata.billadd.city &&
                                    <>
                                    <br/>{orderdata.billadd.city}
                                    </>
                                    }
                                    {orderdata.billadd.state &&
                                    <>
                                    <br/>{orderdata.billadd.state}
                                    </>
                                    }
                                    {orderdata.billadd.pincode &&
                                    <>
                                    &nbsp;- {orderdata.billadd.pincode}
                                    </>
                                    }
                                    {orderdata.billadd.pan &&
                                    <>
                                    <br/>PAN: {orderdata.billadd.pan}
                                    </>
                                    }
                                    {orderdata.billadd.gstin &&
                                    <>
                                    <br/>GSTIN: {orderdata.billadd.gstin}
                                    </>
                                    }
                                </td>
                                {orderdata.addressflag ? (
                                <td colSpan={2}>
                                    {orderdata.billadd.company &&
                                    <>
                                    {orderdata.billadd.company}<br/>
                                    </>
                                    }
                                    {orderdata.billadd.name &&
                                    <>
                                    {orderdata.billadd.name}
                                    </>
                                    }
                                    {orderdata.billadd.phone &&
                                    <>
                                    <br/>{orderdata.billadd.phone}
                                    </>}
                                    {orderdata.billadd.addressline1 &&
                                    <>
                                    <br/>{orderdata.billadd.addressline1}
                                    </>
                                    }
                                    {orderdata.billadd.addressline2 &&
                                    <>
                                    ,&nbsp;{orderdata.billadd.addressline2}
                                    </>
                                    }
                                    {orderdata.billadd.city &&
                                    <>
                                    <br/>{orderdata.billadd.city}
                                    </>
                                    }
                                    {orderdata.billadd.state &&
                                    <>
                                    <br/>{orderdata.billadd.state}
                                    </>
                                    }
                                    {orderdata.billadd.pincode &&
                                    <>
                                    &nbsp;- {orderdata.billadd.pincode}
                                    </>
                                    }
                                    {orderdata.billadd.pan &&
                                    <>
                                    <br/>PAN: {orderdata.billadd.pan}
                                    </>
                                    }
                                    {orderdata.billadd.gstin &&
                                    <>
                                    <br/>GSTIN: {orderdata.billadd.gstin}
                                    </>
                                    }
                                </td>
                                ) : (
                                <td colSpan={2}>
                                    {orderdata.shpadd.company &&
                                    <>
                                    {orderdata.shpadd.company}<br/>
                                    </>
                                    }
                                    {orderdata.shpadd.name &&
                                    <>
                                    {orderdata.shpadd.name}
                                    </>
                                    }
                                    {orderdata.shpadd.phone &&
                                    <>
                                    <br/>{orderdata.shpadd.phone}
                                    </>
                                    }
                                    {orderdata.shpadd.addressline1 &&
                                    <>
                                    <br/>{orderdata.shpadd.addressline1}
                                    </>
                                    }
                                    {orderdata.shpadd.addressline2 &&
                                    <>
                                    ,&nbsp;{orderdata.shpadd.addressline2}
                                    </>
                                    }
                                    {orderdata.shpadd.city &&
                                    <>
                                    <br/>{orderdata.shpadd.city}
                                    </>
                                    }
                                    {orderdata.shpadd.state &&
                                    <>
                                    <br/>{orderdata.shpadd.state}
                                    </>
                                    }
                                    {orderdata.shpadd.pincode &&
                                    <>
                                    &nbsp;- {orderdata.shpadd.pincode}
                                    </>
                                    }
                                    {orderdata.shpadd.pan &&
                                    <>
                                    <br/>PAN: {orderdata.shpadd.pan}
                                    </>
                                    }
                                    {orderdata.shpadd.gstin &&
                                    <>
                                    <br/>GSTIN: {orderdata.shpadd.gstin}
                                    </>
                                    }
                                    </td>
                                )}
                            </tr>
                            {orderdata.builds &&
                            <>
                            <tr>
                                <td colSpan="3">Build Title</td>
                                <td colSpan="3">Components</td>
                                <td>Quantity</td>
                                <td>Build Price</td>
                            </tr>
                            {orderdata.builds.map((build, i) =>
                            <tr key={i}>
                                <td colSpan="3">{build.title}</td>
                                <td colSpan="3">
                                {build.components.map((item, j) =>
                                    <p key={j}>
                                        {item.type}: {item.quantity > 1 && <span>{item.quantity} x </span>}{item.title}
                                        {(orderdata.order_status === "Inventory Added" || orderdata.order_status === "Shipped") &&
                                        <>
                                        {orderdata.outward && orderdata.outward.builds && " [S/N: " + ( orderdata.outward.builds[i].serials[j].sr_no.join(', ')) + "]"}
                                        </>
                                        }
                                    </p>
                                )}
                                </td>
                                <td>{build.quantity}</td>
                                <td>{build.price}</td>
                            </tr>
                            )}
                            </>
                            }
                            {orderdata.products &&
                            <>
                            <tr>
                                <td colSpan="6">Product</td>
                                <td>Quantity</td>
                                <td>Price</td>
                            </tr>
                            {orderdata.products.map((item, i) =>
                            <tr key={i}>
                                <td colSpan="6">
                                    {item.collection === "components" && compData !== null && 
                                    <>
                                    {compData.filter(prod => prod.productid === item.productid)[0].title}
                                    {(orderdata.order_status === "Inventory Added" || orderdata.order_status === "Shipped") &&
                                    <>
                                    {orderdata.outward && orderdata.outward.products && " [S/N: " + <b>(orderdata.outward.products[i].sr_no.join(','))</b> + "]"}
                                    </>
                                    }
                                    </>
                                    }
                                    {item.collection === "monitors" && monitorData !== null && 
                                    <>
                                    {monitorData.filter(prod => prod.productid === item.productid)[0].title}
                                    {(orderdata.order_status === "Inventory Added" || orderdata.order_status === "Shipped") &&
                                    <>
                                    {orderdata.outward && orderdata.outward.products && " [S/N: " + <b>(orderdata.outward.products[i].sr_no.join(','))</b> + "]"}
                                    </>
                                    }
                                    </>
                                    }
                                    {item.collection === "accessories" && accessData !== null && 
                                    <>
                                    {accessData.filter(prod => prod.productid === item.productid)[0].title}
                                    {(orderdata.order_status === "Inventory Added" || orderdata.order_status === "Shipped") &&
                                    <>
                                    {orderdata.outward && orderdata.outward.products && " [S/N: " + <b>(orderdata.outward.products[i].sr_no.join(','))</b> + "]"}
                                    </>
                                    }
                                    </>
                                    }
                                    {item.collection === "networking" && networkData !== null && 
                                    <>
                                    {networkData.filter(prod => prod.productid === item.productid)[0].title}
                                    {(orderdata.order_status === "Inventory Added" || orderdata.order_status === "Shipped") &&
                                    <>
                                    {orderdata.outward && orderdata.outward.products && " [S/N: " + <b>(orderdata.outward.products[i].sr_no.join(','))</b> + "]"}
                                    </>
                                    }
                                    </>
                                    }
                                    {item.collection === "external" && extDeviceData !== null && 
                                    <>
                                    {extDeviceData.filter(prod => prod.productid === item.productid)[0].title}
                                    {(orderdata.order_status === "Inventory Added" || orderdata.order_status === "Shipped") &&
                                    <>
                                    {orderdata.outward && orderdata.outward.products && " [S/N: " + <b>(orderdata.outward.products[i].sr_no.join(','))</b> + "]"}
                                    </>
                                    }
                                    </>
                                    }
                                </td>
                                <td>{item.quantity}</td>
                                <td>{item.price}</td>
                            </tr>
                            )}
                            </>
                            }
                        </tbody>
                    </table>
                    <ul className="btns">
                    <li><button onClick={handleBack}>Back</button></li>
                </ul> 
                </div>
            }
            </div>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    products: state.products,
    user: state.user
})

export default connect(mapStateToProps)(TPOrder)