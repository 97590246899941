import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import KuroLink from '../components/common/KuroLink'
import '../styles/table.css'
import '../styles/profile.css'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import DatePicker from 'react-datepicker'

const Profile = ({ user: { token, userDetails }, admin: { provinces } }) => {

    const [empdata, setempdata] = useState(null)
    const [editFlag, setEditFlag] = useState(false)
    const [OTPsent, setOTPsent] = useState(false)
    const [inputValid, setInputValid] = useState(false)
    const [otp, setOtp] = useState("")
    const { userid } = useParams()

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        };

        let tempUserId = userid ? userid : userDetails.userid

        axios(process.env.REACT_APP_KC_API_URL + 'empprofile?userid=' + tempUserId, config)
            .then((res) => {
                let data = res.data
                Object.entries(data[0]).forEach(([key, value]) => {
                    if (value === null) data[0][key] = ""
                })
                Object.entries(data[0].user_details).forEach(([key, value]) => {
                    if (value === null) data[0].user_details[key] = ""
                })
                setempdata(data)
            });
    }, [])

    useEffect(() => {
        checkInputValidation()
    }, [empdata])

    const updateHandler = (e, key, subkey) => {
        const newEmpData = [...empdata];
        const { value } = e.target;
        const numberRegex = /^-?\d*(\.\d+)?$/; // Regex to validate number


        if (key === "user_details") {
            if (subkey === "presadd_pincode" || subkey === "permadd_pincode") {
                if (numberRegex.test(value)) {
                    newEmpData[0][key][subkey] = value
                } else return;
            }
            newEmpData[0][key][subkey] = value
        } else {
            newEmpData[0][key] = value
        }

        setempdata(newEmpData);
    };

    const handleDateChange = (date, key, subkey) => {
        const newEmpData = [...empdata];
        newEmpData[0][key][subkey] = new Date(date);
        setempdata(newEmpData);
    };

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        };
        let data = empdata[0];

        data.user_details.dob = moment(data.user_details.dob).format("DD-MM-YYYY");
        Object.entries(data).forEach(([key, value]) => data[key] = value === '' ? null : value);
        Object.entries(data.user_details).forEach(([key, value]) => data.user_details[key] = value === '' ? null : value);
        data.user_details.presadd_pincode = parseInt(data.user_details.presadd_pincode);
        data.user_details.permadd_pincode = parseInt(data.user_details.permadd_pincode);

        axios.post(process.env.REACT_APP_KC_API_URL + 'empprofile', data, config)
            .then((res) => {
                alert("Profile updated successfully");
                setEditFlag(false)
            })
            .catch((error) => {
                console.error("Error updating profile:", error);
            });
    };

    const checkEditAccess = () => {
        if (userDetails.accesslevel.profile === "edit") {
            return true
        } else {
            return empdata !== null ? empdata[0].user_details.edit : false
        }
    }

    const getOTP = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        const newuser = "false"
        const body = JSON.stringify({ newuser, "userid": empdata[0].phone })

        axios.post(process.env.REACT_APP_KC_API_URL + 'verify', body, config)
            .then((res) => {
                setOTPsent(true)
            })
    }

    const verifyOTP = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        const body = { "phone": empdata[0].phone, "otp": otp, "userid": userDetails.userid }
        axios.post(process.env.REACT_APP_KC_API_URL + 'empprofile?otp=true', body, config)
            .then((res) => {
                setempdata(res.data);
            })
            .catch((error) => {
                alert("Invalid OTP")
            });
    }

    const editUserOnly = () => {
        if (userDetails.accesslevel.profile === "edit") {
            return editFlag
        } else {
            return false
        }
    }


    const checkInputValidation = () => {
        if (empdata !== null) {
            const emp = empdata[0];

            const userDetails = emp.user_details;

            const requiredFields = [
                userDetails.dob,
                userDetails.gender,
                userDetails.permadd_city,
                userDetails.permadd_state,
                userDetails.permadd_pincode,
                userDetails.bank_accountno,
                userDetails.bank_branch,
                userDetails.bank_ifsc,
                userDetails.bank_name,
                userDetails.pan,
                userDetails.idproof_type,
                userDetails.idproof_number
            ];

            requiredFields.every(field => field !== null && field !== "") ? setInputValid(true) : setInputValid(false);
        }
    };


    return (
        <div className='profile mx-width txt-light'>
            <h2>Profile</h2>
            <table className='border' cellPadding="0" cellSpacing="0">
                {empdata !== null && empdata.map((emp, i) =>
                    <tbody key={i}>
                        <tr>
                            <td>Emp ID</td>
                            <td>{emp.userid}</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.name} onChange={(e) => updateHandler(e, 'name', '')} />
                                ) : (
                                    <p>{emp.name}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Mobile No.</td>
                            <td>
                                {editFlag ? (
                                    <input type="phone" value={emp.phone} onChange={(e) => updateHandler(e, 'phone', '')} />
                                ) : (
                                    <p>{emp.phone}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Email Address</td>
                            <td>
                                {editFlag ? (
                                    <input type="email" value={emp.email} onChange={(e) => updateHandler(e, 'email', '')} />
                                ) : (
                                    <p>{emp.email}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Username</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.username} onChange={(e) => updateHandler(e, 'username', '')} />
                                ) : (
                                    <p>{emp.username}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Phone Verified</td>
                            <td>
                                {emp.user_details.phone_verified ? (
                                    <p style={{ color: 'green', fontWeight: 'bold' }}>Verified</p>
                                ) : OTPsent ? (
                                    <div className='otp'>
                                        <input type="text" placeholder='Enter OTP' value={otp} onChange={(e) => setOtp(e.target.value)} />
                                        <button onClick={() => verifyOTP()}>Verify</button>
                                    </div>
                                ) : (
                                    <div className='otp'>
                                        <input type="phone" value={emp.phone ? emp.phone : ''} onChange={(e) => updateHandler(e, 'phone', '')} />
                                        <button onClick={() => getOTP()}>Get OTP</button>
                                    </div>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Gender</td>
                            <td>
                                {editFlag ? (
                                    <select value={emp.user_details.gender} style={emp.user_details.gender ? null : { border: '1px solid red' }} onChange={(e) => updateHandler(e, 'user_details', 'gender')}>
                                        <option>Please Select Gender</option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </select>
                                ) : (
                                    <p>{emp.user_details.gender === 'M' ? 'Male' : 'Female'}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Date of Birth</td>
                            <td>
                                {editFlag ? (
                                    // <input type="date" value={emp.user_details.dob ? moment(emp.user_details.dob).format('YYYY-MM-DD') : ''} onChange={(e) => updateHandler(e, 'user_details', 'dob')} style={emp.user_details.dob ? null : { border: '1px solid red' }} />
                                    <DatePicker showIcon selected={emp.user_details.dob ? new Date(emp.user_details.dob) : null} dateFormat="dd/MM/yyyy" onChange={(date) => handleDateChange(date, 'user_details', 'dob')} />
                                ) : (
                                    <p>{emp.user_details.dob ? new Date(emp.user_details.dob).toLocaleDateString() : ''}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Permanent Address</th>
                        </tr>
                        <tr>
                            <td>Line 1</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.permadd_line1} onChange={(e) => updateHandler(e, 'user_details', 'permadd_line1')} />
                                ) : (
                                    <p>{emp.user_details.permadd_line1}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Line 2</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.permadd_line2} onChange={(e) => updateHandler(e, 'user_details', 'permadd_line2')} />
                                ) : (
                                    <p>{emp.user_details.permadd_line2}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.permadd_city} onChange={(e) => updateHandler(e, 'user_details', 'permadd_city')} style={emp.user_details.permadd_city ? null : { border: '1px solid red' }} />
                                ) : (
                                    <p>{emp.user_details.permadd_city}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                {editFlag ? (
                                    <select className="state" value={emp.user_details.permadd_state} onChange={(e) => updateHandler(e, 'user_details', 'permadd_state')} style={emp.user_details.permadd_state ? null : { border: '1px solid red' }} >
                                        <option value="">Select State</option>
                                        {provinces.map((province, i) =>
                                            <option key={i} value={province["state"]}>{province["state"]}</option>
                                        )}
                                    </select>
                                ) : (
                                    <p>{emp.user_details.permadd_state}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Pin code</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.permadd_pincode} onChange={(e) => updateHandler(e, 'user_details', 'permadd_pincode')} style={emp.user_details.permadd_pincode ? null : { border: '1px solid red' }} />
                                ) : (
                                    <p>{emp.user_details.permadd_pincode}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Present Address</th>
                        </tr>
                        <tr>
                            <td>Line 1</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.presadd_line1} onChange={(e) => updateHandler(e, 'user_details', 'presadd_line1')} />
                                ) : (
                                    <p>{emp.user_details.presadd_line1}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Line 2</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.presadd_line2} onChange={(e) => updateHandler(e, 'user_details', 'presadd_line2')} />
                                ) : (
                                    <p>{emp.user_details.presadd_line2}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.presadd_city} onChange={(e) => updateHandler(e, 'user_details', 'presadd_city')} />
                                ) : (
                                    <p>{emp.user_details.presadd_city}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                {editFlag ? (
                                    <select className="state" value={emp.user_details.presadd_state} onChange={(e) => updateHandler(e, 'user_details', 'presadd_state')} style={emp.user_details.permadd_state ? null : { border: '1px solid red' }} >
                                        <option value="">Select State</option>
                                        {provinces.map((province, i) =>
                                            <option key={i} value={province["state"]}>{province["state"]}</option>
                                        )}
                                    </select>
                                ) : (
                                    <p>{emp.user_details.presadd_state}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Pin code</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.presadd_pincode} onChange={(e) => updateHandler(e, 'user_details', 'presadd_pincode')} />
                                ) : (
                                    <p>{emp.user_details.presadd_pincode}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Bank Account Details</th>
                        </tr>
                        <tr>
                            <td>Bank Account No</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.bank_accountno} onChange={(e) => updateHandler(e, 'user_details', 'bank_accountno')} style={emp.user_details.bank_accountno ? null : { border: '1px solid red' }} />
                                ) : (
                                    <p>{emp.user_details.bank_accountno}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Bank Name</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.bank_name} onChange={(e) => updateHandler(e, 'user_details', 'bank_name')} style={emp.user_details.bank_name ? null : { border: '1px solid red' }} />
                                ) : (
                                    <p>{emp.user_details.bank_name}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Bank Branch</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.bank_branch} onChange={(e) => updateHandler(e, 'user_details', 'bank_branch')} style={emp.user_details.bank_branch ? null : { border: '1px solid red' }} />
                                ) : (
                                    <p>{emp.user_details.bank_branch}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Bank IFSC</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.bank_ifsc} onChange={(e) => updateHandler(e, 'user_details', 'bank_ifsc')} style={emp.user_details.bank_ifsc ? null : { border: '1px solid red' }} />
                                ) : (
                                    <p>{emp.user_details.bank_ifsc}</p>
                                )}
                            </td>
                        </tr>
                        {userDetails.accesslevel.profile === "edit" && <>
                            <tr>
                                <th colSpan={2}>BFC Details</th>
                            </tr>
                            <tr>
                                <td>BFC Account No</td>
                                <td>
                                    {editFlag ? (
                                        <input type="text" value={emp.user_details.bfc_accountno} onChange={(e) => updateHandler(e, 'user_details', 'bfc_accountno')} />
                                    ) : (
                                        <p>{emp.user_details.bfc_accountno}</p>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>BFC Name</td>
                                <td>
                                    {editFlag ? (
                                        <input type="text" value={emp.user_details.bfc_name} onChange={(e) => updateHandler(e, 'user_details', 'bfc_name')} />
                                    ) : (
                                        <p>{emp.user_details.bfc_name}</p>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>BFC Branch</td>
                                <td>
                                    {editFlag ? (
                                        <input type="text" value={emp.user_details.bfc_branch} onChange={(e) => updateHandler(e, 'user_details', 'bfc_branch')} />
                                    ) : (
                                        <p>{emp.user_details.bfc_branch}</p>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>BFC IFSC</td>
                                <td>
                                    {editFlag ? (
                                        <input type="text" value={emp.user_details.bfc_ifsc} onChange={(e) => updateHandler(e, 'user_details', 'bfc_ifsc')} />
                                    ) : (
                                        <p>{emp.user_details.bfc_ifsc}</p>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>BFC Code</td>
                                <td>
                                    {editFlag ? (
                                        <input type="text" value={emp.user_details.bfc_code} onChange={(e) => updateHandler(e, 'user_details', 'bfc_code')} />
                                    ) : (
                                        <p>{emp.user_details.bfc_code}</p>
                                    )}
                                </td>
                            </tr>
                        </>}
                        <tr>
                            <td>Emergency Contact Name</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.emerg_name} onChange={(e) => updateHandler(e, 'user_details', 'emerg_name')} />
                                ) : (
                                    <p>{emp.user_details.emerg_name}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Emergency Contact Number</td>
                            <td>
                                {editFlag ? (
                                    <input type="phone" value={emp.user_details.emerg_phone} onChange={(e) => updateHandler(e, 'user_details', 'emerg_phone')} />
                                ) : (
                                    <p>{emp.user_details.emerg_phone}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>ID Proof Type</td>
                            <td>
                                {editFlag ? (
                                    <select value={emp.user_details.idproof_type} onChange={(e) => updateHandler(e, 'user_details', 'idproof_type')} style={emp.user_details.idproof_type ? null : { border: '1px solid red' }}>
                                        <option>Please Select Idproof Type</option>
                                        <option value="Aadhar">Aadhar</option>
                                        <option value="Driving Licence">Driving Licence</option>
                                        <option value="Passport ">Passport </option>
                                    </select>
                                ) : (
                                    <p>{emp.user_details.idproof_type}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>ID Proof Number</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.idproof_number} onChange={(e) => updateHandler(e, 'user_details', 'idproof_number')} style={emp.user_details.idproof_number ? null : { border: '1px solid red' }} />
                                ) : (
                                    <p>{emp.user_details.idproof_number}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>PAN No</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.pan} onChange={(e) => updateHandler(e, 'user_details', 'pan')} style={emp.user_details.pan ? null : { border: '1px solid red' }} />
                                ) : (
                                    <p>{emp.user_details.pan}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Paid Offs</td>
                            <td>{editUserOnly() ? (
                                <input type="text" value={emp.user_details.paid_offs} onChange={(e) => updateHandler(e, 'user_details', 'paid_offs')} />
                            ) : (
                                <p>{emp.user_details.paid_offs}</p>
                            )
                            }</td>
                        </tr>
                        <tr>
                            <td>Available Offs</td>
                            <td>
                                {editUserOnly() ? (
                                    <input type="text" value={emp.user_details.available_offs} onChange={(e) => updateHandler(e, 'user_details', 'available_offs')} />
                                ) : (
                                    <p>{emp.user_details.available_offs}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Salary</td>
                            <td>
                                {editUserOnly() ? (
                                    <input type="text" value={emp.user_details.salary} onChange={(e) => updateHandler(e, 'user_details', 'salary')} />
                                ) : (
                                    <p>{emp.user_details.salary}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Role</td>
                            <td>
                                {editUserOnly() ? (
                                    <input type="text" value={emp.user_details.role} onChange={(e) => updateHandler(e, 'user_details', 'role')} />
                                ) : (
                                    <p>{emp.user_details.role}</p>
                                )
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Joining Date</td>
                            <td>
                                {editUserOnly() ? (
                                    <input type="text" value={emp.user_details.joining_date ? new Date(emp.user_details.joining_date).toISOString().split('T')[0] : ''} onChange={(e) => updateHandler(e, 'user_details', 'joining_date')} className="date" />
                                ) : (
                                    <p>{emp.user_details.joining_date}</p>
                                )}
                            </td>
                        </tr>
                    </tbody>
                )}
            </table>


            <p>&nbsp;</p>
            <div className='btn'>
                <KuroLink to='/user/pwd-reset'><button>Change Password</button></KuroLink>
            </div>
            {empdata !== null && !editFlag && checkEditAccess() && <div className='btn'>
                <button onClick={() => setEditFlag(true)}>Edit</button>
            </div>}
            {editFlag && inputValid && <div className='btn'>
                <button onClick={() => submitHandler()}>Submit</button>
            </div>}
            {empdata !== null && inputValid === false && editFlag && <p style={{ color: 'red' }}>Please fill all the required fields before submitting</p>}
            <p>&nbsp;</p>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    admin: state.admin

});

export default connect(mapStateToProps)(Profile)