import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment-timezone'
import '../../styles/attendance.css'
import KuroLink from "../../components/common/KuroLink"

moment.tz.setDefault('Asia/Kolkata')

const Attendance = ({user: {token}})=> {

    const [emps, setemps] = useState(null)
    const [attData, setattData] = useState(null)
    const [datelist, setdatelist] = useState([])

    useEffect(() => {
        getDates()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/employees', config)
        .then((res) => {
            setemps(res.data)
        })

        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/emp_attendance', config)
        .then((res) => {
            setattData(res.data)
        })
    }, [])

    const getDates = () => {
        let tempDateList = []
        for (let i = -3; i <= 3; i++ ) {
            let timestamp = new Date().getTime() + i * 24 * 60 * 60 * 1000
            tempDateList.push(moment(new Date(timestamp)).format('YYYY-MM-DD'))
        }
        setdatelist(tempDateList)
    }

    const updateValue = (userid, e) => {
        let tempData = [...attData]
        if (tempData.filter(item => item.userid === userid).length > 0) {
            if (tempData.filter(item => item.userid === userid)[0]["status"].filter(stat => stat.at_date === moment(new Date()).format('DD-MM-YYYY')).length > 0) {
                tempData.filter(item => item.userid === userid)[0]["status"].filter(stat => stat.at_date === moment(new Date()).format('DD-MM-YYYY'))[0]["value"] = e.target.value
            } else {
                tempData.filter(item => item.userid === userid)[0]["status"].push({"at_date": moment(new Date()).format('DD-MM-YYYY'), "value": e.target.value})
            }
        } else {
            tempData.push({"userid": userid, "status": [{"at_date": moment(new Date()).format('DD-MM-YYYY'), "value": e.target.value}]})
        }
        setattData(tempData)
    }

    const submitHandler = (userid) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        let output = [{}]
        let data = attData.filter(item => item.userid === userid)[0]["status"].filter(item => item.at_date === moment(new Date()).format('DD-MM-YYYY'))

        output[0]["userid"] = userid
        output[0]["status"] = data[0]["value"]
        output[0]["at_date"] = data[0]["at_date"]

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/emp_attendance', output, config)
        .then((res) => {
            setattData(res.data)
        })
    }

    return (
        <div className='attendance'>
            <h2 className='txt-light-grey'>Attendance</h2>
            <ul className='btns'>
                <li><KuroLink to={"/attendance-dashboard"}><button>Dashboard</button></KuroLink></li>
            </ul>
            <table className='border even_odd'>
                <tbody>
                    <tr>
                        <th>Name</th>
                        {datelist.map((item, i) =>
                        <th key={i}>
                            {moment(item).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY') ? (
                                "Today"
                            ) : (
                                moment(item).format('DD-MM-YYYY')
                            )}
                        </th>
                        )}
                        <th></th>
                        <th></th>
                    </tr>
                    {emps !== null && emps.map((emp, i) =>
                     emp.empdetails.userid_id.startsWith("KCAD") || emp.user_status === "Inactive" ? ("") : (
                    <tr key={i}>
                        <td>{emp.name}</td>
                        {datelist.map((item, j) =>
                        <td key={j}>
                            {attData !== null && attData.filter(item => item.userid === emp.userid).length > 0 ? (
                                attData.filter(item => item.userid === emp.userid)[0]["status"].filter(stat => stat.at_date === moment(item).format('DD-MM-YYYY')).length > 0 ? (
                                    moment(item).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY') ? (
                                        <select value={attData.filter(item => item.userid === emp.userid)[0]["status"].filter(stat => stat.at_date === moment(item).format('DD-MM-YYYY'))[0]["value"]} onChange={(e)=> updateValue(emp.userid, e)}>
                                            <option value="">Select Status</option>
                                            <option value="Present">Present</option>
                                            <option value="Absent">Absent</option>
                                            <option value="Week Off">Week Off</option>
                                            <option value="Half Day">Half Day</option>
                                            <option value="Holiday">Holiday</option>
                                            <option value="Leave">Leave</option>
                                        </select>
                                    ) : (
                                        attData.filter(item => item.userid === emp.userid)[0]["status"].filter(stat => stat.at_date === moment(item).format('DD-MM-YYYY'))[0]["value"]
                                    )
                                ) : (
                                    moment(item).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY') ? (
                                        <select onChange={(e)=> updateValue(emp.userid, e)}>
                                            <option value="">Select Status</option>
                                            <option value="Present">Present</option>
                                            <option value="Absent">Absent</option>
                                            <option value="Week Off">Week Off</option>
                                            <option value="Half Day">Half Day</option>
                                            <option value="Holiday">Holiday</option>
                                            <option value="Leave">Leave</option>
                                        </select>
                                    ) : (
                                        "None"
                                    )
                                )
                            ) : (
                                moment(item).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY') ? (
                                    <select onChange={(e)=> updateValue(emp.userid, e)}>
                                        <option value="">Select Status</option>
                                        <option value="Present">Present</option>
                                        <option value="Absent">Absent</option>
                                        <option value="Week Off">Week Off</option>
                                        <option value="Half Day">Half Day</option>
                                        <option value="Holiday">Holiday</option>
                                        <option value="Leave">Leave</option>
                                    </select>
                                ) : (
                                    "None"
                                )
                            )}
                        </td>
                        )}
                        <td><button onClick={()=> submitHandler(emp.userid)}>Submit</button></td>
                        <td><KuroLink to={'/edit-attendance/'+ emp.userid}><button>Edit</button></KuroLink></td>
                    </tr>
                    )
                    )}
                </tbody>
            </table>
        </div>
    )}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(Attendance)