import React, {useState, useEffect} from 'react'
import axios from 'axios'
import KuroLink from '../components/common/KuroLink'
import { useHistory } from "react-router-dom"
import { connect } from 'react-redux'
import AddProduct from '../components/AddProduct'
import '../styles/products.css'
import '../styles/tabs.css'
import note from '../assets/img/button.png'
import user from '../reducers/user'


const  Products = ({ user: { userDetails }}) => {

    const [prodData, setProdData] = useState(null)
    const [addprod, setaddprod] = useState(false)
    const history=useHistory();
    
    useEffect(() => {
        if(userDetails.accesslevel.products === "NA"){
            history.push('/unauthorized')
            return;
        }
        gettempproducts()
    }, [])

    const gettempproducts = () => {
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/tempproducts').then(res => {
            setProdData(res.data)
        }).catch((err)=>{
            if(err.response.status==401){
                history.push("/unauthorized")
            }
        })
    }

    const approveHandler = (index) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const body = prodData[index]

        axios.post(process.env.REACT_APP_KG_API_URL + 'kuroadmin/addproduct', body, config)
        .then((res) => {
            gettempproducts()
            setProdData(res.data)
        }).catch((err)=>{
            if(err.response.status==401){
                history.push("/unauthorized")
            }
        })
    }

        const deleteHandler = (productid) => {
            axios.delete(process.env.REACT_APP_KG_API_URL + 'kuroadmin/addproduct?productid=' + productid)
            .then((res) => {
                gettempproducts()
            }).catch((err)=>{
                if(err.response.status==401){
                    history.push("/unauthorized")
                }
            })
        }

    const updateFlag = (e, field) => {
        let tempProd = [...prodData]
        tempProd[0][field] = e.target.checked
        setProdData(tempProd)
    }

    const prodHandler = (flag) => {
        setaddprod(flag)
    }

    return (
        <div className="prod prod-list">
        {addprod ? (
            <AddProduct backHandler={prodHandler} />
        ) : (
            <>
            <ul className='btns'>
                <li>{(userDetails.accesslevel.products === "write" || userDetails.accesslevel.products === "edit")?<button onClick={() => prodHandler(true)}>Add Product</button>:null}</li>
            </ul>
            <div className="note"><p>Instructions </p> 
                <img src={note} alt='instruction' className='note_img'/>:
                     <span className="note_text">lorem text</span>
                </div>
                <div>
                    <p className='txt-right'>Note:lorem text</p><br />
                </div> 
            {prodData !== null && prodData.length > 0 ? (
                prodData.map((prod, i) =>
            <table className='border txt-light' key={i} cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <td>Product Id</td>
                        <td>Product Url</td>
                        <td>Title</td>
                        <td>Collection</td>
                        <td>Type</td>
                        <td>Category</td>
                        <td>Brand</td>
                        {userDetails.accesslevel.products === "edit"?<td rowSpan="2"><button id="submit" onClick={() => approveHandler(i)}>Approve</button></td>:null}
                        {userDetails.accesslevel.products === "edit"?<td rowSpan="2"><button id="delete" onClick={() => deleteHandler(prod.productid)}>Delete</button></td>:null}
                    </tr>
                    <tr>
                        <td><KuroLink to={"/product/" + prod.prod_url}>{prod.productid}</KuroLink></td>
                        <td>{prod.prod_url}</td>
                        <td>{prod.title}</td>
                        <td>{prod.collection}</td>
                        <td>{prod.type}</td>
                        <td>{prod.category}</td>
                        <td>{prod.maker}</td>
                    </tr>
                </tbody>
            </table>
                )
            ) : (
                <p className='txt-light-grey'>No new products Added</p>
            )}
            </>
        )}
        </div>
    )
}


const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(Products)