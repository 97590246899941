import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import KuroLink from '../components/common/KuroLink'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
moment.tz.setDefault('Asia/Kolkata')

const TPOrderInventory = ({ match, user: {token, userDetails} }) => {

    const { params: { orderId } } = match
const history=useHistory();
    const [orderData, setorderData] = useState(null)
    const [invData, setinvData] = useState(null)
    const [submitted, setsubmitted] = useState(false)
const access=userDetails.accesslevel.orders;

useEffect(()=>{
    if(access!=="edit" && access!=="write"){
        history.push("/unauthorized");
        return;
    }
}, [])
    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/tporders?orderid=' + orderId, config).then(res => {
            setorderData(res.data[0])
        }).catch((err)=>{
            if(err.response.status==401){
                history.push("/unauthorized")
            }
        })
    }, [orderId])

    useEffect(() => {
        if (orderData !== null) {
            let tempinvdata = {}
            tempinvdata.orderid = orderData.orderid
            tempinvdata.ordertype = "tporders"
            if (orderData.builds !== undefined) {
                tempinvdata.builds = []
                for (let i = 0; i < orderData.builds.length; i++) {
                    let build_obj = {}
                    build_obj.serials = []
                    for (let j = 0; j < orderData.builds[i].components.length; j++) {
                        let sr_obj = {}
                        sr_obj.productid = orderData.builds[i].components[j].productid
                        sr_obj.title = orderData.builds[i].components[j].title
                        sr_obj.sr_no = []
                        for (let k = 0; k < orderData.builds[i].components[j].quantity; k++) {
                            sr_obj.sr_no.push("")
                        }
                        build_obj.serials.push(sr_obj)
                    }
                    tempinvdata.builds.push(build_obj)
                }
            }
            if (orderData.products !== undefined) {
                tempinvdata.products = []
                for (let i = 0; i < orderData.products.length; i++) {
                    for (let j = 0; j < orderData.products[i].quantity; j++) {
                        let sr_obj = {}
                        sr_obj.productid = orderData.products[i].productid
                        sr_obj.title = orderData.products[i].title
                        sr_obj.sr_no = ""
                        tempinvdata.products.push(sr_obj)
                    }
                }
            }
           
            setinvData(tempinvdata)
        }
    }, [orderData])

    const updatecomp = (e, bindex, cindex, sindex) => {
        let tempinvdata = {...invData}
        tempinvdata.builds[bindex].serials[cindex].sr_no[sindex] = e.target.value.toUpperCase()
        setinvData(tempinvdata)
    }

    const updateprod = (e, pindex, sindex) => {
        let tempinvdata = {...invData}
        tempinvdata.products[pindex].sr_no[sindex] = e.target.value.toUpperCase()
        setinvData(tempinvdata)
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        let invdata = invData
        let orderdata = {"order_status": "Inventory Added"}
        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/outward', invdata, config)
        .then(() => {
            axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/tporders?orderid=' + invdata.orderid, orderdata, config).then(
                setsubmitted(true)
            ).catch((err)=>{
                if(err.response.status==401){
                    history.push("/unauthorized")
                }
            })
        })
    }

    const handleBack=(()=>{
        history.goBack();
    })

    return (
        orderData !== null &&
        <div className="add-products txt-light-grey">
            {orderData.order_status !== "Authorized" ? (
                <div>
                    <p>Product Serial Nos have been successfully attached to Order {orderData.orderid}</p>
                    <KuroLink to='/tporders'>Go to Orders</KuroLink>
                </div>
            ) : (
                <>
                {submitted && <p>Product Serial Nos have been successfully attached to Order {orderData.orderid}</p>}
                <div>
                    <table cellPadding="0" cellSpacing="0" className="border">
                        <tbody>
                            <tr>
                                <th>Order Id</th>
                                <th>Order Date</th>
                                <th>Channel</th>
                                <th>TP Order Id</th>
                                <th>Customer Name</th>
                                <th>Order Total</th>
                                <th>Order Status</th>
                                <th>Dispatch Due Date</th>
                            </tr>
                            <tr>
                                <td>{orderData.orderid.toUpperCase()}</td>
                                <td>{orderData.order_date === "" ? "" : moment(orderData.order_date).format('DD-MM-YYYY')}</td>
                                <td>{orderData.channel}</td>
                                <td>{orderData.tporderid}</td>
                                <td>{orderData.user.name}</td>
                                <td>{orderData.totalprice}</td>
                                <td>{orderData.order_status}</td>
                                <td>{orderData.dispatchby_date === "" ? "" : moment(orderData.dispatchby_date).format('DD-MM-YYYY')}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>&nbsp;</p>
                    <div className="inventory">
                        <table className="build border" cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td>Product Title</td>
                                    <td>Serial Number</td>
                                </tr>
                            {invData !== null && invData.builds && invData.builds.map((build, i) =>
                                build.serials.map((comp, j) =>
                                <tr key={j}>
                                    <td>
                                        {comp.title}
                                    </td>
                                    <td>
                                        {submitted ? (
                                            comp.sr_no.join(',')
                                        ) : (
                                            comp.sr_no.map((e, k) => {
                                                return <><input value={e} className="large" onChange={(e) => updatecomp(e, i, j, k)} /><br/></>
                                            })
                                        )}
                                    </td>
                                </tr>
                                )
                            )}
                            {invData !== null && invData.products && invData.products.map((prod, i) =>
                                <tr>
                                    <td>
                                        {prod.title}
                                    </td>
                                    <td>
                                        {submitted ? (
                                            prod.sr_no.join(',')
                                        ) : (
                                            prod.sr_no.map((e, j) => {
                                                return <><input value={e} className="large" onChange={(e) => updateprod(e, i, j)} /><br/></>
                                            })
                                        )}
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                    {!submitted && 
                    <ul className='btns'>
                        <li>
                            <button onClick={submitHandler}>Submit</button>
                        </li>
                        
                    </ul>
                    }
                    <ul className='btns'>
                    <li><button onClick={handleBack}>back</button></li>
                    </ul>
                    <p>&nbsp;</p>
                </div>
                </>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(TPOrderInventory)