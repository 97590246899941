import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { updateLoginMsg, pwdLogin, getOtp, otpLogin} from '../actions/user'
import '../styles/login.css'

const Login = ({user: {isAuthenticated, loginMsg}, updateLoginMsg, pwdLogin, getOtp, otpLogin}) => {

    const [userid, setUserid] = useState("")
    const [otp, setOtp] = useState("")
    const [otpsent, setOtpsent] = useState(false)
    const [otplogin, setOtplogin] = useState(false)
    const [flag, setFlag] = useState(false)

    useEffect(() => {
        if (!isAuthenticated) {
            if (flag) {
                if (otplogin && loginMsg === "otp sent") {
                    setOtpsent(true)
                    updateLoginMsg("")
                }
            } else {
                setFlag(true)
                updateLoginMsg("")
            }
        }
    }, [isAuthenticated, loginMsg])

    const focusHandler = (e) => {
        e.target.closest(".field_wrapper").classList.add("focus")
    }

    const blurHandler = (e) => {
        e.target.closest(".field_wrapper").classList.remove("focus")
    }

    const changeHandler = (e) => {
        updateLoginMsg("")
        if (e.target.value === "") {
            e.target.closest(".field_wrapper").classList.remove("filled")
        } else {
            e.target.closest(".field_wrapper").classList.add("filled")
        }
    }

    const useridHandler = (e) => {
        setUserid(e.target.value)
        changeHandler(e)
    }

    const pwdToggleHandler = (e) => {
        if (e.target.closest(".field_wrapper").classList.contains("show")) {
            e.target.closest(".field_wrapper").classList.remove("show")
            e.target.closest(".field_wrapper").querySelector("#pwd").setAttribute("type", "password")
        } else {
            e.target.closest(".field_wrapper").classList.add("show")
            e.target.closest(".field_wrapper").querySelector("#pwd").setAttribute("type", "text")
        }
    }

    const pwdLoginHandler = () => {
        let userid = document.querySelector('.login_details #userid').value
        let pwd = document.querySelector('.login_details #pwd').value
        if (userid !== "" && pwd !== "") {
            pwdLogin(userid, pwd)
        } else {
            updateLoginMsg("Please enter valid login details")
        }
    }

    const VerifyHandler = () => {
        let userid = document.querySelector('.otp_login #userid').value
        if (userid === "") {
            updateLoginMsg("Please enter valid Email/Phone Number")
        } else {
            getOtp(userid)
        }
    }

    const loginWithOtp = () => {
        updateLoginMsg("")
        setOtplogin(true)
    }

    const loginWithPwd = () => {
        setOtplogin(false)
    }

    const backHandler = () => {
        updateLoginMsg("")
        setOtpsent(false)
        setOtp("")
        setUserid("")
    }

    const otpHandler = (e) => {
        e.target.closest(".field_wrapper").classList.remove("warn")
        if (e.target.value === "" || ((/^[0-9]+$/).test(e.target.value) && e.target.value.length <= 6)) {
            setOtp(e.target.value)
        }
        changeHandler(e)
    }

    const otpLoginHandler = () => {
        let otp = document.querySelector('.auth_details #otp').value
        otpLogin(userid, otp)
    }


    return (
        <>
        {isAuthenticated && <Redirect to={sessionStorage.getItem("path") } />}
        <div className="user_auth login">
            <div className='auth_left'>
                <div className="logo">
                    <svg fill="#ed2224" viewBox="0 0 1117 399.95"><title>Kuro Cadence Staff</title><path d="M314.08,695.75l-59.23,0L142.59,576.61V695.75H105V304.83h37.59V571.05L248.18,466.46l53.56.14L190,572.4Z" transform="translate(-105 -304.83)"/><path d="M620,616.29Q615.7,651,596.05,674t-40.71,26.69q-21.06,3.76-30.53,3.77-27.46,0-50.18-12.33t-35-37.52A132.91,132.91,0,0,1,432,621.33V462.7h41.35V601.2q0,27.28,14.14,47.76t37.78,20.47h5.57q19.47-2.53,33.61-18a51.93,51.93,0,0,0,14.14-36.23V462.7H620Z" transform="translate(-105 -304.83)"/><path d="M905.55,473.13,882.9,506.37q-12.27-11.58-25.48-11.58-18.4,0-33.5,19.39c-10.07,12.92-19.78,42.55-19.78,42.55V699.51H766.56v-233h33.83V522a123.32,123.32,0,0,1,22.82-42.05Q839,461,859.78,458,884.32,458,905.55,473.13Z" transform="translate(-105 -304.83)"/><path d="M1222,581.91q0,50.87-34.67,86.86t-83.29,36q-49.07,0-83.74-36T985.62,581.4q0-51.85,33.73-88.88t82.34-37q49.55,0,84.92,36.76T1222,581.91Zm-35.39,0q0-36.54-23.35-62.33t-56.85-25.79q-34.46,0-58.75,24.79t-24.3,60.32q0,35.55,23.6,61.59t57.08,26q33.51,0,58-24.54T1186.61,581.9Z" transform="translate(-105 -304.83)"/></svg>
                </div>
                <div className="txt">
                    <p>This computer system is the property of Kuro Cadence LLP. It is restricted to authorized users only. If you are unauthorized, terminate access now.</p>                
                    <div className="warning">
                        <p>WARNING: IT IS AN OFFENSE TO CONTINUE WITHOUT PROPER AUTHORIZATION. INDIVIDUALS WHO ATTEMPT UNAUTHORIZED ACCESS WILL BE PROSECUTED.</p>
                    </div>
                    <div className="txt-grey">
                    <p><strong>© 2020-2022, Kuro Cadence LLP or its affiliates.</strong></p>
                    </div>
                </div>
            </div>
            <div className='auth_right'>
                <div className="auth_details">
                     <div className="title txt-dark-head">
                        <h2>
                            <svg width="24" height="24" viewBox="0 0 24 24"><path d="M6 8v-2c0-3.313 2.687-6 6-6 3.312 0 6 2.687 6 6v2h-2v-2c0-2.206-1.795-4-4-4s-4 1.794-4 4v2h-2zm-3 2v14h18v-14h-18z"/></svg>
                            <span>Login</span>
                        </h2>
                    </div>
                {!otplogin ? (
                    <div className="login_details">
                        <div className="field_wrapper">
                            <label>Email or Mobile Number*</label>
                            <div className="input-wrapper">
                                <input id="userid" name="userid" onChange={useridHandler} onFocus={focusHandler} onBlur={blurHandler} />
                            </div>
                        </div>
                        <div className="field_wrapper pwd">
                            <label>Password*</label>
                            <div className="input-wrapper">
                                <input id="pwd" name="pwd" type="password" onChange={changeHandler} onFocus={focusHandler} onBlur={blurHandler} />
                                <div className="toggle-pwd">
                                    <svg className="show" onClick={pwdToggleHandler} title="Show password" width="24" height="24" viewBox="0 0 24 24"><path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 5c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zm0-2c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z"/></svg>
                                    <svg className="hide" onClick={pwdToggleHandler} title="Hide password" width="24" height="24" viewBox="0 0 24 24"><path d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z"/></svg>
                                </div>
                                <p className="util forgot"><button className="txt-link" onClick={loginWithOtp}>Forgot Password ?</button></p>
                            </div>
                        </div>
                        <div className="field_wrapper">
                            <button className="btn pri1 solid" name="login" id="login" onClick={pwdLoginHandler}>Login</button>
                            {loginMsg !== "" && <span className="login_error">{loginMsg}</span>}
                        </div> 
                        <p className="util"><button className="txt-link" onClick={loginWithOtp}>Login with OTP</button></p>
                    </div>
                    ) : (
                    <div className="otp_login">
                        {!otpsent ? (
                        <div className="userid">
                            <div className="field_wrapper">
                                <label>Email/Phone Number</label>
                                <div className="input_wrapper">
                                    <input id="userid" name="userid" onChange={useridHandler} onFocus={focusHandler} onBlur={blurHandler} />
                                </div>
                            </div>
                            <div className="field_wrapper">
                                <button className="btn pri1 solid" name="continue" id="continue" onClick={VerifyHandler}>Continue</button>
                                {loginMsg !== "" && loginMsg !== "otp sent" && <span className="login_error">{loginMsg}</span>}
                            </div>
                        </div>
                        ) : (
                        <div className="otp">
                            <span className="back" onClick={backHandler}>
                                <svg fill="#4d4c4c" width="24" height="24" viewBox="0 0 24 24"><path d="M0 12l9-8v6h15v4h-15v6z"/></svg>
                            </span>
                            <p>Enter OTP sent to your mobile number</p>
                            <div className="field_wrapper">
                                <label>OTP</label>
                                <div className="input_wrapper">
                                    <input id="otp" name="otp" value={otp} onChange={otpHandler} onFocus={focusHandler} onBlur={blurHandler} />
                                    {loginMsg !== "" && <span className="login_error">{loginMsg}</span>}
                                </div>
                            </div>
                            <div className="field_wrapper">
                                <button className="btn pri1 solid" name="login" id="login" onClick={otpLoginHandler}>Login</button>
                            </div>
                        </div>
                        )}
                        <p className="util">
                            <button className="txt-link" onClick={loginWithPwd}>Login using Password</button>
                        </p>
                    </div>
                    )}
                </div>
                <p className='ack txt-light-grey'>By using this system, accessing or using this portal, website, information, data, etc, all individuals or entities including but not limited to shareholders, directors, employees, third-party service providers, contract staff, interns, accountants/auditors, vendors, etc acknowledge notice of, and agree to comply with, the Internal Policies – Non-Compete Clauses, Intellectual Property Clauses, Confidentiality Clauses, Penalty clauses, Terms &amp; Conditions, etc of Kuro Cadence LLP.</p>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps, { updateLoginMsg, pwdLogin, getOtp, otpLogin })(Login)