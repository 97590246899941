import React, { useEffect, useState } from 'react'
import KuroLink from '../components/common/KuroLink'
import { connect } from 'react-redux'
import axios from 'axios'
import '../styles/table.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const StockColl = ({ match, user: { token }, products: { monitorData, extDeviceData, networkData } }) => {

    const { params: { prodColl } } = match
    const [prods, setProds] = useState(null)
    const [proddata, setproddata] = useState(null)
    const history = useHistory();
    useEffect(() => {
        if (prodColl === "monitors") {
            setproddata(monitorData)
        } else if (prodColl === "external") {
            setproddata(extDeviceData)
        } else if (prodColl === "networking") {
            setproddata(networkData)
        }
    }, [prodColl, monitorData, extDeviceData, networkData])

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/inventory?collection=' + prodColl, config).then(res => {
            setProds(res.data)
        }).catch((err) => {
            if (err.response.status == 401) {
                history.push("/unauthorized")
            }
        })
    }, [prodColl])

    const categoryHandler = (category) => {
        switch (category) {
            case "monitors":
                return "/m/"
            case "networking":
                return "/n/"
            case "external":
                return "/e/"
            default:
                return "/p/"
        }
    }

    if (prods !== null && prods.lenght > 0) {
        return (
            <div className="prods mx-width txt-light-grey">
                <p className="breadcrumb txt-light-grey"><KuroLink to="/stock-register">Stock Register</KuroLink> &gt; {prodColl}</p>
                <div className="page-title">
                    <h1 className='stock-reg-title' style={{ color: "white", fontWeight: 500 }}>{prodColl}</h1>
                </div>
                <table className="border" cellPadding="0" cellSpacing="0">
                    <tr>
                        <th>Product ID</th>
                        <th>Title</th>
                        <th>Serial No.</th>
                        <th>Product No.</th>
                        <th>UPC No.</th>
                        <th>EAN No.</th>
                    </tr>
                    {prods !== null && prods.map((prod) =>
                        <tr>
                            <td>{proddata !== null && <a target="_blank" rel="noreferrer" href={"https://kurogaming.com" + categoryHandler(prodColl) + proddata.filter(item => item.productid === prod.productid)[0]["prod_url"]}>{prod.productid}</a>}</td>
                            <td>{proddata !== null && proddata.filter(item => item.productid === prod.productid)[0]["title"]}</td>
                            <td>{prod.sr_no}</td>
                            <td>{prod.prod_no}</td>
                            <td>{prod.upc_no}</td>
                            <td>{prod.ean_no}</td>
                        </tr>
                    )}
                </table>
                <p>&nbsp;</p>
            </div>
        )
    } else {
        return (
            <div className="prods mx-width txt-light-grey">
                <p className="breadcrumb txt-light-grey"><KuroLink to="/stock-register">Stock Register</KuroLink> &gt; {prodColl}</p>
                <div className="page-title">
                    <h1 className='stock-reg-title' style={{ color: "white", fontWeight: 500 }}>{prodColl}</h1>
                </div>
                <p>No Products Found</p>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    products: state.products,
    user: state.user
})

export default connect(mapStateToProps)(StockColl)