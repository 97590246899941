import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min' 

const EmployeeAccessLevel = ({ user: { token, userDetails } }) => {
  const [emps, setEmps] = useState(null);
  const [employeeNames, setEmployeeNames] = useState([]);
  const [readOnlyKeys, setReadOnlyKeys] = useState([]);
  const [readWriteKeys, setReadWriteKeys] = useState([]);
  const [editKeys, setEditKeys]=useState([])
  const [isModalOpen, setIsModalOpen] = useState({ visibility: false, emp: null });
  const history=useHistory()

  useEffect(()=>{
  const access=userDetails.accesslevel.employee_accesslevel;
   if(access==="NA"){
          history.push("/unauthorized")}
          return;
   }, [])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        };

        const accessLevelResponse = await axios.get(process.env.REACT_APP_KC_API_URL + 'accesslevel', config);
        setEmps(accessLevelResponse.data);

        const employeesResponse = await axios.get(process.env.REACT_APP_KC_API_URL + 'kuroadmin/employees', config);
        setEmployeeNames(employeesResponse.data);
      } catch (err) {
        if(err.response.status==401){
          history.push("/unauthorized")
      }
      }
    };

    fetchData();
  }, [token]);

  const handleButtonClick = (emp) => {
    setIsModalOpen({
      visibility: true,
      emp: emp,
    });

    // Filter keys based on access level
    const readOnly = [];
    const readWrite = [];
    const edit=[];
    for (const key in emp) {
      if (emp[key] === 'read') {
        readOnly.push(key);
      } else if (emp[key] === 'write') {
        readWrite.push(key);
      }
      else if(emp[key]==="edit"){
        edit.push(key);
      }
    }
    setReadOnlyKeys(readOnly);
    setReadWriteKeys(readWrite);
    setEditKeys(edit)
  };

  const handleDropDownClose = () => {
    setIsModalOpen({ visibility: false, emp: null });
    setReadOnlyKeys([]);
    setReadWriteKeys([]);
    setEditKeys([])
  };

  return (
    <div>
      <h2 className="txt-light-grey">Employee Access Level</h2>
      <table style={{ color: 'white' }} className="border table_mob">
        <thead>
          <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Access Level</th>
            {( userDetails.accesslevel.employee_accesslevel==="edit") && <th>Manage Access</th> }
          </tr>
        </thead>
        <tbody>
          {employeeNames.length > 0 && emps !== null && emps.map((emp) => (
            <tr key={emp.userid}>
              <td>{emp.userid}</td>
              <td>{employeeNames.find(name => name.userid === emp.userid)?.name}</td>
              <td>
                <button onClick={() => handleButtonClick(emp)} className="button_mob">Show Access</button>
              </td>
              {( userDetails.accesslevel.employee_accesslevel==="edit")  && <td>
                <Link to={`/employee-accesslevel/${emp.userid}/${encodeURIComponent(employeeNames.find(name => name.userid === emp.userid)?.name)}`}>Manage Access</Link>
              </td>}
              
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={isModalOpen.visibility}
        onRequestClose={handleDropDownClose}
        contentLabel="Show Access"
        className="modal"
        overlayClassName="overlay"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
          }
        }}>
        {readOnlyKeys.length > 0 ? (
          <>
            <h5>Read Only</h5>
            <div className='list-container'>
              {readOnlyKeys.map((key) => (
                <span className="list" key={key}>{key}</span>
              ))}
            </div>
          </>
        ) : null}
        <br />
        {readWriteKeys.length > 0 ? (
          <>
            <h5>Read & Write</h5>
            <div className='list-container'>
              {readWriteKeys.map((key) => (
                <span className="list" key={key}>{key}</span>
              ))}
            </div>
          </>
        ) : null}
        <br></br>
        {editKeys.length>0 >(<>
        <h5>Edit</h5>
        <div className='list-container'>
          {editKeys.map((key)=><span className='list' key={key}>{key}</span>)}</div></>)}

        {/* Display 'No Access' if both sections are empty */}
        {readOnlyKeys.length === 0 && readWriteKeys.length === 0 && (
          <p className="text-center">No Access</p>
        )}

        <button className="arrow"onClick={handleDropDownClose}><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
</svg></button>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(EmployeeAccessLevel);
