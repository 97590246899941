import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import '../styles/table.css'
import '../styles/react-select.css'

const NetworkInventory = ({user: {token}, products: { networkData }, admin: { inwardinvoices }}) => {

    const [prods, setProds] = useState([])
    const [makers, setMakers] = useState([])
    const selectedProd = useRef()

    useEffect(() => {
        if (networkData != null) {
            addrow(true)
            setMakers([...new Set(networkData.map(item => item.maker))])
        }
    }, [networkData])

    const addrow = (first) => {
        if (first) {
            setProds([{'collection': 'networking', 'type': '', 'maker': '', 'productid': '', 'prod_no': '', 'upc_no': '', 'ean_no': '', 'quantity': 1, 'sr_no': [''], 'pricebg': 0, 'price': 0, 'mrp': 0, 'invoice_date': '', 'invoiceid': ''}])
        } else {
            setProds(prevState => [...prevState, {'collection': 'networking', 'type': '', 'maker': '', 'productid': '', 'prod_no': '', 'upc_no': '', 'ean_no': '', 'quantity': 1, 'sr_no': [''], 'pricebg': 0, 'price': 0, 'mrp': 0, 'invoice_date': '', 'invoiceid': ''}])
        }
    }

    const removerow = (index) => {
        setProds(prevState => prevState.filter((_, i) => i !== index))
    }

    const updateprod = (index, key, e, num) => {
        const tempprods = [...prods]
        if (key === 'type' || key === 'maker'){
            selectedProd.current.setValue('')
        }
        tempprods[index][key] = num ? Number(e.target.value) : e.target.value
        setProds(tempprods)
    }

    const updatequantity = (index, e) => {
        const tempprods = [...prods]
        const tempsr = []
        for (let q = 0; q < e.target.value; q++) {
            tempsr.push('')
        }
        tempprods[index]["sr_no"] = tempsr
        tempprods[index]["quantity"] = e.target.value
        setProds(tempprods)
    }

    const updateserial = (index, e, ind) => {
        const tempprods = [...prods]
        tempprods[index]["sr_no"][ind] = e.target.value
        setProds(tempprods)
    }

    const updateprodprice = (index, key, e) => {
        const tempprods = [...prods]
        if (key === "price") {
            tempprods[index][key] = Number(e.target.value)
            tempprods[index]["pricebg"] = Number(e.target.value) / 1.18
        } else {
            tempprods[index][key] = Number(e.target.value)
            tempprods[index]["price"] = Math.ceil(Number(e.target.value) * 1.18)
        }
        setProds(tempprods)
    }

    const updateproddate = (index, key, date) => {
        const tempprods = [...prods]
        tempprods[index][key] = date
        setProds(tempprods)
    }

    const updateSearchProd = (index, key, e, num) => {
        const tempprods = [...prods]
        tempprods[index][key] = num ? Number(e.value) : e.value
        setProds(tempprods)
    }

    const selectionOptions = (prod) =>{
        const tempArray = []
        networkData !== null && networkData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
            return (
                tempArray.push({ value: item.productid, label: item.title})
            )
        })
        return tempArray
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        const inv_data = prods

        const body = JSON.stringify({ inv_data })
        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/inventory', body, config)
        addrow(true)
    }

    return (
        <div className="prods inventory txt-light">
            <table className="home border table_mob" cellSpacing="0" cellPadding="0">
                <tbody>
                    <tr>
                        <th>Invoice Date</th>
                        <th>Invoice No.</th>
                        <th>Type</th>
                        <th>Brand</th>
                        <th>Model</th>
                        <th>Product No.</th>
                        <th>UPC No. (12-Digit)</th>
                        <th>EAN No. (13-Digit)</th>
                        <th>Quantity</th>
                        <th>Serial No.</th>
                        <th>Price<br/>excl. GST</th>
                        <th>Price<br/>incl. GST</th>
                        <th>MRP</th>
                        <th></th>
                    </tr>
                {prods.map((prod, i) =>
                    <tr key={i}>
                        <td>
                            <DatePicker className="medium" dateFormat='dd-MMM-yyyy' selected={prod.invoice_date} onChange={date => updateproddate(i, "invoice_date", date)} />
                        </td>
                        <td>
                            <select value={prod.invoiceid} onChange={(e) => updateprod(i, "invoiceid", e, false)}>
                                <option value="">Select Invoice</option>
                                {inwardinvoices !== null && inwardinvoices.filter(item => new Date(item.invoice_date).toString() === prod.invoice_date.toString()).map((invoice, i) =>
                                    <option value={invoice.invoiceid}>{invoice.invoice_no}</option>
                                )}
                            </select>
                        </td>
                        <td>
                            <select value={prod.type} onChange={(e) => updateprod(i, "type", e, false)}>
                                <option value="">Select Type</option>
                                <option value="wifi-card">Wifi Card</option>
                            </select>
                        </td>
                        <td>
                            <select value={prod.maker} onChange={(e) => updateprod(i, "maker", e, false)}>
                                <option value="">Select Brand</option>
                            {makers !== null && makers.sort().map((maker, j) =>
                                networkData.filter(item => item.type === prod.type && item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                            )}
                            </select>
                        </td>
                        <td>
                            <Select classNamePrefix="kuro-search-select" options={selectionOptions(prod)} defaultValue={{ value: prod.productid, label: prod.title }} onChange={(e) => updateSearchProd(i,"productid", e, false)} placeholder="Select Model" className="react-select-container" ref={selectedProd}/>
                        </td>
                        <td>
                            <input value={prod.prod_no} onChange={(e) => updateprod(i, "prod_no", e, false)} />
                        </td>
                        <td>
                            <input value={prod.upc_no} onChange={(e) => updateprod(i, "upc_no", e, false)} />
                        </td>
                        <td>
                            <input value={prod.ean_no} onChange={(e) => updateprod(i, "ean_no", e, false)} />
                        </td>
                        <td>
                            <input value={prod.quantity} type="number" className="small" min="1" max="100" onChange={(e) => updatequantity(i, e)} />
                        </td>
                        <td>
                        {prod.sr_no.map((sr,j) =>
                            <input key={j} value={sr} onChange={(e) => updateserial(i, e, j)} />
                        )}
                        </td>
                        <td>
                            <input type="number" className="small" value={prod.pricebg} onChange={(e) => updateprodprice(i, "pricebg", e)} />
                        </td>
                        <td>
                            <input type="number" className="small" value={prod.price} onChange={(e) => updateprodprice(i, "price", e)} />
                        </td>
                        <td>
                            <input type="number" className="small" value={prod.mrp} onChange={(e) => updateprod(i, "mrp", e, true)} />
                        </td>
                        <td>
                        {prods.length - 1 === i ? (
                            <>
                            <span className="prod-span" onClick={() => removerow(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span>
                            <span className="prod-span" onClick={() => addrow(false)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                            </>
                        ) : (
                            <span className="prod-span" onClick={() => removerow(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span>
                        )}
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            <ul className='btns'>
                <li><button onClick={submitHandler}>Submit</button></li>
            </ul>
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps)(NetworkInventory)