import React, {useState} from 'react'
import CompInventory from '../components/CompInventory'
import AccessoryInventory from '../components/AccessoryInventory'
import MonitorInventory from '../components/MonitorInventory'
import NetworkInventory from '../components/NetworkInventory'
import ExtDevicesInventory from '../components/ExtDevicesInventory'
import KuroLink from '../components/common/KuroLink'
import '../styles/entry.css'
import note from '../assets/img/button.png'


const Inventory = () => {
    const [tabIndex, setTabIndex] = useState(0)

    return (
        <div className="kuro-inventory entry txt-light-grey">
            <h2>Inventory Management</h2>
            <div className="note"><p>Instructions </p> 
                <img src={note} alt='instruction' className='note_img'/>:
                     <span className="note_text">lorem text</span>
                </div>
                <div>
                    <p className='txt-right'>Note:lorem text</p><br />
                </div> 
            <div className='col_2'>
                <KuroLink to= "/stock/inventory/details"><button>Find Stock</button></KuroLink>
            </div>
            <div className="tabs">
                <button onClick={() => setTabIndex(0)} className={"tab txt-grey" + (tabIndex === 0 ? " active" : "")}>Components</button>
                <button onClick={() => setTabIndex(1)} className={"tab txt-grey" + (tabIndex === 1 ? " active" : "")}>Monitors</button>
                <button onClick={() => setTabIndex(2)} className={"tab txt-grey" + (tabIndex === 2 ? " active" : "")}>Accessories</button>
                <button onClick={() => setTabIndex(3)} className={"tab txt-grey" + (tabIndex === 3 ? " active" : "")}>Networking Gear</button>
                <button onClick={() => setTabIndex(4)} className={"tab txt-grey" + (tabIndex === 4 ? " active" : "")}>External Devices</button>
            </div>
            <div className="tab-panels">
                <div className={"tab-panel" + (tabIndex === 0 ? " active" : "")}>
                    <h2 className="txt-light-grey">Components</h2>
                    <CompInventory/>
                </div>
                <div className={"tab-panel txt-light-grey" + (tabIndex === 1 ? " active" : "")}>
                    <h2 className="txt-light-grey">Monitors</h2>
                    <MonitorInventory/>
                </div>
                <div className={"tab-panel txt-light-grey" + (tabIndex === 2 ? " active" : "")}>
                    <h2 className="txt-light-grey">Accessories</h2>
                    <AccessoryInventory/>
                </div>
                <div className={"tab-panel txt-light-grey" + (tabIndex === 3 ? " active" : "")}>
                    <h2 className="txt-light-grey">Networking Gear</h2>
                    <NetworkInventory/>
                </div>
                <div className={"tab-panel txt-light-grey" + (tabIndex === 4 ? " active" : "")}>
                    <h2 className="txt-light-grey">External Devices</h2>
                    <ExtDevicesInventory/>
                </div>
            </div>
            <p>&nbsp;</p>
        </div>
    )
}

export default Inventory