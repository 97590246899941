import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment-timezone'

moment.tz.setDefault('Asia/Kolkata')

const OfflineOrderInvoice = ({ match, user: {token}, admin: { provinces }}) => {

    const { params: { orderid } } = match
    const [orderdata, setorderdata] = useState(null)
    const [invoicedata, setinvoicedata] = useState(null)
    const [preview, setpreview] = useState(false)
    const [errmsg, seterrmsg] = useState({
        "state": "",
        "pan": "",
        "gstin": ""
    })

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/kgorders?orderid=' + orderid, config).then(res => {
            setorderdata(res.data[0])
        })
    }, [])

    useEffect(() => {
        if (orderdata !== null) {
            let tempinvoice = {...orderdata}
            for (let i=0; i<tempinvoice.builds.length; i++) {
                tempinvoice.builds[i].buildType = "custom"
                tempinvoice.builds[i].components = tempinvoice.builds[i].components.filter(comp => comp.title !== "" && comp.quantity !== 0)
                for (let j=0; j<tempinvoice.builds[i].components.length; j++) {
                    let srno = ""
                    if (tempinvoice.outward) {
                        let sr_nos = tempinvoice.outward.builds[i].serials[j].sr_no
                        for (let k=0; k<sr_nos.length; k++) {
                            if (sr_nos[k] !== "") {
                                if (srno !== "") {
                                    srno += ", "
                                }
                                srno += sr_nos[k]
                            }
                        }
                        if (srno !== "") {
                            tempinvoice.builds[i].components[j].title += " [S/N: " + srno + "]"
                        }
                    }
                }
            }
            for (let i=0; i<tempinvoice.products.length; i++) {
                let srno = ""
                if (tempinvoice.outward) {
                    let sr_nos = tempinvoice.outward.products[i].sr_no
                    for (let k=0; k<sr_nos.length; k++) {
                        if (sr_nos[k] !== "") {
                            if (srno !== "") {
                                srno += ", "
                            }
                            srno += sr_nos[k]
                        }
                    }
                    if (srno !== "") {
                        tempinvoice.products[i].title += " [S/N: " + srno + "]"
                    }
                }
            }
            tempinvoice.create_order = false
            tempinvoice.invoice_date = tempinvoice.order_date
            tempinvoice.po_ref = tempinvoice.po_ref === "" ? tempinvoice.tporderid : tempinvoice.po_ref
            delete tempinvoice.notes
            delete tempinvoice.version
            delete tempinvoice.estimate_no
            delete tempinvoice.dispatchby_date
            delete tempinvoice.invoice_generated
            delete tempinvoice.order_status
            delete tempinvoice.build_srno
            delete tempinvoice.outward
            setinvoicedata(tempinvoice)
        }
    }, [orderdata])

    const round = (type, value, decimals = 0, even=false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updateroundoff = (tempinvoice) => {
        let roundoff = 0
        if (tempinvoice.billadd.state === "Telangana") {
            roundoff = ((tempinvoice.totalprice * 100) - (200 * tempinvoice.cgst) - (100 * tempinvoice.totalpricebgst))
        } else {
            roundoff = ((tempinvoice.totalprice * 100) - (100 * tempinvoice.gst) - (100 * tempinvoice.totalpricebgst))
        }
        tempinvoice.roundoff = round("round", Math.abs(roundoff) >= 1 ? roundoff / 100 : 0, 2)
        return tempinvoice
    }

    const updatetotal = (tempinvoice) => {
        tempinvoice.taxes = {}
        let totalprice = 0
        let totaldiscount = 0
        let totalpricebgst = 0
        let gst = 0
        let cgst = 0
        let subtotals = {
            "5": 0,
            "12": 0,
            "18": 0,
            "28": 0
        }

        for (let i=0; i<tempinvoice.builds.length; i++) {
            totaldiscount += tempinvoice.builds[i].totaldiscount
            subtotals[tempinvoice.builds[i].tax_rate] += tempinvoice.builds[i].totalprice
            totalprice += tempinvoice.builds[i].totalprice
        }
        for (let i=0; i<tempinvoice.products.length; i++) {
            totaldiscount += tempinvoice.products[i].totaldiscount
            subtotals[tempinvoice.products[i].tax_rate] += tempinvoice.products[i].totalprice
            totalprice += tempinvoice.products[i].totalprice
        }
        for (const [key, value] of Object.entries(subtotals)) {
            let tpbg = 0
            let trgst = 0
            let trcgst = 0
            tpbg = round("round", (100 * value) / (100 + parseInt(key)), 2)
            totalpricebgst += tpbg
            trgst = round("round", (value * parseInt(key)) / (100 + parseInt(key)), 2)
            trcgst = round("round", (value * parseInt(key)) / (2 * (100 + parseInt(key))), 2)
            if (tempinvoice.billadd.state === "Telangana") {
                tempinvoice.taxes[key] = trcgst
            } else {
                tempinvoice.taxes[key] = trgst
            }
            gst += trgst
            cgst += trcgst
        }
        tempinvoice.totaldiscount = totaldiscount
        tempinvoice.totalprice = totalprice
        tempinvoice.totalpricebgst = round("round", totalpricebgst, 2)
        tempinvoice.gst = round("round", gst, 2)
        tempinvoice.cgst = round("round", cgst, 2)

        tempinvoice = updateroundoff(tempinvoice)
        return tempinvoice
    }

    const updateinvoice = (key, e) => {
        let tempinvoice = {...invoicedata}
        tempinvoice[key] = e.target.value
        setinvoicedata(tempinvoice)
    }

    const updateinvoicedate = (date) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.invoice_date = (date == null) ? "" : date
        setinvoicedata(tempinvoice)
    }

    const updateuser = (key, e) => {
        let tempinvoice = {...invoicedata}
        let value = e.target.value
        if (key === "phone") {
            value = value.replaceAll("+91", "").replaceAll(/\D/g, "")
        }
        tempinvoice.user[key] = value
        tempinvoice.billadd[key] = value
        setinvoicedata(tempinvoice)
    }

    const updatepangstin = (invoice) => {
        let tempmsg = {...errmsg}
        if (invoice.billadd.pan.length !== 10 || !(/[A-Z]{5}[0-9]{4}[A-Z]{1}/).test(invoice.billadd.pan)) {
            tempmsg["pan"] = "*** Please Enter Valid PAN number"
        } else {
            tempmsg["pan"] = ""
        }
        if (invoice.billadd.gstin !== "" && (invoice.billadd.gstin.length !== 15 || invoice.billadd.gstin.slice(2,12) !== invoice.billadd.pan || !(/^[0-9]*$/).test(invoice.billadd.gstin.slice(0,2)))) {
            tempmsg["gstin"] = "*** Please Enter Valid GSTIN number"
        } else {
            tempmsg["gstin"] = ""
        }
        seterrmsg(tempmsg)
    }

    const updateaddress = (key, subkey, e) => {
        let value = e.target.value
        let tempinvoice = {...invoicedata}
        if (key === "billadd" && subkey === "state") {
            if (value === "") {
                seterrmsg(msg => ({...msg, state: "Please select the State"}))
            } else {
                seterrmsg(msg => msg.state === "")
            }
            tempinvoice[key][subkey] = value
            tempinvoice = updatetotal(tempinvoice)
        } else if (subkey === "pincode") {
            if (value.length <= 6 && (/^[0-9]*$/).test(value)) {
                tempinvoice[key][subkey] = value
            }
        } else if (subkey === "pan" || subkey === "gstin") {
            if ((/^[A-Z0-9]*$/).test(value.toUpperCase())) {
                tempinvoice[key][subkey] = value.toUpperCase()
            }
            updatepangstin(tempinvoice)
        } else {
            tempinvoice[key][subkey] = value
        }
        setinvoicedata(tempinvoice)
    }

    const updateaddressflag = (value) => {
        let tempinvoice = {...invoicedata}
        tempinvoice["addressflag"] = value
        if (value) {
            delete tempinvoice["shpadd"]
        } else {
            tempinvoice["shpadd"] = {'company': '', 'name': '', 'phone': '', 'addressline1': '', 'addressline2': '', 'city': '', 'pincode': '', 'state': '', 'gstin': '', 'pan': ''}
        }
        setinvoicedata(tempinvoice)
    }

    const updatebuild = (type, bindex, key, e, num=false, cindex) => {
        let tempinvoice = {...invoicedata}
        if (type === "build") {
            tempinvoice.builds[bindex][key] = num ? Number(e.target.value) : e.target.value
        } else {
            tempinvoice.builds[bindex].components[cindex][key] = e.target.value
        }
        if (key === "price" || key === "quantity") {
            let totalprice = parseFloat(tempinvoice.builds[bindex].price) * tempinvoice.builds[bindex].quantity
            tempinvoice.builds[bindex].pricebgst = round("round", parseFloat(tempinvoice.builds[bindex].price) / 1.18, 2)
            tempinvoice.builds[bindex].totalprice = totalprice
            tempinvoice.builds[bindex].totalpricebgst = round("round", totalprice / 1.18, 2)
            tempinvoice = updatetotal(tempinvoice)
        }
        setinvoicedata(tempinvoice)
    }

    const addcomp = (bindex, cindex) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.builds[bindex].components.splice(cindex+1, 0, {'collection': 'components', 'type': '', 'maker': 0, 'productid': '', 'title': '', 'quantity': 1})
        setinvoicedata(tempinvoice)
    }

    const removecomp = (bindex, cindex) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.builds[bindex].components.splice(cindex, 1)
        setinvoicedata(tempinvoice)
    }

    const prodtotal = (tempinvoice, pindex) => {
        if (tempinvoice.products.length > 0) {
            const quantity = tempinvoice.products[pindex].quantity
            const price = tempinvoice.products[pindex].prodprice - tempinvoice.products[pindex].discount
            const totalprice = price * quantity
            tempinvoice.products[pindex].pricebgst = round("round", price / (1 + (tempinvoice.products[pindex].tax_rate / 100)), 2)
            tempinvoice.products[pindex].price = price
            tempinvoice.products[pindex].totalpricebgst = round("round", totalprice / (1 + (tempinvoice.products[pindex].tax_rate / 100)), 2)
            tempinvoice.products[pindex].totalprice = totalprice
            tempinvoice = updatetotal(tempinvoice)
        }
        return tempinvoice
    }

    const updateprod = (pindex, key, e, num=false) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.products[pindex].discount = 0
        tempinvoice.products[pindex].totaldiscount = 0
        tempinvoice.products[pindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "collection" && key === "type" && key === "category" && key === "maker") {
            tempinvoice.products[pindex].productid = ""
            tempinvoice.products[pindex].title = ""
            tempinvoice.products[pindex].prodprice = 0
        }
        if (key !== "title") {
            tempinvoice = prodtotal(tempinvoice, pindex)
        }
        setinvoicedata(tempinvoice)
    }

    const updateproddiscount = (pindex, e) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.products[pindex].discount = Number(e.target.value)
        tempinvoice.products[pindex].totaldiscount = tempinvoice.products[pindex].discount * tempinvoice.products[pindex].quantity
        tempinvoice = prodtotal(tempinvoice, pindex)
        setorderdata(tempinvoice)
    }

    var fileDownload = require('js-file-download')

    const invoiceHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let invoice = invoicedata
        if (invoice.user.state === "Telangana") {
            invoice.roundoff = round("round", invoice.totalprice - 2 * invoice.cgst - invoice.totalpricebgst, 2)
        } else {
            invoice.roundoff = round("round", invoice.totalprice - invoice.gst - invoice.totalpricebgst, 2)
        }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwardinvoices', invoice, config)
        .then((res) => {
            fileDownload(res.data, 'invoice.pdf')
        })
    }

    return (
        <div className="hp20 invoice-creation txt-light-grey">
        {preview ? (
            <div className="invoice-preview">
                <h2>Preview</h2>
                <table className="border" cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <th>S.No.</th>
                            <th colSpan="9">Product Description</th>
                            <th colSpan="2">HSN / SAC CODE</th>
                            <th>QTY</th>
                            <th colSpan="3">Unit Price<br/>(Excl. GST)</th>
                            <th colSpan="3">Tax Rate</th>
                            <th colSpan="3">Total Amount</th>
                        </tr>
                        {invoicedata.builds.map((build, i) =>
                        <tr>
                            <td></td>
                            <td colSpan="9">
                            {build.buildType === "custom" ? (
                                <>
                                <b>{build.title}</b><br/>
                                {build.components.map((comp) =>
                                    comp.title !== "" && 
                                    <>
                                    {comp.title}<br/>
                                    </>
                                )}
                                </>
                            ) : (
                                build.title
                            )}
                            </td>
                            <td colSpan="2">{build.hsncode}</td>
                            <td>{build.quantity}</td>
                            <td colSpan="3">{build.pricebgst}</td>
                            <td colSpan="3">
                            {invoicedata.billadd.state === "Telangana" ? (
                            <>
                            CGST @ 9%<br/>SGST @ 9%
                            </>
                            ) : (
                                "IGST @ 18%"
                            )}
                            </td>
                            <td className="text_right" colSpan="3">{build.totalpricebgst}</td>
                        </tr>
                        )}
                        {invoicedata.products.map((prod, i) =>
                        <tr key={i}>
                            <td></td>
                            <td colSpan="9">{prod.title}</td>
                            <td colSpan="2">{prod.hsncode}</td>
                            <td>{prod.quantity}</td>
                            <td colSpan="3">{prod.pricebgst}</td>
                            <td colSpan="3">
                            {invoicedata.billadd.state === "Telangana" ? (
                            <>
                            CGST @ 9%<br/>SGST @ 9%
                            </>
                            ) : (
                                "IGST @ 18%"
                            )}
                            </td>
                            <td className="text_right" colSpan="3">{prod.totalpricebgst}</td>
                        </tr>
                        )}
                        <tr>
                            <td className="text_right" colSpan="19"><b>Amount Taxable</b></td>
                            <td className="text_right" colSpan="3"><b>Rs.{invoicedata.totalpricebgst}</b></td>
                        </tr>
                        <tr>
                            <td className="text_right" colSpan="19">
                                Tax Summary<br/>
                                {invoicedata.billadd.state === "Telangana" ? (
                                <>
                                CGST @ 9%<br/>SGST @ 9%
                                </>
                                ) : (
                                "IGST @ 18%"
                                )}
                            </td>
                            <td className="text_right" colSpan="3">
                                &nbsp;<br/>
                                {invoicedata.billadd.state === "Telangana" ? (
                                <>
                                Rs.{invoicedata.cgst }<br/>
                                Rs.{invoicedata.cgst }
                                </>
                                ) : (
                                    invoicedata.gst
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="text_right" colSpan="19">Round off</td>
                            <td className="text_right" colSpan="3">{invoicedata.roundoff}</td>
                        </tr>
                        <tr>
                            <td className="text_right" colSpan="19"><b>Grand Total</b></td>
                            <td className="text_right" colSpan="3"><b>Rs.{invoicedata.totalprice}</b></td>
                        </tr>
                    </tbody>
                </table>
                <ul className="btns">
                    <li>
                        <button onClick={invoiceHandler}>Generate Invoice</button>
                    </li>
                    <li>
                        <button onClick={() => setpreview(false)}>Back</button>
                    </li>
                </ul>
            </div>
        ) : (
            invoicedata !== null &&
            <div className="invoice-wrap">
                {invoicedata.builds.length > 0 && 
                <div className='builds'>
                    <h3>Builds</h3>
                    {invoicedata.builds.map((build, i) =>
                    <table key={i} className="build border" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td>Title</td>
                                <td colSpan="5"><textarea className="x-large" value={build.title} onChange={(e) => updatebuild("build", i, "title", e)} /></td>
                            </tr>
                            <tr>
                                <td>Price</td>
                                <td><input type="number" className="large" value={build.price} onChange={(e) => updatebuild("build", i, "price", e, true)} /></td>
                                <td>Quantity</td>
                                <td><input className="small" min="1" type="number" value={build.quantity} onChange={(e) => updatebuild("build", i, "quantity", e, true)} /></td>
                                <td>Total Price</td>
                                <td>{build.totalprice}</td>
                            </tr>
                            <tr>
                                <td colSpan="6">Components</td>
                            </tr>
                            {build.components.map((comp, j) => 
                            comp.title !== "" &&
                            <tr key={j}>
                                <td colSpan="5">
                                    <textarea value={comp.title} className="x-large" min="1" max="100" onChange={(e) => updatebuild("comp", i, "title", e, false, j)} />
                                </td>
                                <td>
                                    <span className="prod-span" onClick={() => addcomp(i, j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                                    <span className="prod-span" onClick={() => removecomp(i, j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    )}
                </div>
                }
                {invoicedata.products.length > 0 &&
                <div className='products'>
                    <h3>Products</h3>
                    <table className="border" cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <td>Title</td>
                                <td>HSNCode</td>
                                <td>Price</td>
                                <td>Tax Rate</td>
                                <td>Quantity</td>
                                <td>Discount<br/>(Per Unit)</td>
                                <td>Total Price</td>
                            </tr>
                            {invoicedata.products.map((prod, i) =>
                            <tr key={i}>
                                <td>
                                    <textarea value={prod.title} type="textarea" className="x-large" onChange={(e) => updateprod(i, "title", e)}></textarea>
                                </td>
                                <td>
                                    <input value={prod.hsncode} onChange={(e) => updateprod(i, "hsncode", e)} />
                                </td>
                                <td>
                                    <input type="number" className="small" value={prod.price} onChange={(e) => updateprod(i, "price", e, true)} />
                                </td>
                                <td>
                                    <select value={prod.tax_rate} onChange={(e) => updateprod(i, "tax_rate", e, true)}>
                                        <option value={5}>5</option>
                                        <option value={12}>12</option>
                                        <option value={18}>18</option>
                                        <option value={28}>28</option>
                                    </select>
                                </td>
                                <td>
                                    <input value={prod.quantity} type="number" className="small" min="1" max="100" onChange={(e) => updateprod(i, "quantity", e, true)} />
                                </td>
                                <td>
                                    <input className='small' value={prod.discount} type="number" onChange={(e) => updateproddiscount(i, e)} />
                                </td>
                                <td>
                                    {prod.totalprice}
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                }
                <table className="border" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Taxable Amount</td>
                            <td>{invoicedata.totalpricebgst}</td>
                        </tr>
                        {invoicedata.billadd.state === "Telangana" ? (
                        <>
                        <tr>
                            <td>SGST</td>
                            <td>{invoicedata.cgst}</td>
                        </tr>
                        <tr>
                            <td>CGST</td>
                            <td>{invoicedata.cgst}</td>
                        </tr>
                        </>
                        ) : (
                        <tr>
                            <td>IGST</td>
                            <td>{invoicedata.gst}</td>
                        </tr>
                        )}
                        <tr>
                            <td>Round Off</td>
                            <td>{invoicedata.roundoff}</td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>{invoicedata.totalprice}</td>
                        </tr>
                        <tr>
                            <td>Total Discount</td>
                            <td>{invoicedata.totaldiscount}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="border" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Invoice Date</td>
                            <td>
                                <DatePicker selected={invoicedata.invoice_date === "" ? new Date() : new Date(invoicedata.invoice_date)} dateFormat='dd-MMM-yyyy' onChange={date => updateinvoicedate(date)} />
                            </td>
                        </tr>
                        <tr>
                            <td>Order ID</td>
                            <td><input className="order" value={invoicedata.orderid} onChange={(e) => updateinvoice("orderid", e)} /></td>
                        </tr>
                        <tr>
                            <td>PO/Ref No</td>
                            <td><input className="po_ref" value={invoicedata.po_ref} onChange={(e) => updateinvoice("po_ref", e)} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="name" value={invoicedata.user.name} onChange={(e) => updateuser("name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="phone" value={invoicedata.user.phone} onChange={(e) => updateuser("phone", e)} /></td>
                        </tr>
                        <tr>
                            <td colSpan="2">Billing Address</td>
                        </tr>
                        <tr>
                            <td>Company Name</td>
                            <td><input className="company" value={invoicedata.billadd.company} onChange={(e) => updateaddress("billadd", "company", e)} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="name" value={invoicedata.billadd.name} onChange={(e) => updateaddress("billadd", "name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="phone" value={invoicedata.billadd.phone} onChange={(e) => updateaddress("billadd", "phone", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line1</td>
                            <td><textarea className="address" value={invoicedata.billadd.addressline1} onChange={(e) => updateaddress("billadd", "addressline1", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line2</td>
                            <td><textarea className="address" value={invoicedata.billadd.addressline2} onChange={(e) => updateaddress("billadd", "addressline2", e)} /></td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td><input className="city" value={invoicedata.billadd.city} onChange={(e) => updateaddress("billadd", "city", e)} /></td>
                        </tr>
                        <tr>
                            <td>Pincode</td>
                            <td><input className="pincode" value={invoicedata.billadd.pincode} onChange={(e) => updateaddress("billadd", "pincode", e)} /></td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                <select className="state" value={invoicedata.billadd.state} onChange={(e) => updateaddress("billadd", "state", e)}>
                                    <option value="">Select State</option>
                                    {provinces.map((province, i) =>
                                    <option key={i} value={province["state"]}>{province["state"]}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>PAN</td>
                            <td><input className="pan" value={invoicedata.billadd.pan} onChange={(e) => updateaddress("billadd", "pan", e)} /></td>
                        </tr>
                        <tr>
                            <td>GSTIN</td>
                            <td><input className="gstin" value={invoicedata.billadd.gstin} onChange={(e) => updateaddress("billadd", "gstin", e)} /></td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                Shipping Address same as Billing Address 
                                <input type="checkbox" className='checkbox' onChange={(e) => updateaddressflag(!invoicedata.addressflag)} checked={invoicedata.addressflag} />
                            </td>
                        </tr>
                        {!invoicedata.addressflag &&
                        <>
                        <tr>
                            <td>Company Name</td>
                            <td><input className="company" value={invoicedata.billadd.company !== null ? invoicedata.billadd.company : ''} onChange={(e) => updateaddress("shpadd", "company", e)} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="name" value={invoicedata.shpadd.name} onChange={(e) => updateaddress("shpadd", "name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="phone" value={invoicedata.shpadd.phone} onChange={(e) => updateaddress("shpadd", "phone", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line1</td>
                            <td><textarea className="address" value={invoicedata.shpadd.addressline1} onChange={(e) => updateaddress("shpadd", "addressline1", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line2</td>
                            <td><textarea className="address" value={invoicedata.shpadd.addressline2} onChange={(e) => updateaddress("shpadd", "addressline2", e)} /></td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td><input className="city" value={invoicedata.shpadd.city} onChange={(e) => updateaddress("shpadd", "city", e)} /></td>
                        </tr>
                        <tr>
                            <td>Pincode</td>
                            <td><input className="pincode" value={invoicedata.shpadd.pincode} onChange={(e) => updateaddress("shpadd", "pincode", e)} /></td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                <select className="state" value={invoicedata.shpadd.state} onChange={(e) => updateaddress("shpadd", "state", e)}>
                                    <option value="">Select State</option>
                                    {provinces.map((province, i) =>
                                    <option key={i} value={province["state"]}>{province["state"]}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>PAN</td>
                            <td><input className="pan" value={invoicedata.shpadd.pan} onChange={(e) => updateaddress("shpadd", "pan", e)} /></td>
                        </tr>
                        <tr>
                            <td>GSTIN</td>
                            <td><input className="gstin" value={invoicedata.shpadd.gstin} onChange={(e) => updateaddress("shpadd", "gstin", e)} /></td>
                        </tr>
                        </>
                        }
                        <tr>
                            <td colSpan="2">
                                <ul className='btns'>
                                    <li>
                                        <button onClick={() => setpreview(true)}>Preview Invoice</button>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {Object.keys(errmsg).map((err) =>
                    <>{errmsg[err] !== "" && <p>{errmsg[err]}</p>}</>
                )}
            </div>
        )}
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps)(OfflineOrderInvoice)