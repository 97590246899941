import '../styles/response.css'

const ResponseBox = (props) => {

  return (
    <div> 
        <div className="inner-wrapper">
            <p>{props.msg}</p>
            <ul className='no_bullets'>
                <li>
                    <button className ="del_btn red" onClick={() => props.okhandler()}>OK</button>
                </li>
            </ul>
        </div> 
    </div>
  )
}

export default ResponseBox