import React, {useState, useEffect} from 'react' 
import axios from 'axios'
import Select from 'react-select'
import { connect } from 'react-redux'
import '../styles/editablebuild.css'
import { useHistory } from 'react-router-dom'

const EditableBuild = ({ prod, products: { presetData }, user:{ userDetails } }) => {

    const [prodData, setprodData] = useState(null)
    const [newprodData, setnewprodData] = useState(null)
    const [editFlag, seteditFlag] = useState(false)
    const [variations, setvariations] = useState(null)
    let history = useHistory();

    useEffect(() => {
        if(userDetails.accesslevel.pre_builts_finder === "NA"){
            history.push('/unauthorized')
            return;
        }
        setprodData(prod)
        setnewprodData(prod)
    }, [prod])

    const generateCombinations = (vars) => {
        if (vars.length === 0) {
            return []
        } else if (vars.length === 1) {
            return vars[0]
        } else {
            var variations = []
            var tempvar = generateCombinations(vars.slice(1))
            for (var comp in tempvar) {
                for (var i = 0; i < vars[0].length; i++) {
                    variations.push(vars[0][i] + " + " + tempvar[comp])
                }
            }
            return variations
        }
    }

    const changepresets = (e, key) => {
        let tempprod = {...newprodData}
        let tempvariations = []
        tempprod.presets[key] = (Array.isArray(e) ? e.map(ps => ps.value) : [])
        let vars = []
        for (let [comp, presets] of Object.entries(tempprod.presets)) {
            let varn = []
            if (presets.length > 1) {
                for (let item of presets) {
                    if (presetData !== null) {
                        varn.push(presetData.filter(psgrp => psgrp.type === comp)[0].list.filter(ps => ps.presetid === item)[0].short_title)
                    }
                }
                vars.push(varn)
            }
        }
        tempvariations = generateCombinations(vars)
        setvariations(tempvariations)
        setnewprodData(tempprod)
    }

    const compOptions = (type) => {
        const tempArray = []
        presetData !== null && presetData.filter(ps => ps.type === type)[0]["list"].map(item => {
            return (
                tempArray.push({value: item.presetid, label: item.kuro_title})
            )
        })
        return tempArray
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        axios.post(process.env.REACT_APP_KG_API_URL + 'kuroadmin/prebuilds', newprodData, config)
    }

    const updateeditFlag = () => {
        seteditFlag(true)
    }

    const cancelHandler = () => {
        seteditFlag(false)
    }

    return (
        <div className="build prod-list">
            {prodData !== null &&
            <div className='background'>
                { editFlag ? (
                <div>
                    <table className="border even_odd" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td>Processor</td>
                                <td colSpan="2">
                                    <Select classNamePrefix="kuro-search-select" options={compOptions("cpu")} isMulti onChange={(e) => changepresets(e, "cpu")} defaultValue={(newprodData.presets.cpu && presetData !== null) && newprodData.presets.cpu.map(ps => {return ({value: ps, label: presetData.filter(item => item.type === "cpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title})})} placeholder="Select Component" className="react-select-container"/>
                                </td>
                                <td>Power Supply Unit</td>
                                <td colSpan="2">
                                    <Select classNamePrefix="kuro-search-select" options={compOptions("psu")} isMulti onChange={(e) => changepresets(e, "psu")} defaultValue={(newprodData.presets.psu && presetData !== null) && newprodData.presets.psu.map(ps => {return ({value: ps, label: presetData.filter(item => item.type === "psu")[0].list.filter(item => item.presetid === ps)[0].kuro_title})})} placeholder="Select Component" className="react-select-container"/>
                                </td>
                                <td>Case</td>
                                <td colSpan="2">
                                    <Select classNamePrefix="kuro-search-select" options={compOptions("tower")} isMulti onChange={(e) => changepresets(e, "tower")} defaultValue={(newprodData.presets.tower && presetData !== null) && newprodData.presets.tower.map(ps => {return ({value: ps, label: presetData.filter(item => item.type === "tower")[0].list.filter(item => item.presetid === ps)[0].kuro_title})})} placeholder="Select Component" className="react-select-container"/>
                                </td>
                            </tr>
                            <tr>
                                <td>Motherboard</td>
                                <td colSpan="2">
                                    <Select classNamePrefix="kuro-search-select" options={compOptions("mob")} isMulti onChange={(e) => changepresets(e, "mob")} defaultValue={(newprodData.presets.mob && presetData !== null) && newprodData.presets.mob.map(ps => {return ({value: ps, label: presetData.filter(item => item.type === "mob")[0].list.filter(item => item.presetid === ps)[0].kuro_title})})} placeholder="Select Component" className="react-select-container"/>
                                </td>
                                <td>SSD</td>
                                <td colSpan="2">
                                    <Select classNamePrefix="kuro-search-select" options={compOptions("ssd")} isMulti onChange={(e) => changepresets(e, "ssd")} defaultValue={(newprodData.presets.ssd && presetData !== null) && newprodData.presets.ssd.map(ps => {return ({value: ps, label: presetData.filter(item => item.type === "ssd")[0].list.filter(item => item.presetid === ps)[0].kuro_title})})} placeholder="Select Component" className="react-select-container"/>
                                </td>
                                <td>Fans</td>
                                <td colSpan="2">
                                    <Select classNamePrefix="kuro-search-select" options={compOptions("fans")} isMulti onChange={(e) => changepresets(e, "fans")} defaultValue={(newprodData.presets.fans && presetData !== null) && newprodData.presets.fans.map(ps => {return ({value: ps, label: presetData.filter(item => item.type === "fans")[0].list.filter(item => item.presetid === ps)[0].kuro_title})})} placeholder="Select Component" className="react-select-container"/>
                                </td>
                            </tr>
                            <tr>
                                <td>Graphics Card</td>
                                <td colSpan="2">
                                    <Select classNamePrefix="kuro-search-select" options={compOptions("gpu")} isMulti onChange={(e) => changepresets(e, "gpu")} defaultValue={(newprodData.presets.gpu && presetData !== null) && newprodData.presets.gpu.map(ps => {return ({value: ps, label: presetData.filter(item => item.type === "gpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title})})} placeholder="Select Component" className="react-select-container"/>
                                </td>
                                <td>HDD</td>
                                <td colSpan="2">
                                    <Select classNamePrefix="kuro-search-select" options={compOptions("hdd")} isMulti onChange={(e) => changepresets(e, "hdd")} defaultValue={(newprodData.presets.hdd && presetData !== null) && newprodData.presets.hdd.map(ps => {return ({value: ps, label: presetData.filter(item => item.type === "hdd")[0].list.filter(item => item.presetid === ps)[0].kuro_title})})} placeholder="Select Component" className="react-select-container"/>
                                </td>
                                <td>WiFi</td>
                                <td colSpan="2">
                                    <Select classNamePrefix="kuro-search-select" options={compOptions("wifi")} isMulti onChange={(e) => changepresets(e, "wifi")} defaultValue={(newprodData.presets.wifi && presetData !== null) && newprodData.presets.wifi.map(ps => {return ({value: ps, label: presetData.filter(item => item.type === "wifi")[0].list.filter(item => item.presetid === ps)[0].kuro_title})})} placeholder="Select Component" className="react-select-container"/>
                                </td>
                            </tr>
                            <tr>
                                <td>RAM</td>
                                <td colSpan="2">
                                    <Select classNamePrefix="kuro-search-select" options={compOptions("ram")} isMulti onChange={(e) => changepresets(e, "ram")} defaultValue={(newprodData.presets.ram && presetData !== null) && newprodData.presets.ram.map(ps => {return ({value: ps, label: presetData.filter(item => item.type === "ram")[0].list.filter(item => item.presetid === ps)[0].kuro_title})})} placeholder="Select Component" className="react-select-container"/>
                                </td>
                                <td>Cooler</td>
                                <td colSpan="2">
                                    <Select classNamePrefix="kuro-search-select" options={compOptions("cooler")} isMulti onChange={(e) => changepresets(e, "cooler")} defaultValue={(newprodData.presets.cooler && presetData !== null) && newprodData.presets.cooler.map(ps => {return ({value: ps, label: presetData.filter(item => item.type === "cooler")[0].list.filter(item => item.presetid === ps)[0].kuro_title})})} placeholder="Select Component" className="react-select-container"/>
                                </td>
                                <td>Operating System</td>
                                <td colSpan="2">
                                    <Select classNamePrefix="kuro-search-select" options={compOptions("os")} isMulti onChange={(e) => changepresets(e, "os")} defaultValue={(newprodData.presets.os && presetData !== null) && newprodData.presets.os.map(ps => {return ({value: ps, label: presetData.filter(item => item.type === "os")[0].list.filter(item => item.presetid === ps)[0].kuro_title})})} placeholder="Select Component" className="react-select-container"/>
                                </td>
                            </tr>
                            <tr>
                                <td>Build Fees</td>
                                <td colSpan="2">
                                    <Select classNamePrefix="kuro-search-select" options={compOptions("build_fees")} isMulti onChange={(e) => changepresets(e, "build_fees")} defaultValue={(newprodData.presets.build_fees && presetData !== null) && newprodData.presets.build_fees.map(ps => {return ({value: ps, label: presetData.filter(item => item.type === "build_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title})})} placeholder="Select Component" className="react-select-container"/>
                                </td>
                                <td>Shipping Fees</td>
                                <td colSpan="2">
                                    <Select classNamePrefix="kuro-search-select" options={compOptions("shp_fees")} isMulti onChange={(e) => changepresets(e, "shp_fees")} defaultValue={(newprodData.presets.shp_fees && presetData !== null) && newprodData.presets.shp_fees.map(ps => {return ({value: ps, label: presetData.filter(item => item.type === "shp_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title})})} placeholder="Select Component" className="react-select-container"/>
                                </td>
                                <td>Warranty</td>
                                <td colSpan="2">
                                    <Select classNamePrefix="kuro-search-select" options={compOptions("warranty")} isMulti onChange={(e) => changepresets(e, "warranty")} defaultValue={(newprodData.presets.warranty && presetData !== null) && newprodData.presets.warranty.map(ps => {return ({value: ps, label: presetData.filter(item => item.type === "warranty")[0].list.filter(item => item.presetid === ps)[0].kuro_title})})} placeholder="Select Component" className="react-select-container"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h2 className='txt-light'>Variations</h2>
                    <div className='variation txt-light'>
                        {variations !== null && variations.length > 1 && variations.map((comp, i) =>
                            <div key={i} className='variation box'>
                                {comp}
                            </div>
                        )}
                    </div>
                    <ul className='btns'>
                        <li><button id="submit" onClick={submitHandler}>Update Build</button></li>
                        <li><button id="submit" onClick={cancelHandler}>Cancel</button></li>
                    </ul>
                </div>
                ) : (
                <div>
                    <table className="border even_odd" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td>Processor</td>
                                <td colSpan="2">
                                    {presetData !== null && prodData.presets.cpu.length > 0 && prodData.presets.cpu.map(ps => {return (<>{presetData.filter(psgrp => psgrp.type === "cpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br/></>)})}
                                </td>
                                <td>Power Supply Unit</td>
                                <td colSpan="2">
                                    {presetData !== null && prodData.presets.psu.length > 0 && prodData.presets.psu.map(ps => {return (<>{presetData.filter(psgrp => psgrp.type === "psu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br/></>)})}
                                </td>
                                <td>Case</td>
                                <td colSpan="2">
                                    {presetData !== null && prodData.presets.tower.length > 0 && prodData.presets.tower.map(ps => {return (<>{presetData.filter(psgrp => psgrp.type === "tower")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br/></>)})}
                                </td>
                            </tr>
                            <tr>
                                <td>Motherboard</td>
                                <td colSpan="2">
                                    {presetData !== null && prodData.presets.mob.length > 0 && prodData.presets.mob.map(ps => {return (<>{presetData.filter(psgrp => psgrp.type === "mob")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br/></>)})}
                                </td>
                                <td>SSD</td>
                                <td colSpan="2">
                                    {presetData !== null && prodData.presets.ssd.length > 0 && prodData.presets.ssd.map(ps => {return (<>{presetData.filter(psgrp => psgrp.type === "ssd")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br/></>)})}
                                </td>
                                <td>Fans</td>
                                <td colSpan="2">
                                    {presetData !== null && prodData.presets.fans.length > 0 && prodData.presets.fans.map(ps => {return (<>{presetData.filter(psgrp => psgrp.type === "fans")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br/></>)})}
                                </td>
                            </tr>
                            <tr>
                                <td>Graphics Card</td>
                                <td colSpan="2">
                                    {presetData !== null && prodData.presets.gpu.length > 0 && prodData.presets.gpu.map(ps => {return (<>{presetData.filter(psgrp => psgrp.type === "gpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br/></>)})}
                                </td>
                                <td>HDD</td>
                                <td colSpan="2">
                                    {presetData !== null && prodData.presets.hdd.length > 0 && prodData.presets.hdd.map(ps => {return (<>{presetData.filter(psgrp => psgrp.type === "hdd")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br/></>)})}
                                </td>
                                <td>WiFi</td>
                                <td colSpan="2">
                                    {presetData !== null && prodData.presets.wifi.length > 0 && prodData.presets.wifi.map(ps => {return (<>{presetData.filter(psgrp => psgrp.type === "wifi")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br/></>)})}
                                </td>
                            </tr>
                            <tr>
                                <td>RAM</td>
                                <td colSpan="2">
                                    {presetData !== null && prodData.presets.ram.length > 0 && prodData.presets.ram.map(ps => {return (<>{presetData.filter(psgrp => psgrp.type === "ram")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br/></>)})}
                                </td>
                                <td>Cooler</td>
                                <td colSpan="2">
                                    {presetData !== null && prodData.presets.cooler.length > 0 && prodData.presets.cooler.map(ps => {return (<>{presetData.filter(psgrp => psgrp.type === "cooler")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br/></>)})}
                                </td>
                                <td>Operating System</td>
                                <td colSpan="2">
                                    {presetData !== null && prodData.presets.os.length > 0 && prodData.presets.os.map(ps => {return (<>{presetData.filter(psgrp => psgrp.type === "os")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br/></>)})}
                                </td>
                            </tr>
                            <tr>
                                <td>Build Fees</td>
                                <td colSpan="2">
                                    {presetData !== null && prodData.presets.build_fees.length > 0 && prodData.presets.build_fees.map(ps => {return (<>{presetData.filter(psgrp => psgrp.type === "build_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br/></>)})}
                                </td>
                                <td>Shipping Fees</td>
                                <td colSpan="2">
                                    {presetData !== null && prodData.presets.shp_fees.length > 0 && prodData.presets.shp_fees.map(ps => {return (<>{presetData.filter(psgrp => psgrp.type === "shp_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br/></>)})}
                                </td>
                                <td>Warranty</td>
                                <td colSpan="2">
                                    {presetData !== null && prodData.presets.warranty.length > 0 && prodData.presets.warranty.map(ps => {return (<>{presetData.filter(psgrp => psgrp.type === "warranty")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br/></>)})}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {userDetails.accesslevel.pre_builts_finder === "edit" ?<button onClick={updateeditFlag}>Edit</button>:null}
                </div>
                )}
            </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    user: state.user
})

export default connect(mapStateToProps)(EditableBuild)