import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import axios from 'axios'
import moment from 'moment-timezone'
import KuroLink from '../components/common/KuroLink'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import '../styles/estimate.css'

const OutwardInvoice = ({ match, user: {token, userDetails}, products: { compData, monitorData, accessData, networkData, extDeviceData }, admin: {provinces}}) => {
    const access=userDetails.access;
    const history = useHistory();
    const { params: { invoice_no, fin_year } } = match
    const [invoiceData, setinvoiceData] = useState(null)
    const [invoiceInfo, setinvoiceInfo] = useState(null)
    const [editFlag, seteditFlag] = useState(false)
    const [compMakers, setcompMakers] = useState([])
    const [monitorMakers, setmonitorMakers] = useState([])
    const [accessMakers, setaccessMakers] = useState([])
    const [networkMakers, setnetworkMakers] = useState([])
    const [extMakers, setextMakers] = useState([])
    const [errmsg, seterrmsg] = useState({
        "state": "",
        "pan": "",
        "gstin": ""
    })

    useEffect(() => {
        if(access==="NA"){
            history.push("/unauthorized")
            return;
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwardinvoices?invoice_no=' + invoice_no + '&fin_year=' + fin_year, config).then(res => {
            setinvoiceInfo(res.data[0])
            setinvoiceData(res.data[0])
        }).catch((err)=>{
            if(err.response.status===401){
                history.push("/unauthorized")
            }
        })
    }, [])

    useEffect(() => {
        if (compData != null) {
            setcompMakers([...new Set(compData.map(item => item.maker))])
        }
        if (monitorData != null) {
            setmonitorMakers([...new Set(monitorData.map(item => item.maker))])
        }
        if (accessData != null) {
            setaccessMakers([...new Set(accessData.map(item => item.maker))])
        }
        if (networkData != null) {
            setnetworkMakers([...new Set(networkData.map(item => item.maker))])
        }
        if (extDeviceData != null) {
            setextMakers([...new Set(extDeviceData.map(item => item.maker))])
        }
    }, [compData, monitorData, accessData, networkData, extDeviceData])

    const round = (type, value, decimals = 0, even=false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updateroundoff = (tempinvoice) => {
        let roundoff = 0
        if (tempinvoice.billadd.state === "Telangana") {
            roundoff = ((tempinvoice.totalprice * 100) - (200 * tempinvoice.cgst) - (100 * tempinvoice.totalpricebgst))
        } else {
            roundoff = ((tempinvoice.totalprice * 100) - (100 * tempinvoice.gst) - (100 * tempinvoice.totalpricebgst))
        }
        tempinvoice.roundoff = round("round", Math.abs(roundoff) >= 1 ? roundoff / 100 : 0, 2)
        return tempinvoice
    }

    const updatetotal = (tempinvoice) => {
        tempinvoice.taxes = {}
        let totalprice = 0
        let totaldiscount = 0
        let totalpricebgst = 0
        let gst = 0
        let cgst = 0
        let subtotals = {
            "5": 0,
            "12": 0,
            "18": 0,
            "28": 0
        }

        for (let i=0; i<tempinvoice.builds.length; i++) {
            let btp = 0
            btp = tempinvoice.builds[i].totalprice
            totaldiscount += tempinvoice.builds[i].totaldiscount
            subtotals[tempinvoice.builds[i].tax_rate] += btp
            totalprice += btp
        }
        for (let i=0; i<tempinvoice.products.length; i++) {
            let ptp = 0
            ptp += tempinvoice.products[i].totalprice
            totaldiscount += tempinvoice.products[i].totaldiscount
            subtotals[tempinvoice.products[i].tax_rate] += ptp
            totalprice += ptp
        }
        for (const [key, value] of Object.entries(subtotals)) {
            let tpbg = 0
            let trgst = 0
            let trcgst = 0
            tpbg = round("round", (100 * value) / (100 + parseInt(key)), 2)
            totalpricebgst += tpbg
            trgst = round("round", (value * parseInt(key)) / (100 + parseInt(key)), 2)
            trcgst = round("round", (value * parseInt(key)) / (2 * (100 + parseInt(key))), 2)
            if (tempinvoice.billadd.state === "Telangana") {
                tempinvoice.taxes[key] = trcgst
            } else {
                tempinvoice.taxes[key] = trgst
            }
            gst += trgst
            cgst += trcgst
        }
        tempinvoice.totaldiscount = totaldiscount
        tempinvoice.totalprice = totalprice
        tempinvoice.totalpricebgst = totalpricebgst
        tempinvoice.gst = gst
        tempinvoice.cgst = cgst

        tempinvoice = updateroundoff(tempinvoice)
        return tempinvoice
    }

    const updateinvoicedate = (date) => {
        let tempinvoice = {...invoiceInfo}
        tempinvoice.invoice_date = (date == null) ? "" : date
        setinvoiceInfo(tempinvoice)
    }

    const updateuser = (key, e) => {
        let tempinvoice = {...invoiceInfo}
        let value = e.target.value
        if (key === "phone") {
            value = value.replaceAll("+91", "").replaceAll(/\D/g, "")
        }
        tempinvoice.user[key] = value
        setinvoiceInfo(tempinvoice)
    }

    const updatepangstin = (invoice) => {
        let tempmsg = {...errmsg}
        if (invoice.billadd.pan.length !== 10 || !(/[A-Z]{5}[0-9]{4}[A-Z]{1}/).test(invoice.billadd.pan)) {
            tempmsg["pan"] = "*** Please Enter Valid PAN number"
        } else {
            tempmsg["pan"] = ""
        }
        if (invoice.billadd.gstin !== "" && (invoice.billadd.gstin.length !== 15 || invoice.billadd.gstin.slice(2,12) !== invoice.billadd.pan || !(/^[0-9]*$/).test(invoice.billadd.gstin.slice(0,2)))) {
            tempmsg["gstin"] = "*** Please Enter Valid GSTIN number"
        } else {
            tempmsg["gstin"] = ""
        }
        seterrmsg(tempmsg)
    }

    const updateaddress = (key, subkey, e) => {
        let value = e.target.value
        let tempinvoice = {...invoiceInfo}
        if (subkey === "pincode") {
            if (value.length <= 6 && (/^[0-9]*$/).test(value)) {
                tempinvoice[key][subkey] = value
            }
        } else if (subkey === "pan" || subkey === "gstin") {
            if ((/^[A-Z0-9]*$/).test(value.toUpperCase())) {
                tempinvoice[key][subkey] = value.toUpperCase()
            }
            updatepangstin(tempinvoice)
        } else {
            tempinvoice[key][subkey] = value
        }
        setinvoiceInfo(tempinvoice)
    }

    const updatestate = (key, e) => {
        let tempinvoice = {...invoiceInfo}
        tempinvoice[key].state = e.value
        if (key === "billadd") {
            if (e.value === "") {
                seterrmsg(msg => ({...msg, state: "Please select the State"}))
            } else {
                seterrmsg(msg => msg.state === "")
            }
            tempinvoice = updateroundoff(tempinvoice)
        }
        setinvoiceInfo(tempinvoice)
    }

    const getselectedstate = (key) => {
        return {value: invoiceInfo[key].state, label: invoiceInfo[key].state}
    }

    const updateaddressflag = (value) => {
        let tempinvoice = {...invoiceInfo}
        tempinvoice["addressflag"] = value
        if (value) {
            delete tempinvoice["shpadd"]
        } else {
            tempinvoice["shpadd"] = {'company': '', 'name': '', 'phone': '', 'addressline1': '', 'addressline2': '', 'city': '', 'pincode': '', 'state': '', 'gstin': '', 'pan': ''}
        }
        setinvoiceInfo(tempinvoice)
    }

    const updatebuild = (e, bindex, key, num=false, cindex) => {
        let tempinvoice = {...invoiceInfo}
        if (key === "components") {
            tempinvoice.builds[bindex].components[cindex].title = e.target.value
        } else {
            tempinvoice.builds[bindex][key] = num ? Number(e.target.value) : e.target.value
        }
        if (key === "price" || key === "quantity") {
            let totalprice = parseFloat(tempinvoice.builds[bindex].price) * tempinvoice.builds[bindex].quantity
            tempinvoice.builds[bindex].pricebgst = round("round", parseFloat(tempinvoice.builds[bindex].price) / 1.18, 2)
            tempinvoice.builds[bindex].totalprice = totalprice
            tempinvoice.builds[bindex].totalpricebgst = round("round", totalprice / 1.18, 2)
            tempinvoice = updatetotal(tempinvoice)
        }
        setinvoiceInfo(tempinvoice)
    }

    const addcomp = (bindex, cindex) => {
        let tempinvoice = {...invoiceInfo}
        tempinvoice.builds[bindex].components.splice(cindex+1, 0, {'collection': 'components', 'type': '', 'maker': 0, 'productid': '', 'title': '', 'quantity': 1})
        setinvoiceInfo(tempinvoice)
    }

    const removecomp = (bindex, cindex) => {
        let tempinvoice = {...invoiceInfo}
        tempinvoice.builds[bindex].components.splice(cindex, 1)
        setinvoiceInfo(tempinvoice)
    }

    const addprod = (pindex) => {
        let tempinvoice = {...invoiceInfo}
        tempinvoice.products.splice(pindex+1, 0, {'collection': '', 'type': '', 'category': '', 'maker': '', 'productid': '', 'title': '', 'quantity': 1, 'prodprice': 0, 'discount': 0, 'pricebgst': 0, 'price': 0, 'totaldiscount': 0, 'totalpricebgst': 0, 'totalprice': 0, 'hsncode': '', 'tax_rate': 18})
        setinvoiceInfo(tempinvoice)
    }

    const removeprod = (pindex) => {
        let tempinvoice = {...invoiceInfo}
        tempinvoice.products.splice(pindex, 1)
        tempinvoice = updatetotal(tempinvoice)
        setinvoiceInfo(tempinvoice)
    }

    const getprodlabel = (prod) => {
        let label = ""

        if (prod.productid !== "") {
            if (prod.productid === "other") {
                label = "Other"
            }
            else if (prod.collection === 'monitors') {
                label = monitorData !== null && monitorData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).filter(item => item.productid === prod.productid)[0].title
            }
            else if (prod.collection === 'components') {
                label = compData !== null && compData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).filter(item => item.productid === prod.productid)[0].title
            }
            else if (prod.collection === 'accessories') {
                label = accessData !== null && accessData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).filter(item => item.productid === prod.productid)[0].title
            }
            else if (prod.collection === 'networking') {
                label = networkData !== null && networkData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).filter(item => item.productid === prod.productid)[0].title
            }
            else if (prod.collection === 'external') {
                label = extDeviceData !== null && extDeviceData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).filter(item => item.productid === prod.productid)[0].title
            }
        }

        return label
    }

    const prodtotal = (tempinvoice, pindex) => {
        if (tempinvoice.products.length > 0) {
            const quantity = tempinvoice.products[pindex].quantity
            const price = tempinvoice.products[pindex].prodprice - tempinvoice.products[pindex].discount
            const totalprice = price * quantity
            tempinvoice.products[pindex].pricebgst = round("round", price / (1 + (tempinvoice.products[pindex].tax_rate / 100)), 2)
            tempinvoice.products[pindex].price = price
            tempinvoice.products[pindex].totalpricebgst = round("round", totalprice / (1 + (tempinvoice.products[pindex].tax_rate / 100)), 2)
            tempinvoice.products[pindex].totalprice = totalprice
            tempinvoice = updatetotal(tempinvoice)
        }
        return tempinvoice
    }

    const updateprod = (pindex, key, e, num=false) => {
        let tempinvoice = {...invoiceInfo}
        tempinvoice.products[pindex].discount = 0
        tempinvoice.products[pindex].totaldiscount = 0
        tempinvoice.products[pindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "prodprice" || key === "quantity" || key === "tax_rate") {
            tempinvoice = prodtotal(tempinvoice, pindex)
        } else if (key !== "title") {
            tempinvoice.products[pindex].productid = ""
            tempinvoice.products[pindex].title = ""
        }
        setinvoiceInfo(tempinvoice)
    }

    const updateprodmodel = (pindex, e, collection) => {
        const prodid = e.value
        let tempinvoice = {...invoiceInfo}
        tempinvoice.products[pindex].productid = prodid
        tempinvoice.products[pindex].discount = 0
        tempinvoice.products[pindex].totaldiscount = 0
        let collData = compData
        if (collection === "monitors") {
            collData = monitorData
        } else if (collection === "accessories") {
            collData = accessData
        } else if (collection === "networking") {
            collData = networkData
        } else if (collection === "external") {
            collData = extDeviceData
        }
        if (e) {
            tempinvoice.products[pindex].title = (prodid === "other") ? "" : collData.filter(item => item.productid === prodid)[0].title
            let price = (prodid === "other") ? 0 : collData.filter(item => item.productid === prodid)[0].price.kuro_on
            tempinvoice.products[pindex].price = price
            tempinvoice.products[pindex].prodprice = price
        } else {
            tempinvoice.products[pindex].title = ''
            tempinvoice.products[pindex].productid = ''
            tempinvoice.products[pindex].price = 0
            tempinvoice.products[pindex].prodprice = 0
        }
        tempinvoice = prodtotal(tempinvoice, pindex)
        setinvoiceInfo(tempinvoice)
    }

    const updateproddiscount = (pindex, e) => {
        let tempinvoice = {...invoiceInfo}
        tempinvoice.products[pindex].discount = Number(e.target.value)
        tempinvoice.products[pindex].totaldiscount = tempinvoice.products[pindex].discount * tempinvoice.products[pindex].quantity
        tempinvoice = prodtotal(tempinvoice, pindex)
        setinvoiceInfo(tempinvoice)
    }

    const prodOptions = (prod) => {
        const tempArray = []
        if (prod.collection === 'components') {
            compData !== null && compData.filter(item => item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title})
                )
            })
        }
        if (prod.collection === 'monitors') {
            monitorData !== null && monitorData.filter(item => item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title})
                )
            })
        }
        if (prod.collection === 'accessories') {
            accessData !== null && accessData.filter(item => item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title})
                )
            })
        }
        if (prod.collection === 'networking') {
            networkData !== null && networkData.filter(item => item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title})
                )
            })
        }
        if (prod.collection === 'external') {
            extDeviceData !== null && extDeviceData.filter(item => item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title})
                )
            })
        }
        tempArray.push({ value: 'other', label: 'Other'})
        return tempArray
    }

    const updateeditFlag = (flag) => {
        let tempinvoice = {...invoiceInfo}
        if (!flag) {
            setinvoiceInfo(tempinvoice)
        }
        seteditFlag(flag)
    }

    var fileDownload = require('js-file-download')

    const downloadinvoice = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwardinvoices?fin_year=' + invoiceData.fin_year + '&invoice_no=' + invoiceData.invoice_no + "&download=true", config)
        .then((res) => {
            fileDownload(res.data, invoiceData.invoice_no + '.pdf')
        })
    }

    const disableScroll = (e) => {
        e.target.blur()
    }

    const updateInvoice = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let invData = invoiceInfo
        let invoice_no = invData.invoice_no
        let fin_year = invData.fin_year

        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwardinvoices?invoice_no=' + invoice_no + '&fin_year=' + fin_year, invData, config)
        .then((res) => {
            setinvoiceData(res.data[0])
            updateeditFlag(false)
        })
    }

    const stateOptions = provinces ? provinces.map(item => ({value:item.state, label: item.state})) : []; 

    return (
        <div className="invoice txt-light-grey">
            <h3>Sales Invoice</h3>
        {editFlag ? (
            <div className="edit-invoice">
                <table className="border even_odd" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Invoice No</td>
                            <td>Invoice Date</td>
                            <td>Customer Name</td>
                            <td>Mobile Number</td>
                            <td>Invoice Total</td>
                        </tr>
                        {invoiceInfo !== null &&
                        <tr>
                            <td>{invoiceInfo.invoice_no}</td>
                            <td><DatePicker value={moment(invoiceInfo.invoice_date).format('DD-MM-YYYY')} onChange={date => updateinvoicedate(date)}/></td>
                            <td><input value={invoiceInfo.user.name} onChange={(e) => updateuser("name", e)} /></td>
                            <td><input value={invoiceInfo.user.phone} onChange={(e) => updateuser("phone", e)} /></td>
                            <td>{invoiceInfo.totalprice}</td>
                        </tr>
                        }
                    </tbody>
                </table>
                {invoiceData !== null &&
                <div className='col_2'>
                    <table className="border even_odd" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    Shipping Address same as Billing Address
                                    <input type="checkbox" className='checkbox' onChange={(e) => updateaddressflag(!invoiceInfo.addressflag)} checked={invoiceInfo.addressflag} />
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Billing Address</th>
                            </tr>
                            <tr>
                                <td >Company Name</td>
                                <td><input value={invoiceInfo.billadd.company} onChange={(e) => updateaddress("billadd", "company", e)} className="input_width"/></td>
                            </tr>
                            <tr>
                                <td>Name</td>
                                <td><input value={invoiceInfo.billadd.name} onChange={(e) => updateaddress("billadd", "name", e)} className="input_width"/></td>
                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td><input value={invoiceInfo.billadd.phone} onChange={(e) => updateaddress("billadd", "phone", e)}className="input_width" /></td>
                            </tr>
                            <tr>
                                <td>Address Line1</td>
                                <td><textarea value={invoiceInfo.billadd.addressline1} onChange={(e) => updateaddress("billadd", "addressline1", e)}className="input_width" /></td>
                            </tr>
                            <tr>
                                <td>Address Line2</td>
                                <td><textarea value={invoiceInfo.billadd.addressline2}onChange={(e) => updateaddress("billadd", "addressline2", e)} className="input_width"/></td>
                            </tr>
                            <tr>
                                <td>City</td>
                                <td><input value={invoiceInfo.billadd.city} onChange={(e) => updateaddress("billadd", "city", e)} className="input_width"/></td>
                            </tr>
                            <tr>
                                <td>State</td>
                                <td>
                                <Select classNamePrefix="kuro-search-select" options={stateOptions} value={getselectedstate("billadd")} onChange={(e) => updatestate("billadd", e)} placeholder="Select State" className="react-select-container" />
                                </td>
                            </tr>
                            <tr>
                                <td>Pincode</td>
                                <td><input value={invoiceInfo.billadd.pincode} onChange={(e) => updateaddress("billadd", "pincode", e)} className="input_width"/></td>
                            </tr>
                            <tr>
                                <td>Pan</td>
                                <td><input value={invoiceInfo.billadd.pan} onChange={(e) => updateaddress("billadd", "pan", e)} className="input_width"/></td>
                            </tr>
                            <tr>
                                <td>GSTIN</td>
                                <td><input value={invoiceInfo.billadd.gstin} onChange={(e) => updateaddress("billadd", "gstin", e)} className="input_width"/></td>
                            </tr>
                            {!invoiceInfo.addressflag &&
                            <>
                            <tr>
                                <th colSpan={2}>Shipping Address</th>
                            </tr>
                            <tr>
                                <td>Company Name</td>
                                <td><input value={invoiceInfo.shpadd.company} onChange={(e) => updateaddress("shpadd", "company", e)} className="input_width"/></td>
                            </tr>
                            <tr>
                                <td>Name</td>
                                <td><input value={invoiceInfo.shpadd.name} onChange={(e) => updateaddress("shpadd", "name", e)} className="input_width"/></td>
                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td><input value={invoiceInfo.shpadd.phone} onChange={(e) => updateaddress("shpadd", "phone", e)} className="input_width"/></td>
                            </tr>
                            <tr>
                                <td>Address Line1</td>
                                <td><textarea value={invoiceInfo.shpadd.addressline1} onChange={(e) => updateaddress("shpadd", "addressline1", e)} className="input_width"/></td>
                            </tr>
                            <tr>
                                <td>Address Line2</td>
                                <td><textarea value={invoiceInfo.shpadd.addressline2} onChange={(e) => updateaddress("shpadd", "addressline2", e)} className="input_width"/></td>
                            </tr>
                            <tr>
                                <td>City</td>
                                <td><input value={invoiceInfo.shpadd.city} onChange={(e) => updateaddress("shpadd", "city", e)} className="input_width"/></td>
                            </tr>
                            <tr>
                                <td>State</td>
                                <td>
                                <Select classNamePrefix="kuro-search-select" options={stateOptions} value={getselectedstate("shpadd")} onChange={(e) => updatestate("shpadd", e)} placeholder="Select State" className="react-select-container" />
                                </td>
                            </tr>
                            <tr>
                                <td>Pincode</td>
                                <td><input value={invoiceInfo.shpadd.pincode} onChange={(e) => updateaddress("shpadd", "pincode", e)} className="input_width"/></td>
                            </tr>
                            <tr>
                                <td>PAN</td>
                                <td><input value={invoiceInfo.shpadd.pan} onChange={(e) => updateaddress("shpadd", "pan", e)} className="input_width"/></td>
                            </tr>
                            <tr>
                                <td>GSTIN</td>
                                <td><input value={invoiceInfo.shpadd.gstin} onChange={(e) => updateaddress("shpadd", "gstin", e)} className="input_width"/></td>
                            </tr>
                            </>
                            }
                        </tbody>
                    </table>
                </div>
                }
                {Object.keys(errmsg).map((err, i) =>
                    <>{errmsg[err] !== "" && <p key={i}>{errmsg[err]}</p>}</>
                )}
                {invoiceData.builds.map((build, i) =>
                <div key={i} className='prod-list'>
                    <h3>Builds</h3>
                    <table className="prod_details border" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td>Title</td>
                                <td colSpan="5"><textarea value={build.title} onChange={(e) => updatebuild(e, i, "title")} /></td>
                            </tr>
                            <tr>
                                <td>Price</td>
                                <td><input className="medium" onChange={(e) => updatebuild(e, i, "price", true)} value={build.price} /></td>
                                <td>Quantity</td>
                                <td><input type="number" min="1" value={build.quantity} onChange={(e) => updatebuild(e, i, "quantity", true)} /></td>
                                <td>Total Price</td>
                                <td>{build.totalprice}</td>
                            </tr>
                            <tr>
                                <td colSpan="6">Components</td>
                            </tr>
                            {build.components.map((comp, j) =>
                            <tr key={j}>
                                <td colSpan="5"><textarea className="title large" onChange={(e) => updatebuild(e, i, "components", false, j)} value={comp.title} /></td>
                                <td>
                                    <span className="prod-span" onClick={() => addcomp(i, j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                                    <span className="prod-span" onClick={() => removecomp(i, j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                )}
                {invoiceInfo !== null &&
                <>
                <div className="prod-list">
                    <h3>Peripherals</h3>
                    <ul className='btns'>
                        <li>
                            <button onClick={() => addprod(-1)}>Add Peripherals [+]</button>
                        </li>
                    </ul>
                    {invoiceInfo.products && invoiceInfo.products.length > 0 &&
                    <table className="border border" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td>Collection</td>
                                <td>Type</td>
                                <td>Category</td>
                                <td>Brand</td>
                                <td>Model</td>
                                <td>Title</td>
                                <td>HSNCode</td>
                                <td>Price</td>
                                <td>Tax Rate</td>
                                <td>Quantity</td>
                                <td>Discount<br/>(Per Unit)</td>
                                <td>Total Price</td>
                                <td></td>
                            </tr>
                            {invoiceInfo.products.map((prod, i) =>
                            <tr key={i}>
                                <td>
                                    <select value={prod.collection} onChange={(e) => updateprod(i, "collection", e)}>
                                        <option value="">Select Collection</option>
                                        <option value="components">Components</option>
                                        <option value="monitors">Monitors</option>
                                        <option value="accessories">Accessories</option>
                                        <option value="networking">Networking</option>
                                        <option value="external">External Devices</option>
                                    </select>
                                </td>
                                <td>
                                    <select value={prod.type} onChange={(e) => updateprod(i, "type", e)}>
                                        <option value="">Select Type</option>
                                    {prod.collection === "components" &&
                                    <>
                                        <option value="cpu">Processor</option>
                                        <option value="mob">Motherboard</option>
                                        <option value="ram">RAM</option>
                                        <option value="gpu">Graphics Card</option>
                                        <option value="psu">Power Supply</option>
                                        <option value="ssd">SSD</option>
                                        <option value="hdd">HDD</option>
                                        <option value="cooler">CPU Cooler</option>
                                        <option value="tower">Case</option>
                                        <option value="fan">Fan</option>
                                    </>
                                    }
                                    {prod.collection === "monitors" && <option value="monitor">Monitor</option>}
                                    {prod.collection === "accessories" &&
                                    <>
                                        <option value="keyboard">Keyboard</option>
                                        <option value="keyboard-accessories">Keyboard Accessories</option>
                                        <option value="mouse">Mouse</option>
                                        <option value="mouse-accessories">Mouse Accessories</option>
                                        <option value="kbdmouse">Keyboard Mouse Combo</option>
                                        <option value="streaming-gear">Streaming Gear</option>
                                    </>
                                    }
                                    {prod.collection === "networking" && <option value="wifi-card">WiFi Card</option>}
                                    {prod.collection === "external" &&
                                    <>
                                        <option value="hdd">HDD</option>
                                        <option value="expansion-card">Expansion Card</option>
                                    </>
                                    }
                                    </select>
                                </td>
                                <td>
                                    <select value={prod.category} onChange={(e) => updateprod(i, "category", e)}>
                                        <option value="">Select Category</option>
                                    {prod.type === "streaming-gear" &&
                                    <>
                                        <option value="capture-card">Capture Card</option>
                                        <option value="gaming-chair">Gaming Chair</option>
                                        <option value="streaming-mic">Streaming Mic</option>
                                        <option value="webcam">Webcam</option>
                                        <option value="gaming-headset">Gaming Headset</option>
                                    </>
                                    }
                                    </select>
                                </td>
                                <td>
                                    <select value={prod.maker} onChange={(e) => updateprod(i, "maker", e)}>
                                        <option value="">Select Brand</option>
                                    {prod.collection === "components" && compMakers !== null && compMakers.map((maker, j) =>
                                        compData.filter(item => item.type === prod.type && item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                    )}
                                    {prod.collection === "monitors" && monitorMakers !== null && monitorMakers.map((maker, j) =>
                                        monitorData.filter(item => item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                    )}
                                    {prod.collection === "accessories" && accessMakers !== null && accessMakers.map((maker, j) =>
                                        accessData.filter(item => (item.type === prod.type && item.type === "streaming-gear" && item.maker === maker && item.category === prod.category) || (item.type === prod.type && item.type !== "streaming-gear" && item.maker === maker)).length > 0 && <option key={j} value={maker}>{maker}</option>
                                    )}
                                    {prod.collection === "networking" && networkMakers !== null && networkMakers.map((maker, j) =>
                                        networkData.filter(item => item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                    )}
                                    {prod.collection === "external" && extMakers !== null && extMakers.map((maker, j) =>
                                        extDeviceData.filter(item => item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                    )}
                                    </select>
                                </td>
                                <td>
                                    <Select classNamePrefix="kuro-search-select" options={prodOptions(prod)} value={{ value: prod.productid, label: getprodlabel(prod) }} onChange={(e) => updateprodmodel(i, e, prod.collection)} placeholder="Select Model" className="react-select-container" />
                                </td>
                                <td>
                                    <textarea value={prod.title} type="textarea" className="large" onChange={(e) => updateprod(i, "title", e)}></textarea>
                                </td>
                                <td>
                                    <input className="small" value={prod.hsncode} onChange={(e) => updateprod(i, "hsncode", e)} />
                                </td>
                                <td>
                                    <input type="number" className="small" onWheel={disableScroll} value={prod.prodprice} onChange={(e) => updateprod(i, "prodprice", e, true)} />
                                </td>
                                <td>
                                    <select className="small" value={prod.tax_rate} onChange={(e) => updateprod(i, "tax_rate", e, true)}>
                                        <option value={5}>5</option>
                                        <option value={12}>12</option>
                                        <option value={18}>18</option>
                                        <option value={28}>28</option>
                                    </select>
                                </td>
                                <td>
                                    <input type="number" className="small" min="1" max="100" onWheel={disableScroll} value={prod.quantity} onChange={(e) => updateprod(i, "quantity", e, true)} />
                                </td>
                                <td>
                                    <input className='small' value={prod.discount} type="number" onWheel={disableScroll} onChange={(e) => updateproddiscount(i, e)} />
                                </td>
                                <td>
                                    {prod.totalprice}
                                </td>
                                <td>
                                    <span className="prod-span" onClick={() => removeprod(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span>
                                    <span className="prod-span" onClick={() => addprod(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    }
                </div>
                <table className='border'>
                    <tbody>
                        <tr>
                            <td>Taxable Amount</td>
                            <td>{invoiceInfo.totalpricebgst}</td>
                        </tr>
                        {invoiceInfo.billadd.state === "" || invoiceInfo.billadd.state === "Telangana" ? (
                        <>
                        <tr>
                            <td>CGST</td>
                            <td>{invoiceInfo.cgst}</td>
                        </tr>
                        <tr>
                            <td>SGST</td>
                            <td>{invoiceInfo.cgst}</td>
                        </tr>
                        </>
                        ) : (
                        <tr>
                            <td>IGST</td>
                            <td>{invoiceInfo.gst}</td>
                        </tr>
                        )}
                        <tr>
                            <td>Round Off</td>
                            <td>{invoiceInfo.roundoff}</td>
                        </tr>
                        <tr>
                            <td>Total Price</td>
                            <td>{invoiceInfo.totalprice}</td>
                        </tr>
                    </tbody>
                </table>
                <ul className="btns">
                    <li>
                        <button onClick={() => updateeditFlag(false)}>Cancel</button>
                    </li>
                    <li>
                        <button onClick={() => updateInvoice("update")}>Update Invoice</button>
                    </li>
                </ul>
                </>
                }
            </div>
        ) : (
            <div className="invoice_details">
                <table className="border even_odd" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr >
                            <td>Invoice No</td>
                            <td>Invoice Date</td>
                            <td>Customer Name</td>
                            <td>Mobile Number</td>
                            <td>Invoice Total</td>
                        </tr>
                        {invoiceData !== null &&
                        <tr>
                            <td>{invoiceData.invoice_no}</td>
                            <td>{moment(invoiceData.invoice_date).format('DD-MM-YYYY')}</td>
                            <td>{invoiceData.user.name}</td>
                            <td>{invoiceData.user.phone}</td>
                            <td>{invoiceData.totalprice}</td>
                        </tr>
                        }
                    </tbody>
                </table>
                {invoiceData !== null &&
                <table className="border even_odd" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td colSpan={2}>Billing Address</td>
                            <td colSpan={2}>Shipping Address</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                            {invoiceData.billadd.company &&
                            <>
                            {invoiceData.billadd.company}<br/>
                            </>
                            }
                            {invoiceData.billadd.name &&
                            <>
                            {invoiceData.billadd.name}
                            </>
                            }
                            {invoiceData.billadd.phone &&
                            <>
                            <br/>{invoiceData.billadd.phone}
                            </>}
                            {invoiceData.billadd.addressline1 &&
                            <>
                            <br/>{invoiceData.billadd.addressline1}
                            </>
                            }
                            {invoiceData.billadd.addressline2 &&
                            <>
                            ,&nbsp;{invoiceData.billadd.addressline2}
                            </>
                            }
                            {invoiceData.billadd.city &&
                            <>
                            <br/>{invoiceData.billadd.city}
                            </>
                            }
                            {invoiceData.billadd.state &&
                            <>
                            <br/>{invoiceData.billadd.state}
                            </>
                            }
                            {invoiceData.billadd.pincode &&
                            <>
                            &nbsp;- {invoiceData.billadd.pincode}
                            </>
                            }
                            {invoiceData.billadd.gstin &&
                            <>
                            <br/>GSTIN: {invoiceData.billadd.gstin}
                            </>}
                            {invoiceData.billadd.pan &&
                            <>
                            <br/>PAN: {invoiceData.billadd.pan}
                            </>
                            }
                            </td>
                        {invoiceData.addressflag ? (
                            <td colSpan={2}>
                            {invoiceData.billadd.company &&
                            <>
                            {invoiceData.billadd.company}<br/>
                            </>
                            }
                            {invoiceData.billadd.name &&
                            <>
                            {invoiceData.billadd.name}
                            </>
                            }
                            {invoiceData.billadd.phone &&
                            <>
                            <br/>{invoiceData.billadd.phone}
                            </>}
                            {invoiceData.billadd.addressline1 &&
                            <>
                            <br/>{invoiceData.billadd.addressline1}
                            </>
                            }
                            {invoiceData.billadd.addressline2 &&
                            <>
                            ,&nbsp;{invoiceData.billadd.addressline2}
                            </>
                            }
                            {invoiceData.billadd.city &&
                            <>
                            <br/>{invoiceData.billadd.city}
                            </>
                            }
                            {invoiceData.billadd.state &&
                            <>
                            <br/>{invoiceData.billadd.state}
                            </>
                            }
                            {invoiceData.billadd.pincode &&
                            <>
                            &nbsp;- {invoiceData.billadd.pincode}
                            </>
                            }
                            {invoiceData.billadd.gstin &&
                            <>
                            <br/>GSTIN: {invoiceData.billadd.gstin}
                            </>}
                            {invoiceData.billadd.pan &&
                            <>
                            <br/>PAN: {invoiceData.billadd.pan}
                            </>
                            }
                            </td>
                        ) : (
                            <td colSpan={2}>
                            {invoiceData.shpadd.company &&
                            <>
                            {invoiceData.shpadd.company}<br/>
                            </>
                            }
                            {invoiceData.shpadd.name &&
                            <>
                            {invoiceData.shpadd.name}
                            </>
                            }
                            {invoiceData.shpadd.phone &&
                            <>
                            <br/>{invoiceData.shpadd.phone}
                            </>}
                            {invoiceData.shpadd.addressline1 &&
                            <>
                            <br/>{invoiceData.shpadd.addressline1}
                            </>
                            }
                            {invoiceData.shpadd.addressline2 &&
                            <>
                            ,&nbsp;{invoiceData.shpadd.addressline2}
                            </>
                            }
                            {invoiceData.shpadd.city &&
                            <>
                            <br/>{invoiceData.shpadd.city}
                            </>
                            }
                            {invoiceData.shpadd.state &&
                            <>
                            <br/>{invoiceData.shpadd.state}
                            </>
                            }
                            {invoiceData.shpadd.pincode &&
                            <>
                            &nbsp;- {invoiceData.shpadd.pincode}
                            </>
                            }
                            {invoiceData.shpadd.gstin &&
                            <>
                            <br/>GSTIN: {invoiceData.shpadd.gstin}
                            </>}
                            {invoiceData.shpadd.pan &&
                            <>
                            <br/>PAN: {invoiceData.shpadd.pan}
                            </>
                            }
                            </td>
                        )}
                        </tr>
                    </tbody>
                </table>
                }
                <div className="build">
                    {invoiceData !== null &&
                    <>
                    {invoiceData.builds.map((build, i) =>
                    <div key={i} className="build-wrap prod-list">
                        <h3>Builds</h3>
                        <table className="border prod_details even_odd" cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td>Title</td>
                                    <td colSpan="5">{build.title}</td>
                                </tr>
                                <tr>
                                    <td>Price</td>
                                    <td>{build.price}</td>
                                    <td>Quantity</td>
                                    <td>{build.quantity}</td>
                                    <td>Total Price</td>
                                    <td>{build.totalprice}</td>
                                </tr>
                                <tr>
                                    <td colSpan="6">Components</td>
                                </tr>
                                {build.components.map((comp, j) =>
                                <tr key={j}>
                                    <td colSpan="6">{comp.title}</td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    )}
                    {invoiceData.products.length > 0 &&
                    <div className="products">
                        <h3>Peripherals</h3>
                        <div className='prod-list'>
                            <table className="border even_odd" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>Title</td>
                                        <td>Price</td>
                                        <td>Quantity</td>
                                        <td>Total Price</td>
                                    </tr>
                                {invoiceData.products.map((prod, j) =>
                                    <tr key={j}>
                                        <td>{prod.title}</td>
                                        <td>{prod.price}</td>
                                        <td>{prod.quantity}</td>
                                        <td>{prod.totalprice}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    }
                    <table className='border even_odd'>
                        <tbody>
                            <tr>
                                <td>Taxable Amount</td>
                                <td>{invoiceData.totalpricebgst}</td>
                            </tr>
                            {invoiceData.billadd.state === "" || invoiceData.billadd.state === "Telangana" ? (
                            <>
                            <tr>
                                <td>CGST</td>
                                <td>{invoiceData.cgst}</td>
                            </tr>
                            <tr>
                                <td>SGST</td>
                                <td>{invoiceData.cgst}</td>
                            </tr>
                            </>
                            ) : (
                            <tr>
                                <td>IGST</td>
                                <td>{invoiceData.gst}</td>
                            </tr>
                            )}
                            <tr>
                                <td>Round Off</td>
                                <td>{invoiceData.roundoff}</td>
                            </tr>
                            <tr>
                                <td>Total Price</td>
                                <td>{invoiceData.totalprice}</td>
                            </tr>
                        </tbody>
                    </table>
                    </>
                    }
                </div>
                <ul className='btns'>
                <li>
                        <button onClick={downloadinvoice}>Download Invoice</button>
                    </li>
                    {userDetails.access==="Super" && <li>
                        <button onClick={() => updateeditFlag(true)}>Edit Invoice</button>
                    </li> }          
                    <li>
                        <KuroLink to="/outward-invoices"><button>Back</button></KuroLink>
                    </li>
                </ul>
            </div>
        )}
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps)(OutwardInvoice)