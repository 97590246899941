import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import KuroLink from "../../components/common/KuroLink"
import { useHistory } from 'react-router-dom'


const Employees = ({ user: { token, userDetails } }) => {
    const history = useHistory()
    const [emps, setemps] = useState(null)

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        };
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/employees', config)
            .then((res) => {
                setemps(res.data)
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    history.push("/unauthorized")
                }
            })

    }, [])

    const updateStatus = (e, index) => {
        let tempemps = [...emps]
        tempemps[index]["user_status"] = e.target.value
        setemps(tempemps)
    }

    const updateHandler = (userid) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        const user_status = emps.filter(emp => emp.userid === userid)[0]["user_status"]

        const body = { 'user_status': user_status }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/empupdate?userid=' + userid, body, config)
            .then((res) => {
                setemps(res.data)
            })
    }

    return (
        <div className='employees txt-light-grey'>
            <h2>List of Employees</h2>
            <p></p>
            {(userDetails.accesslevel.employees === "write" || userDetails.accesslevel.employees === "edit") && <KuroLink to='/create-emp' classList='btn pri0 solid'>Add New Employee</KuroLink>}

            <p></p>
            <table className='border even_odd table_mob'>
                <tbody>
                    <tr>
                        <th>Emp ID</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Access</th>
                        <th>Last Logged In</th>
                        {(userDetails.accesslevel.employees === "write" || userDetails.accesslevel.employees === "edit") && <> <th>Status</th>
                            <th>Approval</th></>}
                    </tr>
                    {emps !== null && emps.map((emp, i) =>
                        <tr key={i}>
                            <td>{userDetails.accesslevel.profile === "edit" ? <KuroLink to={'/user/profile/' + emp.userid}>{emp.userid}</KuroLink> : emp.userid}</td>
                            <td>{emp.name}</td>
                            <td>{emp.phone}</td>
                            <td>{emp.email}</td>
                            <td>{emp.role}</td>
                            <td>{emp.access}</td>
                            <td>{emp.last_login}</td>
                            <td>
                                {(userDetails.accesslevel.employees === "write" || userDetails.accesslevel.employees === "edit") ? (
                                    <select onChange={(e) => updateStatus(e, i)} value={emp.user_status} >
                                        <option value="">Select the Status</option>
                                        <option value="Awaiting Approval">Awaiting Approval</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                ) : (
                                    emp.user_status
                                )}
                            </td>
                            {(userDetails.accesslevel.employees === "write" || userDetails.accesslevel.employees === "edit") &&
                                <td>
                                    <ul className='btns'>
                                        <li>
                                            <button onClick={() => updateHandler(emp.userid)}>Update</button>
                                        </li>
                                    </ul>
                                </td>
                            }
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(Employees)