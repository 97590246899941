import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import '../styles/table.css'

const Outward = ({ user: {token}, products: { compData, monitorData, accessData, networkData, extDeviceData }}) => {

    const [orderid, setorderid] = useState("")
    const [orderdate, setorderdate] = useState("")
    const [prods, setProds] = useState([])
    const [count, setCount] = useState(0)

    useEffect(() => {
        addrow(true)
    }, [])

    const addrow = (first) => {
        if (first) {
            setProds([{'id': count, 'collection': '', 'type': '', 'category': '', 'maker': '', 'productid': '', 'sr_no': ''}])
            setCount(1)
        } else {
            setProds(prevState => [...prevState, {'id': count, 'collection': '', 'type': '', 'category': '', 'maker': '', 'productid': '', 'sr_no': ''}])
            setCount(prevState => prevState + 1)
        }
    }

    const removerow = (id) => {
        const tempprods = prods.filter(item => item.id !== id)
        setProds(tempprods)
    }

    const updateprods = (id, key, e, num) => {
        const index = prods.findIndex(element => element.id === id)
        const tempprods = [...prods]
        tempprods[index][key] = num ? Number(e.target.value) : e.target.value
        setProds(tempprods)
    }

    const updateorderdate = (date) => {
        setorderdate(date)
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        const body = JSON.stringify({ orderid, orderdate, prods })
        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/outward', body, config)
        setProds([])
    }

    return (
        <div className="outward entry mx-width txt-light-grey">
            <h2>Products</h2>
            <table cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <th>Order Id</th>
                        <th>Order Date</th>
                    </tr>
                    <tr>
                        <td>
                            <input value={orderid} onChange={(e) => setorderid(e.target.value)} />
                        </td>
                        <td>
                            <DatePicker className="medium" dateFormat='dd-MMM-yyyy' selected={orderdate} onChange={date => updateorderdate(date)} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className="border" cellSpacing="0" cellPadding="0">
                <tbody>
                    <tr>
                        <th>Collection</th>
                        <th>Type</th>
                        <th>Category</th>
                        <th>Brand</th>
                        <th>Model</th>
                        <th>Serial No.</th>
                        <th></th>
                    </tr>
                {prods.map((prod, i) =>
                    <tr key={i}>
                        <td>
                            <select value={prod.collection} onChange={(e) => updateprods(prod.id, "collection", e)}>
                                <option value="">Select Collection</option>
                                <option value="components">Components</option>
                                <option value="monitors">Monitors</option>
                                <option value="accessories">Accessories</option>
                                <option value="networking">Networking</option>
                                <option value="external">External Devices</option>
                            </select>
                        </td>
                        <td>
                            <select value={prod.type} onChange={(e) => updateprods(prod.id, "type", e)}>
                                <option value="">Select Type</option>
                            {prod.collection === "components" &&
                            <>
                                <option value="cpu">Processor</option>
                                <option value="mob">Motherboard</option>
                                <option value="ram">RAM</option>
                                <option value="gpu">Graphics Card</option>
                                <option value="psu">Power Supply</option>
                                <option value="ssd">SSD</option>
                                <option value="hdd">HDD</option>
                                <option value="cooler">CPU Cooler</option>
                                <option value="tower">Case</option>
                                <option value="fan">Fan</option>
                            </>
                            }
                            {prod.collection === "monitors" &&
                                <option value="monitor">Monitor</option>
                            }
                            {prod.collection === "accessories" &&
                            <>
                                <option value="keyboard">Keyboard</option>
                                <option value="keyboard-accessories">Keyboard Accessories</option>
                                <option value="mouse">Mouse</option>
                                <option value="mouse-accessories">Mouse Accessories</option>
                                <option value="kbdmouse">Keyboard Mouse Combo</option>
                                <option value="streaming-gear">Streaming Gear</option>
                            </>
                            }
                            {prod.collection === "networking" &&
                                <option value="wifi-card">Wifi Card</option>
                            }
                            {prod.collection === "external" &&
                                <option value="hdd">Hard Drive</option>
                            }
                            </select>
                        </td>
                        <td>
                            <select>
                                <option value="">Select Category</option>
                            {prod.type === "streaming-gear" &&
                                <>
                                <option value="capture-card">Capture Card</option>
                                <option value="gaming-chair">Gaming Chair</option>
                                <option value="streaming-mic">Streaming Mic</option>
                                <option value="webcam">Webcam</option>
                                <option value="gaming-headset">Gaming Headset</option>
                                </>
                            }
                            </select>
                        </td>
                        <td>
                            <select value={prod.maker} onChange={(e) => updateprods(prod.id, "maker", e)}>
                                <option value="">Select Brand</option>
                            {compData !== null && prod.collection === "components" && [...new Set(compData.map(item => item.maker))] !== null && [...new Set(compData.map(item => item.maker))].map((maker, j) =>
                                compData.filter(item => item.type === prod.type && item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                            )}
                            {monitorData !== null && prod.collection === "monitors" && [...new Set(monitorData.map(item => item.maker))] !== null && [...new Set(monitorData.map(item => item.maker))].map((maker, j) =>
                                monitorData.filter(item => item.type === prod.type && item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                            )}
                            {accessData !== null && prod.collection === "accessories" && [...new Set(accessData.map(item => item.maker))] !== null && [...new Set(accessData.map(item => item.maker))].map((maker, j) =>
                                accessData.filter(item => item.type === prod.type && item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                            )}
                            {networkData !== null && prod.collection === "networking" && [...new Set(networkData.map(item => item.maker))] !== null && [...new Set(networkData.map(item => item.maker))].map((maker, j) =>
                                networkData.filter(item => item.type === prod.type && item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                            )}
                            {extDeviceData !== null && prod.collection === "external" && [...new Set(extDeviceData.map(item => item.maker))] !== null && [...new Set(extDeviceData.map(item => item.maker))].map((maker, j) =>
                                extDeviceData.filter(item => item.type === prod.type && item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                            )}
                            </select>
                        </td>
                        <td>
                            <select className="large" title={prod.productid && compData !== null && compData.filter(item => item.productid === prod.productid)[0]["title"]} value={prod.productid} onChange={(e) => updateprods(prod.id, "productid", e)}>
                                <option value="">Select Model</option>
                            {compData !== null && compData.filter(item => item.type === prod.type && item.maker === prod.maker).map((prod, j) =>
                                <option key={j} value={prod.productid}>{prod.title}</option>
                            )}
                            </select>
                        </td>
                        <td>
                            <input value={prod.sr_no} onChange={(e) => updateprods(prod.id, "sr_no", e)} />
                        </td>
                        <td>
                        {prods.length - 1 === i ? (
                            <>
                            <span className="prod-span" onClick={() => removerow(prod.id)}>[-]</span>
                            <span className="prod-span" onClick={() => addrow(false)}>[+]</span>
                            </>
                        ) : (
                            <span className="prod-span" onClick={() => removerow(prod.id)}>[-]</span>
                        )}
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            <ul className='btns'><li><button onClick={submitHandler}>Submit</button></li></ul>
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    user: state.user
})

export default connect(mapStateToProps)(Outward)