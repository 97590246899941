import React, { useState } from 'react'
import { connect } from 'react-redux';
import axios from 'axios'
import KuroLink from '../components/common/KuroLink'
import '../styles/pwsreset.css'

const ChangePwd = ({user: {token}}) => {

    const [oldpassword, setoldpassword] = useState("")
    const [newpassword, setnewpassword] = useState("")
    const [confirmpwd, setconfirmpwd] = useState("")
    const [errorMsg, seterrorMsg] = useState("")

    const pwdToggleHandler = (e) => {
        if (e.target.closest(".field_wrapper").classList.contains("show")) {
            e.target.closest(".field_wrapper").classList.remove("show");
            e.target.closest(".field_wrapper").querySelector("#pwd").setAttribute("type", "password");
        } else {
            e.target.closest(".field_wrapper").classList.add("show");
            e.target.closest(".field_wrapper").querySelector("#pwd").setAttribute("type", "text");
        }
    }

    const oldpwdHandler = (e) => {
        seterrorMsg("")
        setoldpassword(e.target.value)
    }

    const newpwdHandler = (e) => {
        seterrorMsg("")
        setnewpassword(e.target.value)
    }

    const confirmpwdHandler = (e) => {
        seterrorMsg("")
        setconfirmpwd(e.target.value)
    }

    const focusHandler = (e) => {
        e.target.closest(".field_wrapper").classList.add("focus");
    };

    const blurHandler = (e) => {
        e.target.closest(".field_wrapper").classList.remove("focus");
    };

    const submitHandler = () => {
        if (newpassword.length < 8) {
            seterrorMsg("Password must be atleast 8 characters")
        } else {
            if (newpassword === confirmpwd) {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`
                    },
                };

                const body = JSON.stringify({ oldpassword, newpassword });

                axios.post(process.env.REACT_APP_KC_API_URL + 'pwdreset', body, config)
            } else {
                seterrorMsg("Passwords do not match")
            }
        }
    }

    return (
        <div className='pwd_reset'>
            <h2 className='txt-light-grey'>Change Password</h2>
            <div className="field_wrapper pwd">
                <label className='txt-light'>Old Password</label>
                <div className="input_wrapper">
                    <input id="pwd" name="pwd" type="password" onChange={oldpwdHandler} onFocus={focusHandler} onBlur={blurHandler} />
                    <div className="toggle_pwd">
                        <svg className="show" onClick={pwdToggleHandler} title="Show password" width="24" height="24" viewBox="0 0 24 24"><path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 5c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zm0-2c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z"/></svg>
                    </div>
                </div>
            </div>
            <div className="field_wrapper pwd">
                <label className='txt-light'>New Password</label>
                <div className="input_wrapper">
                    <input id="pwd" name="pwd" type="password" onChange={newpwdHandler} onFocus={focusHandler} onBlur={blurHandler} />
                    <div className="toggle_pwd">
                        <svg className="show" onClick={pwdToggleHandler} title="Show password" width="24" height="24" viewBox="0 0 24 24"><path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 5c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zm0-2c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z"/></svg>
                    </div>
                </div>
            </div>
            <div className="field_wrapper pwd">
                <label className='txt-light'>Confirm Password</label>
                <div className="input_wrapper">
                    <input id="pwd" name="pwd" type="password" onChange={confirmpwdHandler} onFocus={focusHandler} onBlur={blurHandler} />
                </div>
            </div>
            <div className="field_wrapper">
                <button className="btn pri1 solid" onClick={submitHandler}>Submit</button>
                {errorMsg !== "" && <span className="login_error">{errorMsg}</span>}
                <KuroLink to='/user/profile'><button className="btn pri1 solid" >Back</button></KuroLink>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(ChangePwd)