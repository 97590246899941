import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

const EditableProd = ({ prod, collection, from, user:{ userDetails } }) => {

    const [prodInfo, setprodInfo] = useState(null)
    let history = useHistory()
    var accesslevel = ""
    if(from==="peripherals"){
        accesslevel = userDetails.accesslevel.peripherals
   }
   else{
        accesslevel = userDetails.accesslevel.product_finder
   }
    useEffect(() => {
       

        if(accesslevel === "NA"){
            history.push('/unauthorized')
            return;
        }
        setprodInfo(prod)
    }, [prod])

    const ChangeProd = (e, key, num) => {
        setprodInfo({
            ...prodInfo,
            [key]: num ? Number(e.target.value) : e.target.value
        })
    }

    const ChangePrice = (e, key, subkey) => {
        setprodInfo({
            ...prodInfo,
            [key]: {
                ...prodInfo[key],
                [subkey]: Number(e.target.value)
            }
        })
    }

    const ChangeFlag = (key, value) => {
        setprodInfo({
            ...prodInfo,
            [key]: value
        })
    }

    const submitHandler = () => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        delete prodInfo._id

        axios.post(process.env.REACT_APP_KG_API_URL + 'kuroadmin/' + collection + '?type=' + prodInfo.type, prodInfo, config)
    }

    return (
        <div className="prod prod-list">
            <table className='border' cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <td>Product Id</td>
                        <td>Title</td>
                        <td>Kuro On</td>
                        <td>MD On</td>
                        <td>AM On</td>
                        <td>VP On</td>
                        <td>SH On</td>
                        <td>MRP</td>
                        <td>Status</td>
                        <td>Quantity</td>
                        <td>Delete Flag</td>
                        <td>Active</td>
                        {accesslevel === "edit"? <td rowSpan="3"><button id="submit" className='sm-btn' onClick={submitHandler}>Submit</button></td>:null}
                    </tr>
                    {prod !== null &&
                    <tr>
                        <td>
                            {collection === "components" && <a href={'https://kurogaming.com/p/' + prod.prod_url} target='_blank' rel='noreferrer'>{prod.productid}</a>}
                            {collection === "monitors" && <a href={'https://kurogaming.com/m/' + prod.prod_url} target='_blank' rel='noreferrer'>{prod.productid}</a>}
                            {collection === "accessories" && <a href={'https://kurogaming.com/a/' + prod.prod_url} target='_blank' rel='noreferrer'>{prod.productid}</a>}
                            {collection === "external" && <a href={'https://kurogaming.com/e/' + prod.prod_url} target='_blank' rel='noreferrer'>{prod.productid}</a>}
                            {collection === "networking" && <a href={'https://kurogaming.com/n/' + prod.prod_url} target='_blank' rel='noreferrer'>{prod.productid}</a>}
                        </td>
                        <td>{prod.title}</td>
                        <td>{prod.price.kuro_on}</td>
                        <td>{prod.supp_on.md_on}</td>
                        <td>{prod.supp_on.am_on}</td>
                        <td>{prod.supp_on.vp_on}</td>
                        <td>{prod.supp_on.sh_on}</td>
                        <td>{prod.mrp}</td>
                        <td>{prod.status}</td>
                        <td>{prod.quantity}</td>
                        <td>{prod.delete_flag ? "True" : "False"}</td>
                        <td>{prod.active ? "True" : "False"}</td>
                    </tr>
                    }
                    {accesslevel === "edit"?(
                    <>
                    {prodInfo !== null &&
                    <tr>
                        <td>{prodInfo.productid}</td>
                        <td><textarea className="title" onChange={(e) => ChangeProd(e, "title")} value={prodInfo.title} /></td>
                        <td><input type="number" onChange={(e) => ChangePrice(e, "price", "kuro_on")} value={prodInfo.price.kuro_on} /></td>
                        <td><input type="number" onChange={(e) => ChangePrice(e, "supp_on", "md_on")} value={prodInfo.supp_on.md_on} /></td>
                        <td><input type="number" onChange={(e) => ChangePrice(e, "supp_on", "am_on")} value={prodInfo.supp_on.am_on} /></td>
                        <td><input type="number" onChange={(e) => ChangePrice(e, "supp_on", "vp_on")} value={prodInfo.supp_on.vp_on} /></td>
                        <td><input type="number" onChange={(e) => ChangePrice(e, "supp_on", "sh_on")} value={prodInfo.supp_on.sh_on} /></td>
                        <td><input type="number" onChange={(e) => ChangeProd(e, "mrp", true)} value={prodInfo.mrp} /></td>
                        <td>
                            <select onChange={(e) => ChangeProd(e, "status")} value={prodInfo.status}>
                                <option value="In Stock">In Stock</option>
                                <option value="Currently Unavailable">Currently Unavailable</option>
                                <option value="Call for Price">Call for Price</option>
                                <option value="Temporarily Unavailable">Temporarily Unavailable</option>
                                <option value="Call for Availability">Call for Availability</option>
                                <option value="Check Availability">Check Availability</option>
                            </select>
                        </td>
                        <td><input type="number" onChange={(e) => ChangeProd(e, "quantity", true)} value={prodInfo.quantity} /></td>
                        <td><input type="checkbox" onChange={() => ChangeFlag("delete_flag", !prodInfo.delete_flag)} defaultChecked={prodInfo.delete_flag} /></td>
                        <td><input type="checkbox" onChange={() => ChangeFlag("active", !prodInfo.active)} defaultChecked={prodInfo.active} /></td>
                    </tr>
                    }</>):null}
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(EditableProd)