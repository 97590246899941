import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { getVendors } from '../actions/admin'
import { CurrencyFormat } from '../components/common/CurrencyFormat'
import KuroLink from '../components/common/KuroLink'
import '../styles/table.css'
import '../styles/tabs.css'
import { useHistory } from 'react-router-dom'
moment.tz.setDefault('Asia/Kolkata')

const PaymentVouchers = ({ user: {token, userDetails}, admin: { vendors }, getVendors }) => {

    const [voucherdata, setvoucherdata] = useState(null)
    const [fvoucherdata, setfvoucherdata] = useState(null)
    const [limit, setlimit] = useState(30)
    const [searchtxt, setsearchtxt] = useState("")

    const history=useHistory()
    const access=userDetails.accesslevel.paymentvouchers;
    useEffect(()=>{
      
        if(access==="NA"){
            history.push("/unauthorized")
        }
    })
    useEffect(() => {
        getVendors()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/paymentvouchers?limit=' + limit, config).then(res => {
            setvoucherdata(res.data)
            setfvoucherdata(res.data)
            if (res.data.length < limit) {
                setlimit(0)
            }
        }).catch((err)=>{
            if(err.response.status==401){
                history.push("/unauthorized")
            }
        })
    }, [limit])

    useEffect(() => {
        if (searchtxt === "") {
            setfvoucherdata(voucherdata)
        } else {
            setlimit(0)
            let tempvoucherdata = []
            let fltvendors = vendors.filter(ven => ven.name.toLowerCase().includes(searchtxt.toLowerCase()))
            for (let voucher of voucherdata) {
                if (fltvendors.filter(ven => ven.vendor_code === voucher.vendor).length > 0) {
                    tempvoucherdata.push(voucher)
                }
            }
            setfvoucherdata(tempvoucherdata)
        }
    }, [searchtxt])

    const sortdata = (key) => {
        let data = [...voucherdata]
        if (key === "pay_date") {
            data.sort((a, b) => new Date(b.pay_date) - new Date(a.pay_date))
        } else {
            data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date))
        }
        setfvoucherdata(data)
    }

    const limitHandler = (all) => {
        if (all) {
            setlimit(0)
        } else {
            setlimit(limit + 30)
        }
    }
    const keychecking = (voucher) => {
        if ("vendor" in voucher){
            if(voucher.vendor !== null && voucher.vendor !== ""){
            return vendors.filter(item => item.vendor_code === voucher.vendor)[0].name
            }else{
                return ""
            }
        }
        else if("Utility" in voucher){
            if(voucher.Utility !== ""&&voucher.Utility!==null){
                return voucher.Utility
            }
            else{
                return ""
            }
        }
        else if("Rent" in voucher){
            if(voucher.Rent !== ""&&voucher.Rent!==null){
                return voucher.Rent
            }
            else{
                return ""
                }
        }
        else if("Salary" in voucher){
            if(voucher.Salary !== ""&&voucher.Salary!==null){
                return voucher.Salary + "(salary)"
                }
                else{
                    return ""
                    }
        }
    }


    return (
        <div className="txt-light-grey">

            {vendors===null?(<h1>loading</h1>):(<div> <h2 className="txt-light-grey">Payment Vouchers</h2>
            {(access==="write" || access==="edit") && <ul className='btns'>
                <li><KuroLink to="/create-pv"><button>Create PV</button></KuroLink></li>
            </ul>}
            
            <div className='search_container'>
                <input type='text' name='search' onChange = {(e) => setsearchtxt(e.target.value)} placeholder="Search...."/>
            </div>
            {fvoucherdata !== null &&
            <table className='txt-light'>
                <td className='type'>Sort Data By</td>
                <td>
                    <select onChange={(e) => sortdata(e.target.value)}>
                        <option value="">Select to sort Data</option>
                        <option value="created_date">Created Date</option>
                        <option value="pay_date">Pay Date</option>
                    </select>
                </td>
            </table>
            }
            <table className="border even_odd table_mob" cellSpacing="0" cellPadding="0">
                <tbody>
                    <tr>
                        <th>Voucher Number</th>
                        <th>PO No.</th>
                        <th>Vendor</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Paid By</th>
                        <th>Payment Method</th>
                        <th>Pay Account</th>
                        <th>Pay Date</th>
                        <th>Pay Ref/UTR</th>
                        <th>Payment Narration</th>
                    </tr>
                {fvoucherdata !== null && fvoucherdata.map((voucher, i) =>
                    <tr key={i}>
                        <td><KuroLink to={'payment-vouchers/' + voucher.pv_no}>{voucher.pv_no}</KuroLink></td>
                        <td>{voucher.po_no}</td>
                        <td>
                            {
                                (voucher.type === "Expenses - Salaries" || voucher.type === "Expenses - Utilities") ? (
                                    voucher.tags[0]
                                ) : (
                                    keychecking(voucher)
                                )
                            }
                        </td>
                        <td>{voucher.type}</td>
                        <td>{CurrencyFormat(voucher.amount)}</td>
                        <td>{voucher.paid_by}</td>
                        <td>{voucher.pay_method}</td>
                        <td>{voucher.pay_account}</td>
                        <td>{voucher.pay_date === "" ? "" : moment(voucher.pay_date).format('DD-MM-YYYY')}</td>
                        <td>{voucher.pay_ref_utr}</td>
                        <td>
                        {voucher.narration.map((item, j) =>
                            <p key={j}>
                                {item.desc} - {item.narration_by} - {item.narration_date}
                            </p>
                        )}
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            {limit !== 0 && searchtxt === "" && fvoucherdata !== null &&
            <ul className='btns'>
                <li><button className='btn' onClick={() => limitHandler(false)}>Show More</button></li>
                <li><button className='btn' onClick={() => limitHandler(true)}>Show All</button></li>
            </ul>
            }</div>)}
           
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(PaymentVouchers)