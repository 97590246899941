import React, {useState, useEffect} from 'react'
import axios from 'axios'
import KuroLink from '../components/common/KuroLink'
import EditableProd from '../components/EditableProd'
import '../styles/products.css' 
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

const KGProd = ({ match, user:{ userDetails } }) => {

    let history = useHistory();
    const { params: { prodColl, prodType } } = match
    const [prods, setProds] = useState(null)

    useEffect(() => {
        if(userDetails.accesslevel.peripherals === "NA"){
            history.push('/unauthorized')
            return;
        }
        let url = process.env.REACT_APP_KG_API_URL + 'kuroadmin/' + prodColl
        if (prodType !== undefined) {
            url += '?type=' + prodType
        }
        axios(url).then(res => {
            setProds(res.data)
        })
    }, [prodColl, prodType])

    return (
        <div className="products">
        <p className="breadcrumb txt-light-grey"><KuroLink to="/peripherals">Peeripherals</KuroLink> &gt; {prodType}</p>
            <div className="page-title">
                <h1>{prodColl}</h1>
            </div>
            {prods !== null && prods.map((prod) =>
                <EditableProd prod={prod} collection={prodColl} from={"peripherals"} />
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(KGProd)